import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// import CssBaseline from "@material-ui/core/CssBaseline";
// import AppBar from "@material-ui/core/AppBar";
// import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";

import Typography from "@material-ui/core/Typography";
import AddressForm from "./Firmante/formFirmantesContainer";
import Previewpdf from "./PreviewPDF/PreviewPDF";
import AddressFormDocument from "./Firmante/Document";
import mensaje from "assets/img/mensaje.png";

// import Navbar from "components/Navbars/Navbar.js";
// import Link from "@material-ui/core/Link";
// import { BrowserRouter as Router, useParams } from "react-router-dom";
// import Sidebar from "components/Sidebar/Sidebar.js";
// import routes from "routes.js";
// import bgImage from "assets/img/fondoblack.jpg";
// import logo from "assets/img/ConCertezaGris.png";

import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStylespro = makeStyles(styles);
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

export default function Checkout(props) {
  const ref = React.createRef();
  function goTop() {
    console.log("REF");
    ref.current.scrollIntoView();
  }
  const [tokenstate, setTokenstate] = React.useState("");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [idFlow, setIFLow] = React.useState(false);
  const mainPanel = React.createRef();
  const classespro = useStylespro();
  const { tabs, idDocumentotable, idprocces } = props;
  // let { id } = useParams();

  const [idRequired, setIdRequired] = React.useState(false);
  const [asistido, setAsistido] = React.useState(false);

  React.useEffect(() => {
    goTop();
    setidDocumento("");
    let token = sessionStorage.getItem("Token");

    setTokenstate(token);
    console.log("msjkdjs", idprocces);

    if (tabs == "1") {
      handleDialog();
      setidDocumento(idDocumentotable);
      {
      }
    } else if (idprocces == "2") {
      setIFLow(true);
      setIdRequired(true);
    }
  }, [idDocumentotable]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // console.log("tokenLOCAL STORAGE CHECKOU", tokenstate);
  const classes = useStyles();

  const [activeStep, setActiveStep] = React.useState(0);
  const [idDocumento, setidDocumento] = React.useState("");
  const [openSnack, setOpenSnack] = React.useState(false);
  const [messageError, setMessageError] = React.useState("");
  const [progress, setprogress] = React.useState(false);

  const steps = ["Carga", "Vista previa", "Registra firmantes"];

  function onChangeValue(event) {
    setIdRequired(event.target.value);
  }

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };
  const handleDialog = () => {
    setActiveStep(1);
  };

  const senFirmante = () => {
    setTimeout(handleNext, 5100);
  };

  const sendDocument = () => {
    setTimeout(handleNext, 500);
  };

  // alert functions

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const openAlert = (error) => {
    setOpenSnack(true);
    setMessageError(error);
  };

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${tokenstate}`,
      Accept: " application/json",
      type: "formData",
    },
  };

  const onChangePDF = async (event) => {
    const data = new FormData();
    setprogress(true);
    var file = document.querySelector('input[type="file"]').files[0];
    data.append("filename", file);
    var valorasistido;
    asistido ? (valorasistido = 1) : (valorasistido = 0);
    data.append("asistido", valorasistido);
    if (idRequired) {
      data.append("documento_types_id", 2);
    }

    console.log("fiLE", data);
    let url = `${process.env.REACT_APP_URL}api/documentos`;

    axios
      .post(url, data, config, {
        // receive two parameter endpoint url ,form data
      })
      .then((response) => {
        // Respuesta del servidor
        let data = response.data[0];
        setidDocumento(data.id);
        sendDocument();
        setprogress(false);
      })
      .catch((error) => {
        // Error 😨
        if (error.response.data.errors) {
          openAlert("El archivo debe ser de tipo PDF sin ninguna encriptacion");
        } else if (error.response.data.message) {
          openAlert(error.response.data.message);
        } else {
          openAlert("Su documento tiene algun problema");
        }

        setprogress(false);
      });
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <AddressFormDocument
            onChangePDF={onChangePDF}
            progress={progress}
            setAsistido={setAsistido}
            asistido={asistido}
          />
        );
      case 1:
        return (
          <Previewpdf idDocumento={idDocumento} handleDelete={handleDelete} />
        );
      case 2:
        return (
          <AddressForm
            idDocumento={idDocumento}
            senFirmante={senFirmante}
            handleDelete={handleDelete}
            idRequired={idRequired}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  };

  const handleDelete = () => {
    goTop();
    let urlDeleteDoc = `${process.env.REACT_APP_URL}api/documentos/${idDocumento}`;
    console.log("URL DELETE");
    axios
      .delete(urlDeleteDoc, {
        headers: {
          Authorization: `Bearer ${tokenstate}`,
        },
        data: {},
        // receive two parameter endpoint url ,form data
      })
      .then((response) => {
        // Respuesta del servidor
        console.log("respuest a data", response);
        setActiveStep(0);
      })
      .catch((error) => {
        // Error 😨
        if (error.response) {
          console.log(error.response.data.message);
          openAlert(error.response.data.message);
        } else if (error.request) {
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  return (
    <React.Fragment>
      <main className={classes.layout} ref={ref}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h4" align="center">
            {idFlow == false ? "Firma simple" : "Firma con identificación"}
          </Typography>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                  Muchas gracias por utilizar nuestra plataforma
                </Typography>
                <Typography
                  variant="subtitle1"
                  style={{ textTransform: "none" }}
                >
                  Se le ha mandado una notificación a el firmante.
                </Typography>
                <div align="center">
                  <img src={mensaje} alt="logo" width="260" height="220" />
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep)}
                {activeStep === steps.length - 1 ? (
                  <div className={classes.buttons}>
                    <Button
                      onClick={handleDelete}
                      color="secondary"
                      className={classes.button}
                    >
                      Eliminar
                    </Button>
                  </div>
                ) : activeStep === steps.length - 3 ? (
                  ""
                ) : (
                  <React.Fragment>
                    <div className={classes.buttons}>
                      {activeStep !== 0 && (
                        <Button
                          onClick={handleDelete}
                          className={classes.button}
                          color="secondary"
                        >
                          <Typography
                            variant="button"
                            style={{ textTransform: "none" }}
                          >
                            Eliminar
                          </Typography>
                        </Button>
                      )}
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className={classes.button}
                      >
                        {activeStep === steps.length - 1 ? (
                          "Mandar a firma"
                        ) : activeStep === steps.length - 2 ? (
                          <Typography
                            variant="button"
                            style={{ textTransform: "none" }}
                          >
                            Registrar firmantes
                          </Typography>
                        ) : activeStep === steps.length - 3 ? (
                          "Ver documento"
                        ) : (
                          "siguiente"
                        )}
                      </Button>
                    </div>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
      </main>

      <div className={classes.root}>
        <Snackbar
          open={openSnack}
          autoHideDuration={9000}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          onClose={handleCloseSnack}
        >
          <Alert onClose={handleCloseSnack} severity="error">
            {messageError}
          </Alert>
        </Snackbar>
      </div>
    </React.Fragment>
  );
}
