import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { whiteColor } from "assets/jss/material-dashboard-react";
import { DialogComp } from "components/Dialog/DialogComp";
import TextField from "@material-ui/core/TextField";

import Card from "components/Card/Card";
import Button from "@material-ui/core/Button";
import CardHeader from "components/Card/CardHeader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import { green, deepOrange } from "@material-ui/core/colors";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import { MySnackbar } from "components/Snackbar/MySnackbar";
import ErrorIcon from "@material-ui/icons/Error";
import Snackbar from "components/Snackbar/Snackbar.js";

import firmaavanzada from "assets/img/FirmaAvanzada.png";
import PSCWorld from "assets/img/logopscword.png";

import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import ImageIcon from "@material-ui/icons/Image";
import WorkIcon from "@material-ui/icons/Work";
import BeachAccessIcon from "@material-ui/icons/BeachAccess";

import axios from "axios";
import { BrowserRouter as Router, useParams } from "react-router-dom";
import { data } from "@tensorflow/tfjs";
import GridItem from "components/Grid/GridItem";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  fab: {
    display: "scroll",
    position: "fixed",
  },
  button2: {
    backgroundColor: green[500],
    color: whiteColor,
    alignItems: "center",
    top: "50%",
    left: "40%",
    marginTop: 12,
    marginLeft: 12,
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
    zIndex: -1,
  },
  large: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  input: {
    display: "none",
  },
  backdrop: {
    position: "absolute",
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  buttonProgress: {
    color: green[500],
    alignItems: "center",
    top: "50%",
    left: "50%",
    marginTop: 12,
    marginLeft: 12,
  },
}));
export default function FirmaAvanzada(props) {
  const { handleNext, infofirmante, setActiveStep } = props;
  const { rfc, rfc_empresa } = infofirmante;
  const classes = useStyles();

  //id=idDocumento idfirmante=idFirmante
  let { idDoc, idfirmante } = useParams();
  const [firma, setFirma] = React.useState(true);
  const [openInput, setOpenInput] = React.useState(false);
  const [firmaCer, setFirmaCer] = React.useState("");
  const [firmaKey, setFirmaKey] = React.useState("");
  const [firmaPfx, setFirmaPfx] = React.useState("");
  const [success, setSuccess] = React.useState(false);
  const [nameCer, setNameCer] = React.useState("Certificado (.cer)");
  const [nameKey, setNameKey] = React.useState("Clave privada (.key)");
  const [namePfx, setNamePFx] = React.useState("Certificado (.pfx)");
  const [openAlert, setOpenAlert] = React.useState("");
  const [messageError, setMessageError] = React.useState("");

  const [snackData, setSnackData] = React.useState({
    openSnackError: false,
    snackError: "",
    snackMessage: "",
    snackSeverity: "",
  });

  const { openSnackError, snackError } = snackData;

  const [clave, setclave] = React.useState("");

  const [newAssignProcess, setNewAssign] = React.useState({
    tipoFirma: 0,
    disabledAssign: true,
  });
  const { disabledAssign, tipoFirma } = newAssignProcess;

  const showNotificacion = (message) => {
    setOpenAlert(true);
    setMessageError(message);
    setTimeout(function () {
      setOpenAlert(false);
    }, 6000);
  };

  const handleRadioChange = (event) => {
    const value = event.target.value;
    setNewAssign({
      ...newAssignProcess,
      tipoFirma: value,
      disabledAssign: false,
    });
  };
  //cargar archivos de firma .cer .key clave
  const handleSubirFirma = () => {
    console.log("value", tipoFirma);
    if (tipoFirma == 1) {
      console.log("firmaFIEL");
      setOpenInput(true);
    } else if (tipoFirma == 2) {
      if (rfc_empresa == undefined) {
        showNotificacion(
          "Este proceso de firma ha sido iniciado sin registrar el RFC de la empresa, es necesario que te pongas en contacto con el emisor  para poder corregir el error y continuar con el proceso."
        );
      } else {
        setOpenInput(true);
      }
    }
  };

  const onChangeFirmaCer = (e) => {
    setFirmaCer(e.target.files[0]);
    setNameCer(e.target.files[0].name);
  };
  const onChangeFirmakey = (e) => {
    setFirmaKey(e.target.files[0]);
    setNameKey(e.target.files[0].name);
  };
  const onChangeFirmaPFx = (e) => {
    setFirmaPfx(e.target.files[0]);
    setNamePFx(e.target.files[0].name);
  };

  const handleCloseSnack = () => {
    setSnackData({
      openSnackError: false,
      snackError: "",
      snackMessage: "",
      snackSeverity: "",
    });
  };
  //FUNCION PARA MANDAR LOS ARCHIVOS AL BACKEND Y FIRMAR
  const handlefirmar = () => {
    setOpenInput(false);
    setSuccess(true);
    if (tipoFirma == 1) {
      console.log("firmaFIE2L");
      const token = sessionStorage.signToken;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: " application/json",
        },
      };
      const URL = `${process.env.REACT_APP_URL}api/firmaavanzada/firmasat`;
      const data = new FormData();
      data.append("firmantes_id", idfirmante);
      data.append("documentos_id", idDoc);
      data.append("certificado", firmaCer);
      data.append("key", firmaKey);
      data.append("clave", clave);
      data.append("rfc", rfc);

      axios
        .post(URL, data, config)
        .then((res) => {
          console.log("datarespuesta", res.data);

          setActiveStep(5);
          setSuccess(false);
        })
        .catch((error) => {
          setSuccess(false);
          if (error.response.data.message) {
            setSnackData({
              openSnackError: true,
              snackError: error,
            });
            // showNotificacion(error.response.data.message);
          }
        });
    } else if (tipoFirma == 2) {
      console.log("firmaFIE2L");
      const token = sessionStorage.signToken;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: " application/json",
        },
      };
      const URL = `${process.env.REACT_APP_URL}api/firmaavanzada/firmapscw`;
      const data = new FormData();
      data.append("firmantes_id", idfirmante);
      data.append("documentos_id", idDoc);
      data.append("certificado", firmaPfx);
      data.append("clave", clave);
      data.append("rfc", rfc_empresa);

      axios
        .post(URL, data, config)
        .then((res) => {
          console.log("datarespuesta", res.data);

          setActiveStep(5);
          setSuccess(false);
        })
        .catch((error) => {
          setSuccess(false);
          if (error.response.data.message) {
            setSnackData({
              openSnackError: true,
              snackError: error,
            });
            // showNotificacion(error.response.data.message);
          }
        });
    }
  };

  //VALIDACION DISABLEB BOTON
  var disableEFirma = true;
  if (tipoFirma == 1) {
    if (firmaCer && firmaKey && clave) {
      disableEFirma = false;
    }
  } else {
    if (firmaPfx && clave) {
      disableEFirma = false;
    }
  }
  return (
    <React.Fragment>
      {console.log("CERDF", firmaCer)}
      <Grid container spacing={3}>
        <Grid Item xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success">
              <center>
                <h5 className={styles.cardTitleWhite}>
                  Seleccione el tipo de Firma Avanzada
                </h5>
              </center>
            </CardHeader>
            <List className={classes.root}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="quiz"
                  name="quiz"
                  value={tipoFirma}
                  onChange={handleRadioChange}
                >
                  <ListItem>
                    <FormControlLabel value="1" control={<Radio />} />
                    <ListItemAvatar>
                      <Avatar
                        className={classes.large}
                        style={{ backgroundColor: "#0075bf" }}
                      >
                        <img
                          src={firmaavanzada}
                          alt="Con-Certeza fiel e.firma"
                          width="45"
                          height="45"
                        />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="E.FIRMA (SAT)"
                      secondary="Firma Electrónica avanzada otorgada por el SAT "
                    />
                  </ListItem>
                  <ListItem>
                    <FormControlLabel value="2" control={<Radio />} />
                    <ListItemAvatar>
                      <Avatar
                        className={classes.large}
                        style={{ backgroundColor: "#dfb326" }}
                      >
                        <img
                          src={firmaavanzada}
                          alt="PSC-worl"
                          width="45"
                          height="45"
                        />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="FIRMA PSC WORLD"
                      secondary="Firma Electrónica otorgada por PSC World"
                    />
                  </ListItem>
                </RadioGroup>
              </FormControl>
            </List>
          </Card>

          <Grid container justify="flex-end">
            <Button
              variant="contained"
              color="primary"
              disabled={disabledAssign}
              className={classes.button}
              onClick={handleSubirFirma}
            >
              CONTINUAR
            </Button>
          </Grid>
        </Grid>
        {/* DIALOGO PARA ABRIR INPUT FILE FIRMA */}
        <DialogComp
          open={openInput}
          fullWidth={true}
          maxWidth={"sm"}
          title={
            tipoFirma == 1
              ? "Carga los archivos de tu e.firma"
              : "Carga los archivos de tu firma PSC World"
          }
          content={
            tipoFirma == 1 ? (
              <>
                <div>
                  <input
                    accept=".cer"
                    className={classes.input}
                    id="contained-button-file2345"
                    multiple
                    type="file"
                    name="PDF"
                    onChange={onChangeFirmaCer}
                  />
                  <label htmlFor="contained-button-file2345">
                    <Grid
                      container
                      component="main"
                      spacing={1}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item xs={9} sm={9} md={9}>
                        <TextField
                          disabled
                          id="standard-helperText"
                          variant="outlined"
                          value={nameCer}
                          fullWidth
                        // onChange={handleInput}
                        />
                      </Grid>
                      <Grid item xs={2} sm={2} md={2}>
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                        // disabled={loading}
                        // className={buttonClassname}
                        >
                          <Typography variant="h6">Buscar</Typography>
                        </Button>
                      </Grid>
                    </Grid>
                  </label>
                </div>
                <div>
                  <input
                    accept=".key"
                    className={classes.input}
                    id="contained-button-file2"
                    multiple
                    type="file"
                    name="PDF"
                    onChange={onChangeFirmakey}
                  />
                  <label htmlFor="contained-button-file2">
                    <Grid
                      container
                      component="main"
                      spacing={1}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item xs={9} sm={9} md={9}>
                        <TextField
                          disabled
                          id="outlined-disabled"
                          variant="outlined"
                          value={nameKey}
                          fullWidth
                          // onChange={handleInput}
                          autoFocus
                        />
                      </Grid>
                      <Grid item xs={2} sm={2} md={2}>
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                        // disabled={loading}
                        // className={buttonClassname}
                        >
                          <Typography variant="h6">Buscar</Typography>
                        </Button>
                      </Grid>
                    </Grid>
                  </label>
                  <Grid item xs={9} sm={9} md={9} style={{ marginLeft: 22 }}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Clave privada"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      onChange={(event) => setclave(event.target.value)}
                    />
                  </Grid>
                </div>
              </>
            ) : tipoFirma == 2 ? (
              <div>
                <input
                  accept=".pfx"
                  className={classes.input}
                  id="contained-button-file2"
                  multiple
                  type="file"
                  name="PFc"
                  onChange={onChangeFirmaPFx}
                />
                <label htmlFor="contained-button-file2">
                  <Grid
                    container
                    component="main"
                    spacing={1}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={9} sm={9} md={9}>
                      <TextField
                        disabled
                        id="filled-required"
                        name="pfx"
                        variant="outlined"
                        value={namePfx}
                        fullWidth
                        // onChange={handleInput}
                        autoFocus
                      />
                    </Grid>
                    <Grid item xs={2} sm={2} md={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                      // disabled={loading}
                      // className={buttonClassname}
                      >
                        <Typography variant="h6">Buscar</Typography>
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xs={9} sm={9} md={9} style={{ marginLeft: 22 }}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Clave privada"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      onChange={(event) => setclave(event.target.value)}
                    />
                  </Grid>
                </label>
              </div>
            ) : (
              ""
            )
          }
          buttons={[
            {
              buttonEvent: () => setOpenInput(false),
              buttonName: "Cerrar",
              buttonColor: "secondary",
            },
            {
              buttonEvent: () => handlefirmar(),
              buttonName: "Aceptar",
              buttonDisabled: disableEFirma,
              buttonColor: "primary",
            },
          ]}
        />
      </Grid>

      <MySnackbar
        openAlert={openSnackError}
        onClose={handleCloseSnack}
        error={snackError}
        duration={20000}
      />
      <Snackbar
        place="tc"
        color="danger"
        icon={ErrorIcon}
        message={messageError}
        open={openAlert}
        closeNotification={() => setOpenAlert(false)}
        close
      />

      <Backdrop className={classes.backdrop} open={success}>
        <CircularProgress size={44} className={classes.buttonProgress} />
      </Backdrop>
    </React.Fragment>
  );
}
