import React from "react";
import Avatar from "@material-ui/core/Avatar";
import SigUp from "assets/img/singUpEnter.svg";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import FormHelperText from "@material-ui/core/FormHelperText";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useHistory } from "react-router-dom";
import logoCDO from "assets/img/LOGOCDO.png";
import GridItem from "components/Grid/GridItem.js";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";

import FormGroup from "@material-ui/core/FormGroup";

import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

//log
import {
  saveLog,
  saveLogFail,
  saveLogCaptureActividad,
} from "containers/Logs/funcionesLog.js";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Copyright() {
  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <img src={logoCDO} alt="Con-Certeza" width="30" height="35" />
      <GridItem>
        <Typography variant="body2" color="textSecondary" align="center">
          {"Powered by "}
          <Link color="inherit" href="https://certificaciondigital.online/">
            CDO
          </Link>
        </Typography>
      </GridItem>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: "60px",
    height: "60px",
    marginTop: theme.spacing(-7),
    alignItems: "center",
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
    [theme.breakpoints.up(10 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
}));

const useStylesprogrees = makeStyles((theme) => ({
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonProgress2: {
    color: green[500],
    alignItems: "center",
    top: "50%",
    left: "50%",
    marginTop: 12,
    marginLeft: 12,
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SignUp(props) {
  const { setOpen, setOpenRegister } = props;
  const classes = useStyles();
  const history = useHistory();
  const classespro = useStylesprogrees();
  const [openDialogEnter, setOpenDialogEnter] = React.useState(false);
  const [checkPrivacidad, setCheckPrivadad] = React.useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [messageError, setMessageError] = React.useState("");
  const regexPass = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\d)[A-Za-z\d@$!%*?;.,&]{6,16}$/;

  const [progress, setProgress] = React.useState(false);
  const [dataRegister, setDataRegister] = React.useState({
    name: "",
    lastName: "",
    email: "",
    phone: "",
    company: "",
    password: "",
    confirmPass: "",
    promo_code: "",
    city: "",
  });

  const [state, setState] = React.useState({
    checkedA: false,
    checkedB: false,
  });
  // alert functions

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const openAlert = (error) => {
    setOpenSnack(true);
    setMessageError(error);
  };

  const registerUser = () => {
    setProgress(true);
    const data = new FormData();
    data.append("first_name", dataRegister.name);
    data.append("last_name", dataRegister.lastName);
    data.append("email", dataRegister.email);
    data.append("phone", dataRegister.phone);
    data.append("company", dataRegister.company);
    data.append("password", dataRegister.password);
    data.append("password_confirmation", dataRegister.confirmPass);
    data.append("city", dataRegister.city);

    data.append("promo_code", dataRegister.promo_code);
    //const data = `{\n   "name": "${dataRegister.name}",\n    "email": "${dataRegister.email}",\n    "phone": "${dataRegister.phone}",\n    "company": "${dataRegister.company}",\n    "password": "${dataRegister.password}",\n    "password_confirmation": "${dataRegister.confirmPass}",\n    "promo_code": "${dataRegister.promo_code}",\n}`;
    const config = {
      headers: {
        Accept: " application/json",
      },
    };
    let urlFirmante = `${process.env.REACT_APP_URL}api/auth/signup`;
    axios
      .post(urlFirmante, data, config)
      .then((response) => {
        saveLogFail("User Register", dataRegister.email);
        saveLogCaptureActividad("User Register", dataRegister.email);
        console.log("response Signup", response);
        setOpenDialogEnter(true);
        setProgress(false);
      })
      .catch((error) => {
        setProgress(false);
        if (error.response.data.errors) {
          if (error.response.data.errors.promo_code) {
            openAlert(error.response.data.errors.promo_code);
          } else if (error.response.data.errors.email) {
            openAlert(error.response.data.errors.email);
          } else if (error.response.data.errors.phone) {
            openAlert(error.response.data.errors.phone);
          } else if (error.response.data.errors.city) {
            openAlert(error.response.data.errors.city);
          } else openAlert(error.response.data.message);
        }
        console.log("error", error.response);
      });
  };

  const handlesuccesSignUp = () => {
    var URLactual = window.location.pathname;
    setOpenDialogEnter(false);
    console.log("URLactual", URLactual);
    if (URLactual === "/") {
      setTimeout(function () {
        setOpen(true);
      }, 1000);
      setOpenRegister(false);
    } else {
      history.push("/");
    }
  };

  const removerCaracteres = (event) => {
    let phone = event.target.value;
    if (phone) {
      let wordArray = phone.split("");
      let result = "";
      if (wordArray) {
        wordArray.map((item, i) => {
          if (esNumero(wordArray[i])) {
            result += wordArray[i];
          }
        });
        return result;
      } else {
        return false;
      }
    }
    return false;
  };
  function esNumero(text) {
    if (text) {
      var reg = new RegExp("[0-9]+$");
      if (reg.test(text)) {
        return reg.test(text);
      } else {
        return false;
      }
    }
    return false;
  }

  const handleInput = (event) => {
    if (event.target.name == "phone") {
      let resultado = removerCaracteres(event);
      setDataRegister({
        ...dataRegister,
        [event.target.name]: !resultado ? "" : resultado,
      });
      return false;
    }

    setDataRegister({
      ...dataRegister,
      [event.target.name]: event.target.value,
    });
  };

  const handleCheck = (event) => {
    if (checkPrivacidad === false) {
      setCheckPrivadad(event.target.value);
    } else setCheckPrivadad(false);
  };

  const alertVerifyPassword =
    dataRegister.password == dataRegister.confirmPass
      ? ""
      : "Las contraseñas no coinciden.";

  let alertPass = regexPass.test(dataRegister.password)
    ? ""
    : "La contraseña no es valida.";
  let disableform = true;
  if (
    dataRegister.name.length >= 3 &&
    dataRegister.email.length >= 3 &&
    dataRegister.city.length >= 2 &&
    dataRegister.phone.length >= 8 &&
    dataRegister.company.length >= 3 &&
    dataRegister.password !== "" &&
    dataRegister.confirmPass !== "" &&
    dataRegister.password == dataRegister.confirmPass &&
    state.checkedA == true &&
    state.checkedB == true &&
    alertPass == ""
  ) {
    disableform = false;
  }
  return (
    <Container component="main">
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          <PersonAddIcon />
        </Avatar>

        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="promo_code"
                label="Codigo promocional"
                onChange={handleInput}
                value={dataRegister.promo_code}
                name="promo_code"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="city"
                label="Ciudad"
                onChange={handleInput}
                value={dataRegister.city}
                name="city"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="company"
                label="Empresa"
                onChange={handleInput}
                value={dataRegister.company}
                name="company"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="name"
                required
                name="name"
                variant="outlined"
                fullWidth
                id="name"
                value={dataRegister.name}
                onChange={handleInput}
                label="Nombre"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="lastName"
                required
                name="lastName"
                variant="outlined"
                fullWidth
                id="lastName"
                value={dataRegister.lastName}
                onChange={handleInput}
                label="Apellidos"
                autoFocus
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                value={dataRegister.email}
                onChange={handleInput}
                label="Correo"
                name="email"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="phone"
                label="Celular"
                onChange={handleInput}
                value={dataRegister.phone}
                inputProps={{ maxLength: 10 }}
                name="phone"
                error={dataRegister.phone.length < 10 ? true : false}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Contraseña"
                onChange={handleInput}
                value={dataRegister.password}
                inputProps={{
                  maxLength: 17,
                }}
                type="password"
                id="password"
              />
              <FormHelperText style={{ color: "red" }}>
                {alertPass}
              </FormHelperText>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="confirmPass"
                label="Confirma tu contraseña"
                type="password"
                onChange={handleInput}
                inputProps={{
                  maxLength: 17,
                }}
                value={dataRegister.confirmPass}
                id="confirmPass"
                error={alertVerifyPassword}
              />
              <FormHelperText style={{ color: "red" }}>
                {alertVerifyPassword}
              </FormHelperText>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary" align="center">
                {
                  "La contraseña debe tener de 6 a 16 caracteres y contener una mayúscula, una minúscula y uno o mas números."
                }
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.checkedA}
                      onChange={handleChange}
                      name="checkedA"
                      color="primary"
                    />
                  }
                  label="Acepto Términos y Condiciones"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.checkedB}
                      onChange={handleChange}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label={
                    <div>
                      Acepto{" "}
                      <a
                        href="https://certificaciondigital.online/avisos.html"
                        target="_blank"
                      >
                        Aviso de Privacidad
                      </a>
                    </div>
                  }
                />
              </FormGroup>
            </Grid>
          </Grid>
          <Button
            onClick={registerUser}
            fullWidth
            disabled={disableform}
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Registrate
          </Button>
        </form>
      </Paper>
      <div>
        <Dialog
          // fullWidth={true}
          // maxWidth="md"
          open={openDialogEnter}
          TransitionComponent={Transition}
          keepMounted
          // onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Registro exitoso"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <div align="center">
                <Typography
                  variant="subtitle1"
                  style={{
                    textTransform: "none",

                  }}
                >
                  Gracias por registrarse al servicio gratuito de Con-Certeza, por favor ingresa con el correo y contraseña que registraste.              </Typography>

                <img src={SigUp} alt="logo" width="350" height="240" />
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handlesuccesSignUp()} color="succes">
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div className={classes.root}>
        <Snackbar
          open={openSnack}
          autoHideDuration={3000}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          onClose={handleCloseSnack}
        >
          <Alert onClose={handleCloseSnack} severity="error">
            {messageError}
          </Alert>
        </Snackbar>
      </div>

      <Backdrop className={classespro.backdrop} open={progress}>
        <CircularProgress size={44} className={classespro.buttonProgress} />
      </Backdrop>
    </Container>
  );
}
