/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
import RoomIcon from "@material-ui/icons/Room";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import styles from "assets/jss/material-kit-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont,
  });

  const handlelink = (data) => {
    if (data == "facebook") {
      window.open("https://www.facebook.com/firmadigitalconcerteza");
    } else if (data == "twitter") {
      window.open("https://twitter.com/firmaconcerteza");
    } else if (data == "linkedin") {
      window.open(
        "https://www.linkedin.com/showcase/firma-digital-con-certeza"
      );
    }
  };
  return (
    <footer>
      <div className={classes.container}>
        <div style={{ marginLeft: " 60px", marginRight: " 60px" }}>
          <h3 style={{ fontSize: "2.1rem", fontWeight: "500" }}>
            Donde encontrarnos
          </h3>
          <GridContainer>
            <GridItem xs={12} sm={6} md={3}>
              <h4
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "400",
                }}
              >
                <RoomIcon
                  style={{
                    width: "40px",
                    height: "40px",
                  }}
                />
                Guillermo González Camarena #999 Torre 2 Int. 101, Col. Santa
                Fe, Alcaldía Álvaro Obregón, C.P. 01210, Ciudad de México.
              </h4>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <h4
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "400",
                }}
              >
                <RoomIcon
                  style={{
                    width: "40px",
                    height: "40px",
                  }}
                />
                Pedro Moreno #1677, Piso 5 Oficina 8 Col. Americana, C.P. 44160
                Guadalajara, Jalisco.
              </h4>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <div style={{ alignItems: "center", display: "flex" }}>
                <PhoneIcon
                  style={{
                    width: "40px",
                    height: "40px",
                  }}
                />
                {""}
                <h4
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: "400",
                  }}
                >
                  (55) 2972 5568
                </h4>
              </div>
            </GridItem>
            <GridItem xs={12} sm={3} md={3}>
              <div style={{ alignItems: "center", display: "flex" }}>
                <EmailIcon
                  style={{
                    width: "40px",
                    height: "40px",
                  }}
                />
                {""}
                <h4
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: "400",
                  }}
                >
                  hola@con-certeza.mx
                </h4>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <hr />
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <a
                href="https://certificaciondigital.online/avisos.html"
                className={classes.block}
                target="_blank"
              >
                aviso de Privacidad
              </a>
            </ListItem>
          </List>
        </div>
        <div className={classes.center}>
          &copy; Copyright,{" "}
          <a
            href="https://certificaciondigital.online/"
            className={aClasses}
            target="_blank"
          >
            Certificación Digital Online S.A. de C.V.
          </a>{" "}
          <div className={classes.right}>
            <FacebookIcon
              color="primary"
              style={{
                cursor: "pointer",
                width: "40px",

                height: "40px",
              }}
              onClick={() => handlelink("facebook")}
            />
            <TwitterIcon
              color="primary"
              style={{
                cursor: "pointer",
                width: "40px",

                height: "40px",
              }}
              onClick={() => handlelink("twitter")}
            />
            <LinkedInIcon
              color="primary"
              style={{
                cursor: "pointer",
                width: "40px",

                height: "40px",
              }}
              onClick={() => handlelink("linkedin")}
            />
          </div>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool,
};
