import React from "react";
import Avatar from "@material-ui/core/Avatar";
import SigUp from "assets/img/singUpEnter.svg";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import FormHelperText from "@material-ui/core/FormHelperText";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useHistory } from "react-router-dom";
import logoCDO from "assets/img/LOGOCDO.png";
import GridItem from "components/Grid/GridItem.js";
import clsx from "clsx";
import { green } from "@material-ui/core/colors";
import PictureUpload from "components/CustomUpload/PictureUpload";
import CardFooter from "components/Card/CardFooter";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Copyright() {
  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <img src={logoCDO} alt="Con-Certeza" width="30" height="35" />
      <GridItem>
        <Typography variant="body2" color="textSecondary" align="center">
          {"Powered by "}
          <Link color="inherit" href="https://certificaciondigital.online/">
            CDO
          </Link>
        </Typography>
      </GridItem>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  input: {
    display: "none",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditGroup(props) {
  const { setDialogEdit, idGroup } = props;
  const classes = useStyles();
  const history = useHistory();
  const [openDialogEnter, setOpenDialogEnter] = React.useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [messageError, setMessageError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const [imgpreview, setimgpreview] = React.useState("");
  const [imgBase64, setImgBase64] = React.useState("");
  const timer = React.useRef();

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);
  const [dataRegister, setDataRegister] = React.useState({
    idUsuario: "",
    domain: "",
  });

  // alert functions

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  const EditGroup = () => {
    var data = {
      logo: imgBase64,
      uri: dataRegister.domain,
    };

    console.log("dataEditar", data);

    let token = sessionStorage.getItem("Token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: " application/json",
      },
    };

    const URL = `${process.env.REACT_APP_URL}api/roles/${idGroup}`;
    axios
      .put(URL, data, config)
      .then((res) => {
        const data = res.data;
        setDialogEdit(false);

        console.log("PERFIL MODIF.", data);
        alert("se modifico con exito ");
      })
      .catch((error) => {
        alert("se produjo un error ");
        console.error(error);
      });
  };

  const handlesuccesSignUp = () => {
    setOpenDialogEnter(false);
    window.location.reload();
  };

  const handleInput = (event) => {
    setDataRegister({
      ...dataRegister,
      [event.target.name]: event.target.value,
    });
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const onChangeImg = async (e) => {
    let file = e.target.files[0];
    const base64 = await convertBase64(file);

    setimgpreview(base64);
    setImgBase64("");
    e.preventDefault();

    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const imagen = reader.result;
      var base64result = imagen.substr(reader.result.indexOf(",") + 1);
      setImgBase64(base64result);
    };

    if (!loading) {
      setSuccess(false);
      setLoading(true);
      timer.current = setTimeout(() => {
        setSuccess(true);
        setLoading(false);
      }, 2000);
    }
  };

  let disableform = true;
  if (imgBase64.length > 122 && dataRegister.domain) {
    disableform = false;
  }
  return (
    <Container component="main" maxWidth="xs">
      <Grid className={classes.paper}>
        {/* <Avatar className={classes.avatar}>
          <PersonAddIcon />
        </Avatar> */}
        <Typography component="h1" variant="h5">
          Editar grupo
        </Typography>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} align="center">
              <input
                accept=".jpg, .jpeg, .png"
                className={classes.input}
                id="contained-button-file"
                multiple
                type="file"
                name="PDF"
                onChange={onChangeImg}
              />
              <label htmlFor="contained-button-file">
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  disabled={loading}
                  className={buttonClassname}
                >
                  <Typography variant="h6" style={{ textTransform: "none" }}>
                    Cargar logotipo
                  </Typography>
                </Button>
              </label>
            </Grid>

            <Grid item xs={12} sm={12} align="center">
              {imgpreview.length > 0 ? (
                <img src={imgpreview} width="60%" height="auto" />
              ) : (
                ""
              )}
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                autoComplete="ruta"
                required
                name="domain"
                variant="outlined"
                fullWidth
                id="domain"
                value={dataRegister.domain}
                onChange={handleInput}
                label="Dominio"
                autoFocus
              />
            </Grid>
          </Grid>
          <Button
            onClick={EditGroup}
            fullWidth
            disabled={disableform}
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Registrar
          </Button>
        </form>
      </Grid>

      <Grid>
        <Dialog
          // fullWidth={true}
          // maxWidth="md"
          open={openDialogEnter}
          TransitionComponent={Transition}
          keepMounted
          // onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Registro exitoso"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <Typography
                variant="subtitle1"
                style={{
                  textTransform: "none",
                }}
              >
                El usuario ha sido registrado con éxito, podra iniciar sesión.
              </Typography>
              <div align="center">
                <img src={SigUp} alt="logo" width="350" height="240" />
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handlesuccesSignUp()} color="succes">
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
      <Grid className={classes.root}>
        <Snackbar
          open={openSnack}
          autoHideDuration={9000}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          onClose={handleCloseSnack}
        >
          <Alert onClose={handleCloseSnack} severity="error">
            {messageError}
          </Alert>
        </Snackbar>
      </Grid>
    </Container>
  );
}
