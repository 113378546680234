import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "components/CustomButtons/Button.js";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Header from "components/Header/Header.js";
import logo from "assets/img/ConCertezaAzul.png";
import { useHistory } from "react-router-dom";
import GridItem from "components/Grid/GridItem.js";
import TwitterIcon from "@material-ui/icons/Twitter";
import firmaelec from "assets/img/logoMano2.png";
import logoCDO from "assets/img/LOGOCDO.png";
import SignUp from "containers/signUp/signUp.js";
//tabpanel
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
//log
import {
    saveLog,
    saveLogFail
} from "containers/Logs/funcionesLog.js";


import { makeStyles, useTheme } from "@material-ui/core/styles";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,
    };
}

function Copyright() {
    return (
        <Grid container direction="row" justify="center" alignItems="center">
            <img src={logoCDO} alt="Con-Certeza" width="30" height="35" />
            <GridItem>
                <Typography variant="body2" color="textSecondary" align="center">
                    {"Powered by "}
                    <Link color="inherit" href="https://certificaciondigital.online/">
                        CDO
                    </Link>
                </Typography>
            </GridItem>
        </Grid>
    );
}

function Contacto() {
    return (
        <Grid container direction="row" justify="center" alignItems="center">
            <GridItem>
                <Typography variant="body2" color="textSecondary" align="center">
                    {"Contactanos: hola@con-certeza.mx "}
                </Typography>
            </GridItem>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100vh",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    image: {
        backgroundImage: "url(" + firmaelec + ")",
        backgroundRepeat: "no-repeat",
        backgroundColor:
            theme.palette.type === "light"
                ? theme.palette.grey[50]
                : theme.palette.grey[900],
        backgroundSize: "cover",
        backgroundPosition: "center",
    },
    paper: {
        margin: theme.spacing(7, 4),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 30
    },

    link: {
        flexDirection: "column",
        alignItems: "center",
    },
    titlel: {
        margin: theme.spacing(8, 4),
        display: "flex",
        flexDirection: "column",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    tableActionButtonIcon: {
        cursor: "pointer",
        width: "40px",
        margin: theme.spacing(1, 1),
        height: "40px",
    },
    subtitle: {
        marginTop: 50,
        textShadow: "5px 1px 10px white ",
        fontSize: "2.5rem",
        fontWeight: "450",
        marginLeft: "80px",
    },
}));

export default function SignInSide(props) {
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const classes = useStyles();
    const [email, setEmail] = React.useState("");
    const [password, setPassWord] = React.useState("");
    const history = useHistory();
    const [success, setSuccess] = React.useState(false);
    const [openSnack, setOpenSnack] = React.useState(false);
    const [messageError, setMessageError] = React.useState("");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const setSignUp = () => {
        setValue(1);
    };
    const handleSendLogin = () => {
        setSuccess(true);
        const data = new FormData();
        data.append("email", email);
        data.append("password", password);

        let urlLogin = `${process.env.REACT_APP_URL}api/auth/login`;

        axios
            .post(urlLogin, data, {})
            .then((response) => {
                console.log("login data:", response);
                const token = response.data.access_token;
                saveLog(token, "Login");
                setSuccess(false);
                localStorage.setItem("Token", token);

                history.push("/admin/dashboard");
            })
            .catch((error) => {
                // Error 😨
                saveLogFail("Login Fail", email);
                openAlert("El correo y/o contraseña son incorrectos");

                setSuccess(false);
                if (error.response) {
                    console.log(error.response.data.message);
                    // alert(error.response.data.message);
                } else if (error.request) {
                    console.log(error.request);
                }
                console.log(error.config);
            });
    };

    const handlelink = (data) => {
        if (data == "facebook") {
            window.open("https://www.facebook.com/firmadigitalconcerteza");
        } else if (data == "twitter") {
            window.open("https://twitter.com/firmaconcerteza");
        } else if (data == "linkedin") {
            window.open(
                "https://www.linkedin.com/showcase/firma-digital-con-certeza"
            );
        }
    };

    // alert functions

    const handleCloseSnack = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpenSnack(false);
    };

    const openAlert = (error) => {
        setOpenSnack(true);
        setMessageError(error);
    };

    const recoverPassword = () => {
        history.push("/recover/password");
    };

    let disabledLogin = true;
    if (email.length >= 6 && password.length >= 6) {
        disabledLogin = false;
    }
    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={false} sm={4} md={7} className={classes.image}>
                <Header
                    relative
                    color="transparent"
                    brand={
                        <div>
                            <img src={logo} alt="Con-Certeza" width="250" height="63" />
                        </div>
                    }
                // rightLinks={<HeaderLinks />}
                // {...rest}
                />
                <GridItem xs={8} sm={7} md={7}>
                    <div className={classes.note}>
                        <h3
                            className={classes.subtitle}
                            style={{
                                color: "#8304b3",
                            }}
                        >
                            La forma más sencilla y segura para firmar, certificar y
                            notificar documentos electrónicos con certeza jurídica
                        </h3>
                    </div>

                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <GridItem>
                        <FacebookIcon
                            color="primary"
                            className={classes.tableActionButtonIcon}
                            onClick={() => handlelink("facebook")}
                        />
                        <TwitterIcon
                            color="primary"
                            className={classes.tableActionButtonIcon}
                            onClick={() => handlelink("twitter")}
                        />
                        <LinkedInIcon
                            color="primary"
                            className={classes.tableActionButtonIcon}
                            onClick={() => handlelink("linkedin")}
                        />
                    </GridItem>
                </GridItem>
                <Box mt={7}>
                    <Contacto />
                    <br />
                </Box>
            </Grid>
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <AppBar position="static" color="default">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                    >
                        <Tab label="Inicia sesión" {...a11yProps(0)} />
                        <Tab label="Regístrate" {...a11yProps(1)} />
                    </Tabs>
                </AppBar>

                <TabPanel value={value} index={0} dir={theme.direction}>
                    <div className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Inicia sesión
                        </Typography>
                        <form className={classes.form} noValidate>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Correo"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                onChange={(event) => setEmail(event.target.value)}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Contraseña"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                onChange={(event) => setPassWord(event.target.value)}
                            />
                            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
                            <Button
                                fullWidth
                                variant="outlined"
                                color="success"
                                disabled={disabledLogin}
                                className={classes.submit}
                                onClick={handleSendLogin}
                            >
                                inicia sesión
                            </Button>
                            <Grid container className={classes.link}>
                                <Grid item>
                                    <Link onClick={recoverPassword} variant="body2">
                                        {"¿Olvidaste tu contraseña?"}
                                    </Link>
                                </Grid>
                            </Grid>
                            <Box mt={5}>
                                <Copyright />
                            </Box>
                        </form>
                    </div>
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                    <SignUp setValue={setValue} />
                </TabPanel>
            </Grid>
            <Backdrop className={classes.backdrop} open={success}>
                <Grid>
                    <CircularProgress size={44} className={classes.buttonProgress} />
                    <br></br>
                    <Typography variant="body2" color="textSecondary" align="center">
                        Espera un poco
                    </Typography>
                </Grid>
            </Backdrop>

            <div className={classes.root}>
                <Snackbar
                    open={openSnack}
                    autoHideDuration={9000}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    onClose={handleCloseSnack}
                >
                    <Alert onClose={handleCloseSnack} severity="error">
                        {messageError}
                    </Alert>
                </Snackbar>
            </div>
        </Grid>
    );
}
