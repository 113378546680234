import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import Cdo from "../../services/Cdo";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import { Grid } from "semantic-ui-react";

export default function PVWarningDialog(props) {
    const dispatch = useDispatch();

    const { pruebaVidaAceptado } = useSelector((state) => state.app);

    useEffect(() => {
        if (pruebaVidaAceptado === true)
            props.onHide(true);
    }, [pruebaVidaAceptado]);

    const onHideDlg = async function (val) {
        if (val) {
            await dispatch({
                type: 'SET_PRUEBA_VIDA_ACEPTADO',
                payload: true,
            });
            return;
        }

        props.onHide(val);


    }
    return (
        <React.Fragment>
            <Dialog
                open={props.show}
                keepMounted
                // onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">
                    {"Prueba de vida"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <Typography>
                            <div>
                                <div>
                                    El emisor del documento de firma solicitó una prueba de vida que consiste en grabar un audio y video de usted leyendo el texto que se muestra en pantalla. Si la lectura es correcta, el sistema permitirá realizar la firma.
                                </div>
                                <div style={{ width: "100%", textAlign: "right", marginTop: "1rem" }}>
                                    <Button
                                        size="large"
                                        variant="contained"
                                        color="secondary"
                                        style={{ marginRight: "1rem" }}
                                        onClick={() => onHideDlg(false)}
                                    >
                                        Cancelar
                                    </Button>
                                    <Button
                                        size="large"
                                        variant="contained"
                                        color="primary"
                                        onClick={() => onHideDlg(true)}
                                    >
                                        Continuar
                                    </Button>
                                </div>
                            </div>
                        </Typography>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}