import Http from './HttpProvider';

const CdoAdmin = {
    obtenerGrupos() {
        return Http.Fetch('GET', 'api/roles/groups');
    },
    async isEmailAvailable(email) {
        const resp = await Http.Fetch('GET', 'api/users/email-available/' + email);
        return resp.available === true;
    },
    async createCustomer(frm) {
        const resp = await Http.Fetch('POST', 'api/roles/create-customer', frm);
        return resp;
    },
    async updateCustomer(frm) {
        const resp = await Http.Fetch('POST', 'api/customassets/update', frm);
        return resp;
    },
    async fetchCompanyByCustomAssetsId(id) {
        const resp = await Http.Fetch('GET', 'api/customassets/' + id);
        return resp;
    },
    async createCorporacion(roles_id, name) {
        const resp = await Http.Fetch('POST', 'api/corporaciones', { roles_id, name });
        return resp;
    },
    async deleteCorporacion(id) {
        const resp = await Http.Fetch('DELETE', 'api/corporaciones/' + id);
        return resp && resp.ok === true;
    },
    async updateCorporacion(id, name) {
        const resp = await Http.Fetch('PUT', 'api/corporaciones/' + id, { name });
        return resp && resp._id === id;
    },
    async deleteUsuario(id) {
        const resp = await Http.Fetch('DELETE', 'api/users/' + id);
        return resp && resp.ok === true;
    },
    async createGroup(name, description, custom_assets_id) {
        const resp = await Http.Fetch('POST', 'api/roles', { name, description, custom_assets_id });
        return resp && resp.role ? resp.role : null;
    },
    async giveCompanyPermission(idRole) {
        const resp = await Http.Fetch('GET', 'api/roles/give-company-permission/' + idRole);
        return resp && resp.ok === true;
    },
    async deleteRole(id) {
        const resp = await Http.Fetch('DELETE', 'api/roles/' + id);
        return resp && resp.ok === true;
    },
    async createUser(email, first_name, last_name, password, phone, company, city) {
        const resp = await Http.Fetch('POST', 'api/users', { email, first_name, last_name, password, password_confirmation: password, phone, company, city });
        return resp && resp.id > 0 ? resp.id : 0;
    },
    async updateUser(id, email, first_name, last_name, password, phone, company, city) {
        password = typeof password === 'string' && password.length > 0 ? password : undefined;
        const resp = await Http.Fetch('PUT', 'api/users/' + id, { email, first_name, last_name, password, password_confirmation: password, phone, company, city, no_roles_update: true });
        return resp && resp.id > 0 ? resp.id : 0;
    },
    async assignRole(userId, roleId, main = false) {
        const resp = await Http.Fetch('PUT', `api/roles/assign-role/${userId}/${roleId}`, { main });
        return resp && resp.ok === true;
    },
    async removeRole(userId, roleId) {
        const resp = await Http.Fetch('DELETE', `api/roles/remove-role/${userId}/${roleId}`);
        return resp && resp.ok === true;
    },
}

export default CdoAdmin;