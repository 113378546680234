import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";
import axios from "axios";
import clsx from "clsx";
import Check from "@material-ui/icons/Check";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
//import Button from "components/CustomButtons/Button.js";
import Button from "@material-ui/core/Button";
import BackspaceIcon from "@material-ui/icons/Backspace";
import UndoIcon from "@material-ui/icons/Undo";
import IconButton from '@material-ui/core/IconButton';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import ControlPanel from "components/controlPanel/ControlPanel";
import Slide from "@material-ui/core/Slide";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DescriptionIcon from '@material-ui/icons/Description';
import VisibilityIcon from '@material-ui/icons/Visibility';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import { Document, Page, Text } from "react-pdf";
import { pdfjs } from "react-pdf";
import { useHistory } from "react-router-dom";
//pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import pdflogo from "./pdflogo.png"
import { isMobile } from "react-device-detect";
import './style.css';
import VistaPrevia from "./VistaPrevia";
import Notificados from "./Notificados";
import notificacionexitosa from "./notificacionexitosa.png";
import StepConnector from "@material-ui/core/StepConnector";

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "30%",
      marginRight: "auto",
      marginTop: "13%",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  divacciones: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  input: {
    display: "none",
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    float: "right",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  footer: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginBottom: theme.spacing(1),
      padding: theme.spacing(3),
    },
  },
}));

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage:
      " linear-gradient(79deg, rgba(76,175,80,1) 0%, rgba(102,187,106,1) 63%, rgba(92,184,96,1) 100%)",
  },
});

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  completed: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;
  let icons = {};

  icons = {
    1: <DescriptionIcon />,
    2: <VisibilityIcon />,
    3: <GroupAddIcon />,
  }

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {completed ? <Check /> : icons[String(props.icon)]}
    </div>
  );
}

function getSteps() {
  return ["Texto y Anexos", "Vista Previa", "Destinatarios"];
}

function getStepContent(stepIndex) {
  switch (stepIndex) {
    case 0:
      return "Texto y Anexos";
    case 1:
      return "Vista Previa";
    case 2:
      return "Destinatarios y Observadores";
    default:
      return "Unknown stepIndex";
  }
}

export default function Flujo(props) {
  const ref = React.createRef();
  function goTop() {
    ref.current.scrollIntoView();
  }

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [firmantes, setFirmantes] = React.useState("");
  const [openDialogEnviado, setOpenDialogEnviado] = React.useState(false);
  const [titulo, setTitulo] = React.useState("NOTIFICACION-CERTIFICADA.pdf");
  const history = useHistory();
  const classes = useStyles();
  const classes2 = useStyles2();
  //pdf
  const [openDialog, setOpenDialog] = React.useState(false);
  const [base64, setbase64] = React.useState("");
  const [base64Principal, setbase64Principal] = React.useState("");
  const [documentoPrincipal, setDocumentoPrincipal] = React.useState("");
  const [scale, setScale] = React.useState(1.0);
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pdfHeigth, setpdfHeigth] = React.useState(575);
  const [pdfWidth, setpdfWidth] = React.useState(660);

  const handleVolver = () => {
    let uri = localStorage.getItem("uri");
    if (props.setActiveRender) {
      props.setActiveRender(0);
    } else {
      history.push(`/${uri}/serviciosLegales`);
    }
  };

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  const handleclosepdf = () => {
    setOpenDialog(false);
    setbase64("");
    setScale(1.0);
  };

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  //steps
  const [activeStep, setActiveStep] = React.useState(0);
  const [paperWidth, setPaperWidth] = React.useState("90%");

  const [images, setImages] = React.useState([]);
  const [imagesPDF, setImagesPDF] = React.useState([]);

  const [documento, setDocumento] = React.useState("");


  const [texto, setTexto] = React.useState("");

  const [foto, setFoto] = React.useState("");
  const [dialogoFoto, setDialogoFoto] = React.useState(false);

  const handleclosefoto = () => {
    setDialogoFoto(false);
    setFoto("");
  };

  const steps = getSteps();
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleChange = (event) => {
    console.log(event.target.files);

  }

  async function pdftoimage(url) {
    return new Promise(resolve => {
      var data = [];
      var loadingTask = pdfjs.getDocument(url);
      loadingTask.promise.then(function (pdf) {
        var totalPages = pdf.numPages

        for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
          pdf.getPage(pageNumber).then(function (page) {
            var scale = 1.5;
            var viewport = page.getViewport({ scale: scale });
            var canvas = document.createElement('canvas');
            var context = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            var renderContext = { canvasContext: context, viewport: viewport };
            var renderTask = page.render(renderContext);
            renderTask.promise.then(function () {
              var downloadimageurl = canvas.toDataURL("image/jpeg", 0.94);
              //data.push(canvas.toDataURL('image/png'))
              data.push(canvas.toDataURL("image/jpeg", 0.94))
              /*
              var downloadimageurl = downloadimageurl.replace(
                   /^data:image\/[^;]/,
                      "data:application/octet-stream"
                      );
              data.push(downloadimageurl)
              */
              console.log(data.length + ' page(s) loaded in data')
            });
          });
        }
        console.log("data", data);
      }, function (reason) {
        // PDF loading error
        console.error(reason);
      });
      resolve(data);
    });
  }


  async function handleVistaPrevia() {
    goTop();
    console.log("arrImages", images);
    handleNext();
  }

  function crearContrato(archivo) {

    let urlDocumento = `${process.env.REACT_APP_URL}api/documentos`;
    let tokenCC = sessionStorage.getItem("Token");

    const data = new FormData();
    data.append("filename", archivo, titulo + ".pdf");
    data.append("descripcion", "Notificación Certificada");
    data.append("documento_types_id", "6");


    var config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + tokenCC,
      },
    };
    return new Promise(function (resolve, reject) {
      axios.post(urlDocumento, data, config, {}).then(
        (response) => {
          var result = response.data;
          console.log("Processing Request");
          resolve(result);
        },
        (error) => {
          reject(error);
          alert(error);
          //setMensajeError(true);
        }
      );
    });


  }

  function dataURLtoBlob(dataurl) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  async function handleFirmantes() {
    goTop();
    var bl = dataURLtoBlob(base64Principal);
    var ContratoCC = await crearContrato(bl);
    setDocumentoPrincipal(ContratoCC[1]);
    handleNext();
  }

  const fileToDataUri = (image) => {
    return new Promise((res) => {
      const reader = new FileReader();
      const { type, name, size } = image;
      reader.addEventListener('load', () => {
        res({
          base64: reader.result,
          name: name,
          type,
          size: size,
        })
      });
      reader.readAsDataURL(image);
    })
  }

  const uploadImage = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const newImagesPromises = []
      for (let i = 0; i < e.target.files.length; i++) {
        newImagesPromises.push(fileToDataUri(e.target.files[i]))
      }
      const newImages = await Promise.all(newImagesPromises)
      setImages([...images, ...newImages])
      console.log(newImages);
    }

    //e.target.value = "";
  }

  const clickLimpiar = () => {
    setImages([]);
  }

  function handleDelete() {
    let urlDeleteDoc = `${process.env.REACT_APP_URL}api/documentos/${documentoPrincipal._id}`;
    let token = sessionStorage.getItem("Token");
    console.log("URL DELETE");
    axios
      .delete(urlDeleteDoc, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {},
      })
      .then((response) => {
        console.log("respuesta delete", response);
      })
      .catch((error) => {
        // Error 😨
        if (error.response) {
          console.log(error.response.data.message);
          //openAlert(error.response.data.message);
          alert(error.response.data.message);
        } else if (error.request) {
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  const handleCancelar = () => {
    goTop();
    setImages([]);
    setActiveStep(0);
    setTexto("");
  }

  const handleEliminar = () => {
    handleDelete();
    goTop();
    setImages([]);
    setActiveStep(0);
    setTexto("");
  }

  const handleEnviar = () => {
    let token = sessionStorage.getItem("Token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: " application/json",
      },
    };

    const urlInit = `${process.env.REACT_APP_URL}api/documentos/${documentoPrincipal._id}/notify`;
    axios.put(urlInit, {}, config).then((response) => {
      console.log("NOTIFICACIÓN:", response);
    });
    goTop();
    setImages([]);
    setActiveStep(0);
    setTexto("");
    setOpenDialogEnviado(true);
  }

  const handleInput = (event) => {
    if (event.target.value.length > 3) {
      setTexto(event.target.value);
    }
  };

  function findLongestWord(str) {

    var words = str.split(' ');
    var longest = 0;

    for (var i = 0; i < words.length; i++) {
      if (words[i].length > longest) {
        longest = words[i].length;
      }
    }
    //console.log("LONGEST:",longest);
    return longest;
  }

  let disableform = true;
  if (findLongestWord(texto) > 0 && findLongestWord(texto) < 65) {
    disableform = false;
  }

  React.useEffect(() => {
    document.body.style.overflow = "unset";
    if (isMobile) {
      setPaperWidth("100%");
      setpdfHeigth(500);
      setpdfWidth(290);
    }
  }, []);

  return (
    <React.Fragment>
      {activeStep == 0 ? (
        <Button
          size="large"
          style={{
            marginTop: "-9px",
            height: "30px",
          }}
          startIcon={<ArrowBackIcon />}
          onClick={() => handleVolver()}
        >
          Regresar
        </Button>
      ) : (
        ""
      )}
      <div style={{ marginTop: "0px" }} ref={ref}>
        <Grid
          style={{
            marginTop: "0",
            width: paperWidth,
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Paper>
            <Typography variant="h4" align="center">
              Notificaciones Certificadas
            </Typography>
            {/*
          <Typography align="center">
            Generar Notificación Certificada
          </Typography>
          */}
            <Stepper
              activeStep={activeStep}
              className={classes.stepper}
              connector={<ColorlibConnector />}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Paper>
        </Grid>
      </div>
      {activeStep === 0 ? (
        <Grid
          style={{
            width: paperWidth,
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Paper>
            <Typography variant="h5" align="center">
              Título del documento:
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextField
                id="outlined-basic"
                label="Ingrese título"
                variant="outlined"
                inputProps={{ maxLength: 100 }}
                style={{ width: "80%" }}
                onChange={(e) => {
                  setTitulo(e.target.value)
                }}
              />
            </div>
            <br />
          </Paper>
          <Paper>
            <Typography variant="h5" align="center">
              Texto de la notificación<font color="red">*</font>
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextareaAutosize
                aria-label="minimum height"
                rowsMin={10}
                placeholder=""
                style={{ width: "80%" }}
                onChange={handleInput}
              />
            </div>
            <section className="hero">
              <label className="fileUploaderContainer">
                Clic para subir anexos
                <input type="file" id="fileUploader" onChange={uploadImage} multiple accept="application/pdf,image/*" />
              </label>
              <Button
                variant="contained"
                color="secondary"
                onClick={clickLimpiar}>
                <UndoIcon /> Borrar anexos
              </Button>
              <div>

                {
                  images.length > 0
                    ? images.map((imageObj, i) => {
                      return (
                        <React.Fragment key={i}>
                          {imageObj.base64.search("data:image") >= 0 ? (
                            <img
                              width="50"
                              height="50"
                              src={imageObj.base64}
                              title={imageObj.name}
                              onClick={() => {
                                setDialogoFoto(true);
                                setFoto(imageObj.base64);
                              }}
                            />
                          )
                            : (
                              <React.Fragment>
                                <img
                                  width="50"
                                  height="50"
                                  src={pdflogo}
                                  title={imageObj.name}
                                  onClick={() => {
                                    setOpenDialog(true);
                                    setbase64(imageObj.base64);
                                  }}
                                />
                              </React.Fragment>
                            )}
                        </React.Fragment>
                      )
                    })
                    : null
                }
              </div>
            </section>
            <Button
              variant="contained"
              color="primary"
              disabled={disableform}
              onClick={() => { handleVistaPrevia() }}
              style={{
                float: "right",
                marginBottom: "10%",
                marginTop: "10px",
                //backgroundColor: "#3f51b5",
                //color: "white"
              }}
            >
              Vista Previa
            </Button>
          </Paper>
        </Grid>
      ) :
        activeStep === 1 ? (
          <React.Fragment>
            <Grid
              style={{
                width: paperWidth,
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <Paper>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <VistaPrevia
                    texto={texto}
                    images={images}
                    setbase64Principal={setbase64Principal}
                  />
                </div>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => { handleFirmantes() }}
                  style={{
                    float: "right",
                    marginBottom: "10%",
                    marginTop: "20px"
                    //backgroundColor: "#3f51b5",
                    //color: "white"
                  }}
                >
                  Destinatarios
                </Button>
                <Button
                  variant="contained"
                  color="danger"
                  onClick={() => { handleCancelar() }}
                  style={{
                    float: "right",
                    marginBottom: "20px",
                    marginTop: "20px",
                    marginRight: "5px",
                    backgroundColor: "red", color: "white"
                  }}
                >
                  Cancelar
                </Button>
              </Paper>
            </Grid>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Grid
              style={{
                width: paperWidth,
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <Paper>
                <Typography variant="h5" align="center">
                  Registrar Destinatarios
                </Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Notificados
                    documentoPrincipal={documentoPrincipal}
                    base64Principal={base64Principal}
                    setFirmantes={setFirmantes}
                  />
                </div>
                <Button
                  variant="contained"
                  disabled={(firmantes && firmantes.length) ? false : true}
                  onClick={() => { handleEnviar() }}
                  style={{
                    float: "right",
                    marginBottom: "10%",
                    backgroundColor: "#3f51b5",
                    color: "white"
                  }}
                >
                  <Typography style={{ textTransform: "none" }}>
                    Enviar Notificación
                  </Typography>
                </Button>
                <Button
                  variant="contained"
                  onClick={() => { handleEliminar() }}
                  style={{
                    float: "right",
                    marginBottom: "10%",
                    backgroundColor: "white",
                    color: "red"
                  }}
                >
                  <Typography style={{ textTransform: "none" }}>
                    Eliminar
                  </Typography>
                </Button>
              </Paper>
            </Grid>
          </React.Fragment>
        )
      }

      {/*dialogo para ver pdf*/}
      <Dialog
        open={openDialog}
        maxWidth={"md"}
        // fullWidth={true}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Tu documento"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {base64.length === 0 ? (
              <Backdrop>
                <CircularProgress
                  size={44}
                />
              </Backdrop>
            ) : (
              <div align="center">
                <ControlPanel
                  scale={scale}
                  setScale={setScale}
                  numPages={numPages}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                  file={base64}
                />
                <Document
                  error="Error al intentar cargar el PDF"
                  loading="Cargando..."
                  file={base64}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  <Page
                    scale={scale}
                    height={pdfHeigth}
                    width={pdfWidth}
                    renderTextLayer={false}
                    pageNumber={pageNumber}
                  />
                </Document>

                <div className="text-center">
                  <p>
                    Página {pageNumber || (numPages ? 1 : "--")} de{" "}
                    {numPages || "--"}
                  </p>
                  <button
                    className="btn btn-dark"
                    type="button"
                    disabled={pageNumber <= 1}
                    onClick={previousPage}
                  >
                    Atrás
                  </button>{" "}
                  <button
                    className="btn btn-dark"
                    type="button"
                    disabled={pageNumber >= numPages}
                    onClick={nextPage}
                  >
                    Siguiente
                  </button>
                </div>
              </div>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleclosepdf} color="success">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      {/*fin dialogo para ver pdf*/}


      {/*dialogo para ver IMAGEN*/}
      <Dialog
        open={dialogoFoto}
        maxWidth={"md"}
        // fullWidth={true}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"IMAGEN"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <img
              width="250"
              src={foto}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleclosefoto} color="success">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      {/*fin dialogo para ver IMAGEN*/}

      {/*dialogo ENVIADO*/}
      <Dialog
        open={openDialogEnviado}
        maxWidth={"md"}
        // fullWidth={true}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">

        </DialogTitle>
        <DialogContent>
          <div>
            <Typography variant="h5" gutterBottom>
              Notificación Enviada
            </Typography>
            <Typography variant="subtitle1" style={{ textTransform: "none" }}>
              Su notificación ha sido enviada de manera exitosa
            </Typography>
            <div align="center">
              <img src={notificacionexitosa} width="260" height="220" />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setOpenDialogEnviado(false);
            props.setActiveRender(0);
          }} color="success">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      {/*fin dialogo ENVIADO*/}
    </React.Fragment>
  )
}
