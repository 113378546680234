import React from "react";
import axios from "axios";
import moment from "moment";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import VisibilityIcon from '@material-ui/icons/Visibility';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import PDFView from "./PDFView";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import CircularProgress from "@material-ui/core/CircularProgress";

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";
import styles2 from "assets/jss/material-dashboard-react/components/tasksStyle.js";
const useStyles = makeStyles(styles);
const useStylestable = makeStyles(styles2);
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

export default function BuscarDocumentos(){
    const classes = useStyles();
    const classes2 = useStylestable();
    const [codigo, setCodigo] = React.useState("");
    const [expediente, setExpediente] = React.useState("");
    const [desde, setDesde] = React.useState("");
    const [hasta, setHasta] = React.useState("");
    const [docuData, setDocuData] = React.useState([]);
    const [base64, setBase64] = React.useState(false);
    const [modalVer, setModalVer] = React.useState(false);
    const [nombreArchivo, setNombreArchivo] = React.useState("");
    const [documentoShow, setDocumentoShow] = React.useState("");
    const [modalShow, setModalShow] = React.useState(false);
    const [openMensaje, setOpenMensaje] = React.useState(false);
    const [OpenEspera, setOpenEspera] = React.useState(false);
    const [mensaje, setMensaje] = React.useState(false);
    const [severity, setSeverity] = React.useState("info");
  const handleCloseMensaje = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenMensaje(false);
  };


  const localization = {
    body: {
      emptyDataSourceMessage: "No hay datos por mostrar",
      addTooltip: "Añadir",
      deleteTooltip: "Eliminar",
      editTooltip: "Editar",
      filterRow: {
        filterTooltip: "Filtrar",
      },
    },
    header: {
      actions: "",
    },
    pagination: {
      firstAriaLabel: "Primera página",
      firstTooltip: "Primera página",
      labelDisplayedRows: "{from}-{to} de {count}",
      labelRowsPerPage: "Filas por página:",
      labelRowsSelect: "filas",
      lastAriaLabel: "Ultima página",
      lastTooltip: "Ultima página",
      nextAriaLabel: "Pagina siguiente",
      nextTooltip: "Pagina siguiente",
      previousAriaLabel: "Pagina anterior",
      previousTooltip: "Pagina anterior",
    },
    toolbar: {
      exportAriaLabel: "Exportar",
      exportTitle: "Exportar",
      exportCSVName: "Exportar a CVS",
      exportPDFName: "Exportar a PDF",
      searchPlaceholder: "Buscar",
      searchTooltip: "Buscar",
    },
  };


  function alerta(texto, severidad) {
    setOpenMensaje(true);
    setMensaje(texto);
    setSeverity(severidad);
  }

  function setFechas() {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let separator = "-";

    let fecha = `${year}${separator}${month < 10 ? `0${month}` : `${month}`
      }${separator}${date}`;
    setDesde(fecha);
    setHasta(fecha);
  }

  const handleFecha = () => {
    console.log("FEcha")
  }

  const onChangeDesde = (e) => {
    setDesde(e.target.value);
    console.log("desde", e.target.value); // Works
  };

  const onChangeHasta = (e) => {
    setHasta(e.target.value);
    console.log("hasta", e.target.value); // Works
  };

  function handleBuscar(tipo){
    alerta("Buscando documentos","info")
    setOpenEspera(true);
    var dataForm = new FormData();
    switch(tipo) {
        case 1:
            dataForm.append('tipo', '1');
            dataForm.append('desde', desde);
            dataForm.append('hasta', hasta);
          break;
        case 2:
            dataForm.append('tipo', '2');
            dataForm.append('codigo', codigo);
          break;
        case 3:
            dataForm.append('tipo', '3');
            dataForm.append('expediente', expediente);
        break;
      }
      let token = sessionStorage.getItem("Token");

    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_URL}api/expedientes/search/document`,
      headers: {
        'Accept': 'application/json',
        Authorization: `Bearer ${token}`,
      }, 
      data: dataForm
    };

    axios(config)
      .then(function (response) {
        console.log("Resultado: ", response.data);
        switch(tipo) {
            case 1:
                setDocuData(response.data)
                setExpediente("")
                break;
            case 2:
                setDocuData(response.data)
                setCodigo("")
                break;
            case 3:
              setDocuData(response.data)
                break;
        }
        setOpenEspera(false);
      })
      .catch(function (error) {
        console.log(error);
        //alerta(error, "error")
        setOpenEspera(false);
        setDocuData([])
      });
 }

function handleBase64(id) {
  let token = sessionStorage.getItem("Token");
 
  var config = {
    method: 'get',
    url: `${process.env.REACT_APP_URL}api/documentos/${id}/preview/download`,
    headers: {
      'Accept': 'application/json',
      Authorization: `Bearer ${token}`,
    }
  };
  axios(config)
    .then(function (response) {
      //console.log("base64:",response.data)
      setBase64("data: application / pdf; base64, " + response.data);
      //setGuardar(false);
      setModalVer(true);
    })
    .catch(function (error) {
      console.log(error);
      setBase64("");
    });
}

    return (
        <>
        <Grid container>
                <Grid item xs={12} sm={12}>
                    <b>BÚSQUEDA DE DOCUMENTOS</b>
                    <hr />
                </Grid>
                <Grid item xs={12} sm={4}>
                <Card variant="outlined">
                    <CardContent>
                    <Typography
                        className={classes.title}
                        color="textPrimary"
                        gutterBottom
                    >
                        <b>Fecha de carga:</b>
                    </Typography>

                    <TextField
                        id="dateDesde"
                        label="Desde"
                        type="date"
                        onChange={onChangeDesde}
                        value={desde}
                        className={classes.textField}
                        InputLabelProps={{
                        shrink: true,
                        }}
                    />
                    <TextField
                        id="dateHasta"
                        label="Hasta"
                        type="date"
                        onChange={onChangeHasta}
                        value={hasta}
                        className={classes.textField}
                        InputLabelProps={{
                        shrink: true,
                        }}
                    />
                    <hr />
                    <Button 
                        variant="outlined" 
                        color="primary" 
                        onClick={()=>{
                            if (desde!="" && hasta!="")
                            {
                                handleBuscar(1)
                            }
                            else
                                alerta("Debe ingresar rango de fechas", "error")
                        }} 
                    >
                        BUSCAR
                    </Button>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12} sm={4}>
                <Card variant="outlined">
                    <CardContent>
                    <Typography
                        color="textPrimary"
                        gutterBottom
                    >
                        <b>Codigo:</b>
                    </Typography>

                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="codigo"
                        label=""
                        fullWidth
                        variant="outlined"
                        value={codigo}
                        onChange={(e) => {
                        setCodigo(e.target.value)
                        }}
                    />
                    <hr />
                    <Button 
                        variant="outlined" 
                        color="primary" 
                        onClick={()=>{
                            if (codigo!="")
                                handleBuscar(2)
                            else
                                alerta("Debe ingresar codigo del documento", "error")
                        }} 
                        style={{ marginLeft: 20 }}
                    >
                        BUSCAR
                    </Button>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12} sm={4}>
                <Card variant="outlined">
                    <CardContent>
                    <Typography
                        color="textPrimary"
                        gutterBottom
                    >
                        <b>Expediente:</b>
                    </Typography>

                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="expediente"
                        label=""
                        fullWidth
                        variant="outlined"
                        value={expediente}
                        onChange={(e) => {
                        setExpediente(e.target.value)
                        }}
                    />
                    <hr />
                    <Button 
                        variant="outlined" 
                        color="primary" 
                        onClick={()=>{
                            if (expediente!="")
                              handleBuscar(3)
                            else
                                alerta("Debe ingresar número del expediente","error")
                        }} 
                        style={{ marginLeft: 20 }}
                    >
                        BUSCAR
                    </Button>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>

        
        <Grid container style={{ marginTop: 10 }}>
                  <div style={{ width: "100%" }}>
                  {OpenEspera ? (
                        <CircularProgress />
                      ) : null}
                    <MaterialTable
                      localization={localization}
                      options={{
                        exportButton: { csv: true, pdf: false },
                        exportAllData: true,
                        draggable: false,
                        pageSize: 20, // make initial page size
                        pageSizeOptions: [20, 50, 100, 500, 1000],
                        actionsColumnIndex: -1,
                      }}
                      columns={[
                        {
                          title: "Compañía",
                          field: "users.company",
                        },
                        { title: "Documento", field: "filename" },
                        { title: "Codigo", field: "meta.custom_id" },
                        {
                          title: "Fecha",
                          field: "created_at",
                          render: (row) => (
                            <span>
                              {row["created_at"]
                                ? moment(
                                  row["created_at"]
                                ).format("DD/MM/YYYY")
                                : null}
                            </span>
                          ),
                        },
                        { title: "Expediente", field: "expediente.custom_id" },
                        { title: "Nombre", field: "expediente.name" },
                        { title: "Estatus", field: "status.name" },
                        { title: "Emisor", field: "users.name" },
                        { title: "ID", field: "_id" },
                      ]}
                      data={docuData}
                      title={"DOCUMENTOS (" + docuData.length + ")"}
                      actions={[
                        {
                          icon: () => (
                            <InfoIcon
                              className={
                                classes2.tableActionButtonIcon +
                                " " +
                                classes2.edit
                              }
                            />
                          ),
                          tooltip: "Información del documento",
                          onClick: (event, rowData) => {
                            setNombreArchivo(rowData);
                            setDocumentoShow(rowData);
                            setModalShow(true);
                          },
                        },
                          {
                            icon: () => (
                              <VisibilityIcon
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                              />
                            ),
                            tooltip: "Visualizar PDF",
                            onClick: (event, rowData) => {
                              //console.log(rowData)
                              alerta("Abriendo documento, por favor espere unos segundos", "info")
                              setNombreArchivo(rowData);
                              handleBase64(rowData._id);
                            },
                          },
                        ]}
                    />
                  </div>
                </Grid>

      {/*DIALOGO VER PDF*/}
      <Dialog
        fullWidth={true}
        maxWidth='md'
        open={modalVer}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {nombreArchivo.name}
        </DialogTitle>
        <DialogContent>
          {base64 ? <PDFView base64={base64} /> : ("NO hay PDF")}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            //color="primary"
            onClick={() => {
              setModalVer(false)
            }}
            className={classes.botondanger}
          >
            Cerrar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {

              const downloadLink = document.createElement("a");

              downloadLink.href = base64;
              downloadLink.download = nombreArchivo.filename + ".pdf";
              downloadLink.click();
            }}
          //className={classes.botondanger}
          >
            Descargar
          </Button>

        </DialogActions>
      </Dialog>
      {/* FIN VER PDF*/}

      {/*DIALOGO INFO*/}
      <Dialog
        fullWidth={true}
        maxWidth='sm'
        open={modalShow}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Documento
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {documentoShow ? (
              <>
                <b>Nombre:</b> {documentoShow.filename}
                <br></br>
                <b>Fecha de registro:</b> {moment(documentoShow.created_at).format(
                  "DD/MM/YYYY HH:mm:ss"
                )}
                <br></br>
                <b>Código:</b> {documentoShow.meta && documentoShow.meta.custom_id ? documentoShow.meta.custom_id : null}
                <br></br>
                <b>Usuario:</b> {documentoShow.users && documentoShow.users.name ? documentoShow.users.name + " (" + documentoShow.users.email + ")" : null}
                <br></br>
                <b>Estatus:</b> {documentoShow.status && documentoShow.status.name ? documentoShow.status.name : null}
                <br></br>
                {documentoShow.reason ? (
                  <React.Fragment>
                    <font color="red"><b>{documentoShow.reason}</b></font>
                    <br></br>
                    <font color="red">Fecha de rechazo:</font>{" "}
                    <font color="red">
                      {moment(documentoShow.stopped_at).format(
                        "DD/MM/YYYY HH:mm:ss"
                      )}
                    </font>
                    <br></br>
                  </React.Fragment>
                ) : null}
                <b>Hash:</b> <font size="1">{documentoShow.hash}</font>
                <br></br>
                <b>ID:</b> {documentoShow._id}
                <br></br>
                <b>Tamaño:</b> {documentoShow.size}
                <br></br>
              </>
            ) : null}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setModalShow(false)
            }}
          //          className={classes.botondanger}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      {/* FIN SHOW INFO*/}


        {/*MENSAJE*/}
      <Snackbar
        open={openMensaje}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        onClose={handleCloseMensaje}>
        <Alert onClose={handleCloseMensaje} severity={severity}>
          {mensaje}
        </Alert>
      </Snackbar>
      {/*FIN MENSAJE*/}
        </>
    )
}