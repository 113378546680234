import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import axios from "axios";
import PDFView from "../Expedientes/PDFView";
import AppBar from "@material-ui/core/AppBar";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import Snackbar from "components/Snackbar/Snackbar.js";
import ErrorIcon from "@material-ui/icons/Error";
import CardHeader from "components/Card/CardHeader";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  input: {
    display: "none",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  appbar: {
    alignItems: "center",
  },
  botondanger: {
    backgroundColor: "red",
    color: "white",
    margin: 2,
    float: "right",
  },
  botongreen: {
    backgroundColor: "green",
    color: "white",
    margin: 2,
    float: "right",
  },
  formControl: {
    marginTop: 10,
    width: "80%",
  },
}));

export default function Almacenar(props) {
  const classes = useStyles();
  const [base64, setBase64] = React.useState("");
  const [documento, setDocumento] = React.useState("");
  const [desde, setDesde] = React.useState("");
  const [hasta, setHasta] = React.useState("");
  const [diasAlerta, setDiasAlerta] = React.useState(0);
  const [diasAlert, setDiasAlert] = React.useState(0);
  const [alerta, setAlerta] = React.useState("");
  const [messageError, setMessageError] = React.useState("");
  const [openAlert, setOpenAlert] = React.useState("");
  const [checked, setChecked] = React.useState(false);
  const [descripcion, setDescripcion] = React.useState("");
  const [inicio, setInicio] = React.useState("");
  const [termino, setTermino] = React.useState("");
  const [data, setData] = React.useState({});
  const [idClases, setIdClases] = React.useState("")
  const [selectClases, setSelectClases] = React.useState([])

  const [tipoClases, setTipoClases] = React.useState({
    codigo: "Selecciona la clase de documento ",
    name: "Selecciona la clase de documento ",
  })
  const [selectCustomMeta, setSelectCustomMeta] = React.useState("");
  const [customMeta, setCustomMeta] = React.useState([]);

  const showNotificacion = (message) => {
    setOpenAlert(true);
    setMessageError(message);
    setTimeout(function () {
      setOpenAlert(false);
    }, 6000);
  };

  const handlechangeCustom = (event) => {
    setData({});
    const result = selectCustomMeta.filter(
      (item) => item._id == event.target.value
    );
    setCustomMeta(result);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      if (diasAlerta === 0) {
        setDiasAlert(30);
        setDiasAlerta(30);
      } else {
        setDiasAlert(diasAlerta);
      }
    } else {
      setDiasAlert(0);
      setDiasAlerta(0);
    }
  };
  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  const handlechangeClases = (event) => {
    console.log("idclases", event.target.value)
    setIdClases(event.target.value)
    let datal = {
      codigo: event.target.value,
      name: event.target.value,
    }
    setTipoClases(datal)
  }
  function getCustomMeta() {
    let token = sessionStorage.getItem("Token");
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_URL}api/custommeta`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then(function (response) {
        // console.log("MetadatosOLV:", response.data);
        setSelectCustomMeta(response.data);
      })
      .catch(function (error) {
        console.log(error);
        setSelectCustomMeta("");
      });
  }

  const Textos = () => {
    // {
    //   DETALLE: "Escribir detalle",
    //   NOMBRE: "Escribir nombre",
    // }
    let mainData = customMeta[0].fields;
    return (
      <React.Fragment>
        <br />
        <br />
        <Grid container spacing={3}>
          {Object.keys(mainData).map((_itm, index) => (
            <>
              <TextField
                style={{ marginTop: 5 }}
                fullWidth
                key={index}
                label={_itm}
                //value={data[index]}
                helperText={"* " + mainData[_itm]}
                onChange={(e) => {
                  handleChangeMetadatos(_itm, e);
                  // const { value, id } = e.target;
                  // //const newArray = [...data];
                  // let newObject = [...data];
                  // //newArray[id] = value;
                  // newObject = [...newObject, { name: _itm, value: value }];
                  // setData(newObject);
                }}
                //onBlur={handleChange}
                name="name"
                id={index.toString()}
                variant="outlined"
              />
              <hr />
            </>
          ))}
        </Grid>
      </React.Fragment>
    );
  };

  const handleChangeMetadatos = (atributo, event) => {
    setData({
      ...data,
      [atributo]: event.target.value,
    });
    // clg
  };

  const handleGuardar = async () => {

    const dataForm = new FormData();

    dataForm.append("filename", props.file);

    if (props.carpeta) {
      dataForm.append("folder", props.carpeta);
    }

    dataForm.append("documento_types_id", 11);

    dataForm.append("meta[info]", JSON.stringify(data));

    if (descripcion !== "") {
      dataForm.append("description", descripcion);
    }

    if (inicio !== "") {
      dataForm.append("meta[vigencia_start]", inicio + "00:00:00");
    }
    if (termino !== "") {
      dataForm.append("meta[vigencia_end]", termino + "23:59:59");
    }
    if (diasAlert > 0) {
      dataForm.append("meta[dias_alerta]", diasAlert);
    }
    console.log("fiLE NEW", dataForm);
    let url = `${process.env.REACT_APP_URL}api/documentos`;
    let token = sessionStorage.getItem("Token");

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
        Accept: " application/json",
        type: "formData",
      },
    };

    axios
      .post(url, dataForm, config, {
        // receive two parameter endpoint url ,form data
      })
      .then((response) => {
        // Respuesta del servidor
        let data = response.data[0];
        if (props.setRefresh) props.setRefresh(props.idFolderActual);

        if (props.setOpen) props.setOpen(false);

        props.refreshTables();
      })
      .catch((error) => {
        // Error 😨
        if (error.response.data.errors) {
          //openAlert("El archivo debe ser de tipo PDF sin ninguna encriptacion");
          let errors = error.response.data.errors;
          if (errors["meta.vigencia_start"]) {
            showNotificacion(
              "Por favor indique una fecha de inicio de vigencia o borre la fecha de fin de vigencia"
            );
          } else {
            let textoerror = JSON.stringify(error.response.data.errors);
            showNotificacion(
              textoerror.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "")
            );
          }
        } else if (error.response.data.message) {
          showNotificacion(error.response.data.message);
        } else {
          showNotificacion("Su documento tiene algun problema");
        }
      });
  };



  async function saveDocumento(data) {
    {

      const dataForm = new FormData();

      dataForm.append("filename", props.file);

      if (props.carpeta) {
        dataForm.append("folder", props.carpeta);
      }

      dataForm.append("documento_types_id", 11);

      console.log("fiLE NEW", dataForm);
      let url = `${process.env.REACT_APP_URL}api/documentos`;
      let token = sessionStorage.getItem("Token");

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          Accept: " application/json",
          type: "formData",
        },
      };

      axios
        .post(url, dataForm, config, {
          // receive two parameter endpoint url ,form data
        })
        .then((response) => {
          // Respuesta del servidor
          let data = response.data[0];
          console.log("DATA RESPUESTA:", data)
          setDocumento(data.id);
          if (props.setRefresh) props.setRefresh(props.idFolderActual);
          //if (props.setOpen) props.setOpen(false);
          props.refreshTables();
        })
        .catch((error) => {
          // Error 😨
          if (error.response.data.errors) {
            //openAlert("El archivo debe ser de tipo PDF sin ninguna encriptacion");
            let errors = error.response.data.errors;
            if (errors["meta.vigencia_start"]) {
              showNotificacion(
                "Por favor indique una fecha de inicio de vigencia o borre la fecha de fin de vigencia"
              );
            } else {
              let textoerror = JSON.stringify(error.response.data.errors);
              showNotificacion(
                textoerror.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "")
              );
            }
          } else if (error.response.data.message) {
            showNotificacion(error.response.data.message);
          } else {
            showNotificacion("Su documento tiene algun problema");
          }
          //if (props.setRefresh) props.setRefresh(props.idFolderActual);
          //if (props.setOpen) props.setOpen(false);
          //props.refreshTables();
        });
    };
  }

  function handleEliminar() {
    let token = sessionStorage.getItem("Token");
    let urlDeleteDoc = `${process.env.REACT_APP_URL}api/documentos/${documento}`;
    console.log("URL DELETE");
    axios
      .delete(urlDeleteDoc, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {},
        // receive two parameter endpoint url ,form data
      }).then((response) => {
        // Respuesta del servidor
        console.log("res Delete", response);
        if (props.setRefresh) props.setRefresh(props.idFolderActual);
        //if (props.setOpen) props.setOpen(false);
        props.refreshTables();
      })
      .catch((error) => {
        // Error 😨
        console.log(error);
      });
    setDocumento("");
  }

  function handleModificar() {
    let tokenCC = sessionStorage.getItem("Token");
    let urlmodificar = "";
    let desc = "";
    if (descripcion !== "") {
      desc = descripcion;
    }
    urlmodificar = "?description=" + desc;

    if (inicio !== "" && inicio !== undefined && inicio !== "Invalid date") {
      urlmodificar =
        urlmodificar + "&meta[vigencia_start]=" + inicio + "00:00:00";
    }

    if (inicio === "" || inicio === "Invalid date") {
      urlmodificar = urlmodificar + "&meta[vigencia_start]=";
      urlmodificar = urlmodificar + "&meta[vigencia_end]=";
    } else {
      if (termino !== "" && termino !== undefined && termino !== "Invalid date") {
        urlmodificar =
          urlmodificar + "&meta[vigencia_end]=" + termino + "23:59:59";
      }
      if (termino === "" || termino === "Invalid date") {
        urlmodificar = urlmodificar + "&meta[vigencia_end]=";
      }
    }

    if (diasAlert > 0) {
      urlmodificar = urlmodificar + "&meta[dias_alerta]=" + diasAlert;
    } else {
      urlmodificar = urlmodificar + "&meta[dias_alerta]=";
    }
    // if (idClases) {
    //   urlmodificar = urlmodificar + "fc_clase_id" + idClases
    // }
    const dataform = new FormData();


    urlmodificar = urlmodificar + "&meta[info]=" + JSON.stringify(data);
    if (idClases) {
      urlmodificar = urlmodificar + "&meta[info]=" + JSON.stringify(data) + "&fc_clase_id=" + idClases;

    }

    const urljs = `${process.env.REACT_APP_URL}api/documentos/${documento}${urlmodificar}`
    var config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${tokenCC}`,

      },
    };
    axios.put(urljs, dataform, config)
      .then(function (response) {
        console.log("Actualizado:", response.data);
        if (props.setRefresh) props.setRefresh(props.idFolderActual);
        if (props.setOpen) props.setOpen(false);
        props.refreshTables();
        setDocumento("");
      })
      .catch(function (error) {
        //showNotificacion(error);
        alert(error);
        console.log(error);
      });

  }

  React.useEffect(() => {
    if (props.file) {
      getBase64(props.file).then((data) => {
        setBase64(data);
        saveDocumento(data);
      });
    }
    getCustomMeta();
    handleclases();
  }, []);
  function handleclases() {
    let token = sessionStorage.getItem("Token");
    axios
      .get(`${process.env.REACT_APP_URL}api/clases`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("RESPCORPO", res.data);
        var RESPCORPO = res.data;
        var collection = []
        RESPCORPO.map(function (data) {
          var clasesItem = {
            codigo: data._id,
            name: data.name,
          }
          collection.push(clasesItem)
        })
        console.log("COLLECTION", collection)
        setSelectClases(collection)
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <AppBar position="static" className={classes.appbar}>
              ALMACENAR EN BÓVEDA ELECTRÓNICA
            </AppBar>
          </Grid>
          <Grid item sm={6}>
            <Paper className={classes.paper}>
              {base64 ? <PDFView base64={base64} scale={0.7} /> : "NO hay PDF"}
            </Paper>
          </Grid>
          <Grid item sm={6}>
            <Paper className={classes.paper}>
              <CardHeader color="info">INFORMACIÓN GENERAL</CardHeader>
              {/*METADATA PREDETERMINADA*/}
              <Container maxWidth="xs">
                <Box width={1} bgcolor="grey.300" p={1} my={0.5}>
                  <Paper elevation={3} style={{ padding: 20 }}>
                    <Grid container>
                      <Grid item xs={12} sm={6}>
                        Descripción:
                      </Grid>
                     
                      <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  multiline
                
                  maxRows={4}
                  inputProps={{
                    maxlength: 360,
                  }}
                  placeholder=""
                  helperText={descripcion && `${descripcion.length}/360`}
                  value={descripcion}
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    setDescripcion(e.target.value);
                  }}
                />
             

                      </Grid>

                      <Grid item xs={12} sm={6}>
                        Inicio vigencia:
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="dateDesde"
                          type="date"
                          onChange={(e) => {
                            console.log("inicio", e.target.value); // Works
                            setInicio(e.target.value);
                            setDesde(e.target.value);

                            if (e.target.value > hasta) {
                              setAlerta(
                                "La fecha de término debe ser superior a la fecha de inicio"
                              );
                            } else {
                              setAlerta("");
                            }
                            var date = new Date(e.target.value);
                            var f = new Date();

                            var hoy = new Date("Y-m-d");
                            if (date.getFullYear() > 2300) {
                              showNotificacion(
                                "La fecha de inicio es inválida"
                              );
                            }
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        Término vigencia:
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="dateDesde"
                          type="date"
                          onChange={(e) => {
                            console.log("termino", e.target.value); // Works
                            setTermino(e.target.value);
                            setHasta(e.target.value);

                            if (e.target.value < desde) {
                              //alert("La fecha de término debe ser superior a la fecha de inicio")
                              setAlerta(
                                "La fecha de término debe ser superior a la fecha de inicio"
                              );
                            } else {
                              setAlerta("");
                            }
                            var date = new Date(e.target.value);
                            if (date.getFullYear() > 2300) {
                              showNotificacion(
                                "La fecha de término es inválida"
                              );
                            }
                          }}
                          //value={desde}
                          //className={classes.textField}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      {alerta ? (
                        <Grid item xs={12} sm={12}>
                          <font size="2" color="red">
                            <b>{alerta}</b>
                          </font>
                        </Grid>
                      ) : null}
                      <Grid item xs={12} sm={12}>
                        <font size="2" color="darkblue">
                          <b>Estos datos son opcionales</b>
                        </font>
                      </Grid>

                      {hasta ? (
                        <React.Fragment>
                          <Grid item xs={12} sm={2}></Grid>
                          <Grid
                            item
                            xs={12}
                            sm={7}
                            className={classes.textodiaslabel}
                          >
                            <Checkbox
                              checked={checked}
                              onChange={handleChange}
                              color="default"
                            //inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                            <font size="2" color="green">
                              Alerta de vencimiento:
                            </font>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={2}
                            className={classes.textodias}
                          >
                            <TextField
                              //variant="outlined"
                              id="standard-number"
                              type="number"
                              value={diasAlerta}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                min: 2,
                                max: 90,
                                style: { fontSize: 15, color: "green" },
                              }}
                              style={{ width: "80%" }}
                              onChange={(e) => {
                                console.log("días", e.target.value); // Works
                                setDiasAlerta(e.target.value);
                                setDiasAlert(e.target.value);
                                if (e.target.value < 2 || e.target.value > 90) {
                                  showNotificacion(
                                    "La alerta debe ser mínimo 2 y máximo 90 días"
                                  );
                                  setDiasAlerta(30);
                                  setDiasAlert(30);
                                }
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={1}
                            className={classes.textodias}
                          >
                            <font size="2" color="green">
                              días
                            </font>
                          </Grid>
                        </React.Fragment>
                      ) : null}
                    </Grid>
                  </Paper>
                </Box>
              </Container>
              {/*FIN METADATA*/}
              {/*METADATOS CUSTOM*/}
              <br />
              {selectCustomMeta ? (
                <>
                  <CardHeader color="info">DATOS ADICIONALES</CardHeader>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="remitente">
                      Selecciones el catálogo de datos
                    </InputLabel>
                    <Select
                      labelId="custommeta"
                      id="custommetaid"
                      //value={customMeta._id}
                      onChange={handlechangeCustom}
                    >
                      <MenuItem value="Seleccione los metadatos" disabled>
                        Selecciona metadatos
                      </MenuItem>
                      {selectCustomMeta.map(({ _id, name }, index) => (
                        <MenuItem key={index} value={_id}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {customMeta.length > 0 ? Textos() : null}
                </>
              ) : null}
              {selectClases.length > 1 ? (
                <FormControl className={classes.formControl}  >
                  <InputLabel id="clase">Clases de documento</InputLabel>

                  <Select
                    labelId="clase"
                    id="clase"
                    value={tipoClases.codigo}
                    onChange={handlechangeClases}
                  >
                    <MenuItem value="Selecciona la clase de documento " disabled>
                      Selecciona  la clase de documento
                    </MenuItem>
                    {selectClases.map(({ codigo, name }, index) => (
                      <MenuItem key={index} value={codigo}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : ""}
              {/*FIN METADATOS CUSTOM*/}
              <hr />
              {documento ? (
                <Button
                  variant="contained"
                  //color="primary"
                  className={classes.botongreen}
                  component="span"
                  onClick={() => {
                    //handleGuardar();
                    handleModificar();
                  }}
                >
                  GUARDAR
                </Button>
              ) : null}

              <Button
                variant="contained"
                //color="secondary"
                className={classes.botondanger}
                component="span"
                onClick={() => {
                  if (documento) {
                    handleEliminar();
                  }
                  props.setFile("");
                  props.setOpen(false);
                }}
              >
                CANCELAR
              </Button>
              <br />
              <br />
            </Paper>
          </Grid>
        </Grid>
      </div>
      <Snackbar
        place="tc"
        color="danger"
        icon={ErrorIcon}
        message={messageError}
        open={openAlert}
        closeNotification={() => setOpenAlert(false)}
        close
      />
    </>
  );
}
