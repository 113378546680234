import React, { useEffect, useState } from "react";
import clsx from "clsx";
import axios from "axios";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import FolderIcon from "@material-ui/icons/Folder";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DescriptionIcon from "@material-ui/icons/Description";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import ControlPanel from "components/controlPanel/ControlPanel";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import FileCopyIcon from "@material-ui/icons/FileCopyOutlined";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import MaterialTable from "material-table";
import localization from "components/localization/localization";
import styles2 from "assets/jss/material-dashboard-react/components/tasksStyle.js";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/AddCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import PostAddIcon from "@material-ui/icons/PostAdd";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import BackupIcon from "@material-ui/icons/Backup";

import moment from "moment";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import { Grid } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import GetAppIcon from "@material-ui/icons/GetApp";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import PeopleIcon from "@material-ui/icons/People";
import Checkbox from "@material-ui/core/Checkbox";
import HomeIcon from "@material-ui/icons/Home";
import ClearIcon from "@material-ui/icons/Clear";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";

import DeleteIcon from "@material-ui/icons/Delete";
//constancia de conservación
import ClosedCaptionIcon from "@material-ui/icons/ClosedCaption";

// Importaciones para carga de compnenetes entrte pantallas
import Components from "containers/CheckoutNew";
import ContainerAutoSign from "containers/autoSign/autoSingContainer";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import ErrorIcon from "@material-ui/icons/Error";
import VistaArchivos from "containers/archivo/vistaArchivos";
import Adjuntos from "containers/Adjuntos/Adjuntos"; //LEMP ADJUNTOS

import EditIcon from "@material-ui/icons/Edit";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";

import { green } from "@material-ui/core/colors";
import { grayColor } from "assets/jss/material-dashboard-react.js";
import { DialogComp } from "components/Dialog/DialogComp";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import { CircularGridLines } from "react-vis";
import Almacenar from "containers/Boveda/Almacenar";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import InfoDocu from "components/ActionsDocument/infoDocu";
import Snackbar from "components/Snackbar/Snackbar";
import { whiteColor } from "assets/jss/material-dashboard-react";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const drawerWidth = 280;

const useStylesprogrees = makeStyles((theme) => ({
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonProgress2: {
    color: green[500],
    alignItems: "center",
    top: "50%",
    left: "50%",
    marginTop: 12,
    marginLeft: 12,
  },

  root: {
    height: "600px",
    background:
      "  linear-gradient(to right top, #eee404, #f1e405, #f3e407, #f6e308, #f8e30a)",
    borderRadius: "3px",

    padding: "15px",
    marginTop: "-20px",
    marginRight: "15px",
    backgroundColor: grayColor[0],
    float: "left",
  },

  iconGrayColor: {
    backgroundColor: grayColor[0],
    borderRadius: "3px",

    padding: "15px",
    marginTop: "-20px",
    marginRight: "15px",
    float: "left",
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  botoninfo: {
    backgroundColor: "#00acc1",
    color: "white",
  },

  botondanger: {
    backgroundColor: "red",
    color: "white",
  },

  botongreen: {
    backgroundColor: "green",
    color: "white",
  },
}));

const useStyles = makeStyles((theme) => ({
  inline: {
    fontSize: "12px",
    color: "grey",
  },
  iconSize: {
    fontSize: "15px",
    color: "#9c27b0",
    // padding: "5px",
  },
  iconSize2: {
    fontSize: "17px",
    color: "#9c27b0",
    // padding: "5px",
  },
  root: {
    display: "flex",
  },
  speedDial: {
    position: "absolute",
    bottom: theme.spacing(7),

    right: theme.spacing(2),
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 80,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 20,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(12) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(19) + 1,
    },
  },
  toolbar: {
    display: "flex",

    justifyContent: "flex-end",
    padding: theme.spacing(0, 0),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    height: "550px",
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));
const useStylestable = makeStyles(styles2);

export default function MiniDrawer({
  refreshTables,
  handleCloseArchivo,
  setNewAssign,
  // openNewAssignProcess,
  setOpenForm,
  setIdStatus,
  dataCarpetas,
  setidDocumento,
  setabState,
  setIdprocces,
  sendFolder2,
  setidDocuDetenido,
  succesCarpetas,
  // handleInserFolder,
  cerrarCarpetas,
  newAssignProcess,
  arrayPatch,
  setArraypatch,

}) {
  const classes = useStyles();
  const classes2 = useStylestable();

  const classespro = useStylesprogrees();
  const theme = useTheme();
  const [dataFolder, setDAtaFolder] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openZip, setOpenZip] = useState(false);
  const [folderSelected, setFolderSelected] = useState("");

  // Modificar Metadata States
  const [progrees, setProgrees] = useState(false);

  const [valueDeleteMeta, setValueDeleteMeta] = useState({
    atributoDelete: "",
    openConfirmarDeleteMeta: false,
  });
  const { atributoDelete, openConfirmarDeleteMeta } = valueDeleteMeta;

  const [rowDataFirmante, setrowDataFirmante] = useState({});
  const [dataNewMeta, setDataNewMeta] = useState({
    openDialogNewMeta: false,
    nombreNewMeta: "",
    valorNewMeta: "",
  });

  const { nombreNewMeta, valorNewMeta, openDialogNewMeta } = dataNewMeta;

  const [infoMetadata, setInfoMetadata] = useState({});

  // Fin Metadata States

  const [ItemDataok, setItemDataOk] = useState([
    <Typography paragraph>
      <Backdrop className={classespro.backdrop} open={true}>
        <CircularProgress size={44} className={classespro.buttonProgress} />
      </Backdrop>
      Cargando carpetas, espere un momento por favor...
    </Typography>,
  ]);
  const [folder, setFolder] = useState("");

  // Estados para las funciones de botones para agregar firmantes
  // const [idprocces, setIdprocces] = useState("");

  //constancia de conservación
  const [openGenerarConstancia, setOpenGenerarConstancia] = useState(false);
  const [idDocumentoConstancia, setidDocumentoConstancia] = useState("");

  const [currentStep, setCurrentStep] = useState("");
  const [openDeleteFolder, setopenDeleteFolder] = useState(false);
  const [myInfoFolder, setInfoFolder] = useState("");
  const [sendFolder, setSendFolder] = useState(false);
  const [idDocumentoFolder, setidDocumentoFolder] = useState("");

  const [patch, setPatch] = useState("");

  const [pdfBase64, setPdfBase64] = useState([]);
  const [openPDF, setOpenPDF] = useState(false);
  const [scale, setScale] = useState(1.3);
  const [numPages, setNumPages] = useState(null);
  const [messageError, setMessageError] = useState("");

  const [pageNumber, setPageNumber] = useState(1);
  const [pdfHeigth, setpdfHeigth] = useState(575);
  const [pdfWidth, setpdfWidth] = useState(660);
  const [openCarpetas, setOpenCarpetas] = useState(false);
  const [open, setOpen] = useState(true);
  const [detallesDocu, setDetallesDocu] = useState({});
  const [openSpeed, setOpenSpeed] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [contratosID, setContratosID] = useState("");
  const [listaFirmantes, setListaFirmantes] = useState("");
  const [listaFirmantesCC, setListaFirmantesCC] = useState("");
  const [listaNotificados, setListaNotificados] = useState("");
  const [modalFirmante, setModalFirmante] = useState(false);
  const [modalFirmantes, setModalFirmantes] = useState(false);
  const [modalFirmantesCC, setModalFirmantesCC] = useState(false);
  const [enviar, setEnviar] = useState(true);
  const [tipoDoc, setTipoDoc] = useState("");
  const [openDialogInfo, setOpenDialogInfo] = useState(false);
  const [docuDataPerson, setDocuDataPerson] = useState([]);
  const [diasAlert, setDiasAlert] = useState(0);
  const [openDelete, setopenDelete] = useState(false);
  const [openDialogAdjuntos, setOpenDialogAdjuntos] = useState(false); //LEMP Adjuntos

  const [openFirmantesRechazados, setopenFirmantesRechazados] = useState(false);
  const [openAlert, setOpenAlert] = useState("");
  const [isExpanded, setExpanse] = useState(false);

  const [checked, setChecked] = useState(false);
  const [idFather, setidFather] = useState("");
  const [idDeleteCDO, setidDeleteCDO] = useState("");
  const [idDelete, setidDelete] = useState("");

  const [descripcion, setDescripcion] = useState("");
  const [desde, setDesde] = useState("");
  const [hasta, setHasta] = useState("");
  const [alerta, setAlerta] = useState("");
  const [diasAlerta, setDiasAlerta] = useState(0);
  const [openModificar, setOpenModificar] = useState(false);
  const [openAlmacenar, setOpenAlmacenar] = useState(false);
  const [archivoAlmacenar, setArchivoAlmacenar] = useState("");
  const [idFolderActual, setIdFolderActual] = useState("");
  const [idFolderstate, setIdFolderstate] = useState("");

  // const [progress, setProgrees] = useState(false);
  const [depthFolder, setDethFolder] = useState(0);
  const [dataCarpeta, setDataCarpeta] = useState([]);
  const [nameFolder, setNameFolder] = useState("");
  const [openEditCarpetas, setOpenEditCarpetas] = useState(false);
  const [succesmsjf, setjsjs] = useState(null)

  const PROXY_URL = "";
  let token = sessionStorage.getItem("Token");

  useEffect(() => {
    if (docuDataPerson && docuDataPerson.meta && docuDataPerson.meta.info) {
      if (typeof docuDataPerson.meta.info === "string") {
        setInfoMetadata(JSON.parse(docuDataPerson.meta.info));
      } else {
        setInfoMetadata(docuDataPerson.meta.info);
      }
    }
  }, [docuDataPerson]);

  //LEMP ADJUNTOS
  const handleinfoDocuAdjuntos = (detalle) => {
    handleOpenAdjuntos(detalle);
  };

  const handleOpenAdjuntos = (rowData) => {
    console.log(rowData)
    setDocuDataPerson([]);
    setDocuDataPerson(rowData);
    setOpenDialogAdjuntos(true);
  };
  const handleCloseAdjuntos = () => {
    console.log(docuDataPerson)
    setDocuDataPerson([]);
    handleActualizar(idFolderActual);
    setOpenDialogAdjuntos(false);
  };

  // Metadata Functions
  const handleChangeMetadatos = (atributo, event) => {
    event.preventDefault();
    setInfoMetadata({
      ...infoMetadata,
      [atributo]: event.target.value,
    });
  };
  const handleCancelarNuevoMeta = () => {
    setDataNewMeta({
      openDialogNewMeta: false,
      nombreNewMeta: "",
      valorNewMeta: "",
    });
  };

  const handleCancelarActualizacion = () => {
    setInfoMetadata({});
    setDocuDataPerson([]);
    setOpenModificar(false);
    setDescripcion("");
    setDesde("");
    setHasta("");
    setDiasAlerta(30);
  };

  const handleEliminarMetadato = () => {
    let myObject = {};
    let result = Object.keys(infoMetadata).filter(
      (item) => item != atributoDelete
    );
    result.map((item) => {
      myObject = {
        ...myObject,
        [item]: infoMetadata[item],
      };
    });
    setInfoMetadata(myObject);
    setValueDeleteMeta({
      atributoDelete: "",
      openConfirmarDeleteMeta: false,
    });
  };

  const handleConfirmaEliminarMeta = (atributo) => {
    setValueDeleteMeta({
      atributoDelete: atributo,
      openConfirmarDeleteMeta: true,
    });
  };

  const handleCancelarEliminarMeta = () => {
    setValueDeleteMeta({
      atributoDelete: "",
      openConfirmarDeleteMeta: false,
    });
  };

  const handleAgregarNuevoMetadato = () => {
    setInfoMetadata({
      ...infoMetadata,
      [nombreNewMeta]: valorNewMeta,
    });
    setDataNewMeta({
      openDialogNewMeta: false,
      nombreNewMeta: "",
      valorNewMeta: "",
    });
  };

  const handleChangeNewMetadata = (event) => {
    setDataNewMeta({
      ...dataNewMeta,
      [event.target.name]: event.target.value,
    });
  };
  const handleOpenNuevoMetadato = () => {
    // setNewDataMeta
    setDataNewMeta({
      ...dataNewMeta,
      openDialogNewMeta: true,
    });
  };
  // Fin metadata functions

  useEffect(() => {

    if (dataCarpetas) {
      setDAtaFolder(dataCarpetas);
      setItemDataOk([
        <Typography paragraph>Seleccione una Carpeta</Typography>,
      ]);

      console.log("dataCarpetas", dataCarpetas)

    }


    if (isMobile()) {
      setpdfHeigth(500);
      setpdfWidth(290);
    }
  }, [dataCarpetas]);

  const isMobile = () => {
    return (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/BlackBerry/i)
    );
  };


  const actions = [
    {
      icon: (
        <CreateNewFolderIcon
          onClick={() => {
            setOpenCarpetas(true);
          }}
        />
      ),
      name: "Crear Carpeta",
    },
    {
      icon: (
        <div>
          {patch ? (
            <>
              <input
                accept="pdf/*"
                //className={classes.input}
                style={{ display: "none" }}
                id="contained-button-file"
                multiple
                type="file"
                name="PDF"
                onChange={onChangeAlmacenar}
                onClick={(e) => (e.target.value = null)}
              />
              <label htmlFor="contained-button-file">
                <IconButton
                  variant="contained"
                  //color="primary"
                  component="span"
                //disabled={patch?false:true}
                >
                  <NoteAddIcon />
                </IconButton>
              </label>
            </>
          ) : (
            <label htmlFor="contained-button-file">
              <IconButton
                variant="contained"
                //color="primary"
                component="span"
                disabled={true}
              >
                <NoteAddIcon />
              </IconButton>
            </label>
          )}
        </div>
      ),
      name: "Cargar documento",
    },
  ];

  const handleOpen = () => {
    setOpenSpeed(true);
  };
  function ShowMeta(props) {
    let mainData;
    if (typeof props.json === "string") {
      mainData = JSON.parse(props.json);
    } else {
      mainData = props.json;
    }
    return (
      <>
        {Object.keys(mainData).map((key, i) => (
          <p key={i}>
            <b>{key.toUpperCase()}:</b> {mainData[key]}
          </p>
        ))}
      </>
    );
  }
  const showNotificacion = (message) => {
    setOpenAlert(true);
    setMessageError(message);
    setTimeout(function () {
      setOpenAlert(false);
    }, 6000);
  };
  const handleChangeBox = (event) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      if (diasAlerta === 0 || diasAlerta === null) {
        setDiasAlert(30);
        setDiasAlerta(30);
      } else {
        setDiasAlert(diasAlerta);
      }
    } else {
      setDiasAlert(0);
      setDiasAlerta(0);
    }
  };

  const handleCloseInfo = () => {
    console.log("handleCloseInfo");
    setOpenDialogInfo(false);
  };
  const handleChangeDataDocuPerson = () => {
    setOpenDialogInfo(false);
    if (docuDataPerson.description !== "") {
      setDescripcion(docuDataPerson.description);
    }
    if (
      docuDataPerson.meta &&
      docuDataPerson.meta.vigencia_start !== "" &&
      docuDataPerson.meta.vigencia_start !== undefined
    ) {
      setDesde(moment(docuDataPerson.meta.vigencia_start).format("YYYY-MM-DD"));
    }
    if (
      docuDataPerson.meta &&
      docuDataPerson.meta.vigencia_end !== "" &&
      docuDataPerson.meta.vigencia_end !== undefined
    ) {
      setHasta(moment(docuDataPerson.meta.vigencia_end).format("YYYY-MM-DD"));
    }
    if (
      docuDataPerson.meta &&
      docuDataPerson.meta.dias_alerta !== "" &&
      docuDataPerson.meta.dias_alerta !== undefined
    ) {
      setDiasAlerta(docuDataPerson.meta.dias_alerta);
    }
    setOpenModificar(true);
  };

  function bucarFirmantesCDO(contratoCDO_id) {
    const URL =
      "https://api.certificaciondigital.online/api/contrato/" +
      contratoCDO_id +
      "/firmante/lista";
    var config = {
      method: "get",
      url: PROXY_URL + URL,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Basic " + token,
      },
    };
    axios(config)
      .then(function (response) {
        console.log("Firmantes CDO:", response.data);
        setListaFirmantes(response.data);
        if (response.data.length === 0) {
          setEnviar(true);
        } else {
          setEnviar(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  function bucarNotificadosCDO(contratoCDO_id) {
    const URL =
      "https://api.certificaciondigital.online/api/contrato/" +
      contratoCDO_id +
      "/notificador/lista";
    var config = {
      method: "get",
      url: PROXY_URL + URL,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Basic " + token,
      },
    };
    axios(config)
      .then(function (response) {
        console.log("Notificados CDO:", response.data);
        setListaNotificados(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleDeleteFolder = () => {
    setProgrees(true);
    let token = sessionStorage.getItem("Token");
    console.log("DATAFOLDER", myInfoFolder);
    let urlDeleteDoc = `${process.env.REACT_APP_URL}api/categorias/${myInfoFolder._id}`;
    console.log("URL DELETE FOLDER");
    axios
      .delete(urlDeleteDoc, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {},
        // receive two parameter endpoint url ,form data
      })
      .then((response) => {
        // Respuesta del servidor
        //setProgrees(false);
        if (folderSelected.parent_id) {
          handleActualizar(myInfoFolder);
        } else {
          handleCatalogo();
          setPatch("");
        }
        //handleActualizar(myInfoFolder);
        //handleCatalogo();
        //setPatch("");
        //setInfoFolder("");
        setopenDeleteFolder(false);
        console.log("respuest Delete", response);
      })
      .catch((error) => {
        setInfoFolder("");
        setopenDeleteFolder(false);
        setProgrees(false);
        // Error 😨
        if (error.response) {
          console.log(error.response.data.message);
          alert(error.response.data.message);
        } else if (error.request) {
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  const crearCarpeta = () => {
    setProgrees(true);
    const URL = `${process.env.REACT_APP_URL}api/categorias`;
    const data = new FormData();
    let rutaFolder = "";
    if (patch.length > 1) {
      rutaFolder = `${patch}/${folder}`;
    } else {
      rutaFolder = folder;
    }
    data.append("folder", rutaFolder);
    let token = sessionStorage.getItem("Token");
    console.log("RUTAFOLDER", rutaFolder);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
        Accept: " application/json",
        type: "formData",
      },
    };

    axios
      .post(URL, data, config, {
        // receive two parameter endpoint url ,form data
      })
      .then((response) => {
        let isStopped = response.data.categoria.recently_created;
        console.log(response, "RESPONSE NEW FOLDER");
        console.log(isStopped, "RESPONSE isStopped");
        if (!isStopped) {
          showNotificacion(
            "No es posible crear dos carpetas con nombres idénticos en la misma ubicación"
          );
        }
        setFolder("");
        // Respuesta del servidor
        setOpenCarpetas(false);
        //handleCatalogo();

        console.log("respADD", response.data);
        //handleActualizar(response.data.categoria);
        if (response.data.categoria.parent_id) {
          handleActualizar(response.data.categoria);
        } else {
          handleCatalogo();
        }
      })
      .catch((error) => {
        // Error 😨
        console.log("respERROR", error);
      });
  };

  // funcion para actualizar la data despues agregar o borrar una carpeta
  const handleActualizar = (dataPather) => {
    console.log("datapapa", dataPather);
    let idFolder;
    if (dataPather.parent_id) {
      idFolder = dataPather.parent_id;
    } else {
      if (typeof dataPather === "string") {
        idFolder = dataPather;
      } else {
        idFolder = dataPather._id;
      }
    }

    setItemDataOk([]);
    let token = sessionStorage.getItem("Token");
    axios
      .get(`${process.env.REACT_APP_URL}api/categorias/${idFolder}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const okreturn = handleItemDocu(res.data);
        // setDAtaFolder(res.data);
        setItemDataOk(okreturn);
        setProgrees(false);
      })
      .catch((error) => {
        console.error(error);
        setProgrees(false);
      });
  };

  const handleInserFolder = (dataDocu) => {
    console.log("dataDocuFolder", dataDocu);
    setidDocumentoFolder(dataDocu.id);
    setSendFolder(true);
  };

  const handleClose = () => {
    setOpenSpeed(false);
  };
  const handleCatalogo = () => {
    setArraypatch([]);
    // setProgrees(true);
    let token = sessionStorage.getItem("Token");
    axios
      .get(`${process.env.REACT_APP_URL}api/categorias`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setProgrees(true);
        setDAtaFolder(res.data);
        setItemDataOk([
          <Typography paragraph>Seleccione una Carpeta</Typography>,
        ]);
        setProgrees(false);
      })
      .catch((error) => {
        setProgrees(false);
        console.error(error);
      });
    // setProgrees(false);
  };

  //FUNCION PARA TRAER EL DOCUMENTO FIRMADO
  const handlePDFB64 = (idDoc) => {
    console.log("IDPDFD", idDoc);
    let token = sessionStorage.getItem("Token");

    axios
      .get(`${process.env.REACT_APP_URL}api/documentos/${idDoc}/download`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setPdfBase64(res.data);
        setOpenPDF(true);
      })
      .catch((error) => {
        console.error(error);
        alert(error.data.message);
      });
  };

  function handleModificar() {
    console.log("handleModificar NOT ARROW FUNCTION");

    let tokenCC = sessionStorage.getItem("Token");
    //let tokenCC = `${process.env.REACT_APP_TOKEN}`;
    let urlmodificar = "";
    let desc = "";
    console.log("desde:", desde);
    console.log("hasta:", hasta);
    console.log("dias:", diasAlerta);

    if (descripcion != "" && descripcion != undefined) {
      desc = descripcion;
    }
    urlmodificar = "?description=" + desc;

    if (desde !== "" && desde !== undefined && desde !== "Invalid date") {
      urlmodificar =
        urlmodificar + "&meta[vigencia_start]=" + desde + "00:00:00";
    }
    if (desde === "" || desde === "Invalid date") {
      urlmodificar = urlmodificar + "&meta[vigencia_start]=";
      urlmodificar = urlmodificar + "&meta[vigencia_end]=";
    } else {
      if (hasta !== "" && hasta !== undefined && hasta !== "Invalid date") {
        urlmodificar =
          urlmodificar + "&meta[vigencia_end]=" + hasta + "23:59:59";
      }
      if (hasta === "" || hasta === "Invalid date") {
        urlmodificar = urlmodificar + "&meta[vigencia_end]=";
      }
    }

    if (checked) {
      urlmodificar = urlmodificar + "&meta[dias_alerta]=" + diasAlerta;
    } else {
      urlmodificar = urlmodificar + "&meta[dias_alerta]=";
    }

    urlmodificar = urlmodificar + "&meta[info]=" + JSON.stringify(infoMetadata);

    var config = {
      method: "PUT",
      url: `${process.env.REACT_APP_URL}api/documentos/${docuDataPerson._id}${urlmodificar}`,
      //url : `${url}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + tokenCC,
      },
    };
    setProgrees(true);

    axios(config)
      .then(function (response) {
        setProgrees(false);
        console.log("ContratoCC Actualizado:", response.data);
      })
      .catch(function (error) {
        setProgrees(true);
        showNotificacion(error);
        console.log(error);
      });

    //reload
    setInfoMetadata({});
    setDocuDataPerson([]);
    setOpenModificar(false);
    setDescripcion("");
    setDesde("");
    setHasta("");
    setDiasAlerta(30);
  }
  const handleDetalles = async (idDoc, accion) => {
    const token = sessionStorage.getItem("Token");
    console.log("ACCION", accion);
    axios
      .get(`${process.env.REACT_APP_URL}api/documentos/${idDoc}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (accion == "descarga") {
          descargarDocu(res.data);
        } else if (accion == "participantes") {
          participantesDocu(res.data);
        } else if (accion == "info") {
          handleinfoDocu(res.data);
        } else if (accion == "adjuntos") {
          handleinfoDocuAdjuntos(res.data);
        }
      })
      .catch((error) => {
        //if (error.response.data.message) {
        console.log(error);
        //}
      });
  };
  const handleinfoDocu = (detalle) => {
    handleOpenInfo(detalle);
  };

  const handleOpenInfo = (data) => {
    setOpenDialogInfo(true);
    console.log("datainfo", data);
    setDocuDataPerson(data);
  };
  const participantesDocu = (detalle) => {
    if (detalle.type.id === 3) {
      setContratosID({
        cc: detalle._id,
        cdo: detalle.cdo_contratos_id,
      });
      setListaFirmantesCC(detalle.firmantes);
      bucarFirmantesCDO(detalle.cdo_contratos_id);
      bucarNotificadosCDO(detalle.cdo_contratos_id);
      setModalFirmantes(true);
    } else {
      // !ANTERIOR SALVADOR handleOpenTodosFirmantes(detalle);
      setTipoDoc(detalle.type.id);
      setListaFirmantes(detalle.firmantes);
      setListaNotificados(detalle.notificados);
      setModalFirmantesCC(true);
    }
  };

  const descargarDocu = (detalle) => {
    console.log("entro a descarga", detalle);
    if (detalle.type.id === 5 || detalle.status.id === 5) {
      console.log("Descargar constanciaCDO");
      console.log(detalle.constancia);
      var obj;
      if (typeof detalle.constancia === "string") {
        obj = JSON.parse(detalle.constancia);
      } else {
        obj = detalle.constancia;
      }
      //var obj = JSON.parse(detalle.constancia);
      handleDescargarConstanciaCDO(obj.descripcion, obj.id);
      getTotalDownloadPdf(detalle);
    } else {
      getTotalDownloadPdf(detalle);
    }
  };

  const handleDescargarConstanciaCDO = (nombre, idConstancia) => {
    var nombre_archivo = nombre + ".zip";
    var URL =
      "https://api.certificaciondigital.online/api/constancia/solicitud/" +
      idConstancia +
      "/descargar";

    const username = "ce2af9cab947ed15288e058ecd5350f5";
    const password = "9c9dd0db9b4a23eddf64b96e28b1214b";
    const token = Buffer.from(`${username}:${password}`, "utf8").toString(
      "base64"
    );

    var config = {
      method: "get",
      url: PROXY_URL + URL,
      responseType: "blob", // important
      headers: {
        Authorization: "Basic " + token,
      },
    };
    axios(config).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", nombre_archivo);
      document.body.appendChild(link);
      link.click();
    });
  };

  const getTotalDownloadPdf = (data) => {
    console.log("DATA TYPE:", data.type.id);
    if (data.type.id === 3) {
      handleDescargarCDO(data.filename, data.status.id, data.cdo_contratos_id);
    } else {
      if (data.status.name === "firmado") {
        getZipDocument(data._id, data.filename);
      } else {
        //handleOpenPDF(data._id);
        handleDescargarPDF(data._id, data.filename);
      }
    }
  };

  //funcion para descargar el paquete de constancia del documentos

  const getZipDocument = (idDocu, fileName) => {
    const nameFormat = fileName.slice(0, -4);
    setOpenZip(true);

    let token = sessionStorage.getItem("Token");
    axios({
      url: `${process.env.REACT_APP_URL}api/documentos/${idDocu}/bundle/download`,
      method: "GET",
      responseType: "blob", // important
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${nameFormat}.zip`);
      document.body.appendChild(link);
      link.click();
      setOpenZip(false);
    });
  };
  const eliminarContratoCDO = (idcontrato) => {
    const URL =
      "https://api.certificaciondigital.online/api/contrato/" +
      idcontrato +
      "/delete";
    var config = {
      method: "get",
      url: PROXY_URL + URL,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Basic " + token,
      },
    };
    axios(config)
      .then(function (response) {
        console.log("Contrato CDO eliminado:", response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //constancia de conservación
  const handleGenerarConstancia = () => {
    setProgrees(true);
    setOpenGenerarConstancia(false);
    let token = sessionStorage.getItem("Token");
    let urlUpdateDoc = `${process.env.REACT_APP_URL}api/documentos/solicitarConstancia/${idDocumentoConstancia}`;

    axios
      /*
        .put(urlUpdateDoc, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: {},
          // receive two parameter endpoint url ,form data
        })
        */
      .put(
        `${process.env.REACT_APP_URL}api/documentos/solicitarConstancia/${idDocumentoConstancia}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        // Respuesta del servidor
        console.log("res Update", response);

        setidDocumentoConstancia("");
        //setOpenGenerarConstancia(false);
        setProgrees(false);
        handleActualizar(idFolderActual);
      })
      .catch((error) => {
        // Error 😨
        setidDocumentoConstancia("");
        //setOpenGenerarConstancia(false);
        setProgrees(false);
        alert("Error al crear Constancia");
      });
  };

  const handleDelete = () => {
    setProgrees(true);
    let token = sessionStorage.getItem("Token");

    let urlDeleteDoc = `${process.env.REACT_APP_URL}api/documentos/${idDelete}`;
    console.log("URL DELETE DOCUMENTO");
    axios
      .delete(urlDeleteDoc, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {},
        // receive two parameter endpoint url ,form data
      })
      .then((response) => {
        // Respuesta del servidor
        console.log("res Delete", response);
        //eliminar contrato CDO
        if (idDeleteCDO) {
          eliminarContratoCDO(idDeleteCDO);
        }
        setidDelete("");
        setopenDelete(false);
        setProgrees(false);
        handleActualizar(idFolderActual);
      })
      .catch((error) => {
        // Error 😨
        setopenDelete(false);
        setidDelete("");
        setProgrees(false);

        alert(error.data.message);
      });
    //eliminar contrato CDO
    if (idDeleteCDO) {
      eliminarContratoCDO(idDeleteCDO);
    }
  };

  const handleOpenDelete = (itemdocu) => {
    setopenDelete(true);
    setidDelete(itemdocu.id);
  };

  const openEditName = (dataFolder) => {
    console.log("datafolder", dataFolder);
    setFolderSelected(dataFolder);
    setNameFolder(dataFolder.name);
    setIdFolderstate(dataFolder._id);
    setOpenEditCarpetas(true);
  };

  const handleEditName = () => {
    setProgrees(true);
    let token = sessionStorage.getItem("Token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: " application/json",
      },
    };

    let urlEdit = `${process.env.REACT_APP_URL}api/categorias/${idFolderstate}?name=${nameFolder}`;
    axios
      .put(urlEdit, {}, config)
      .then((response) => {
        // Respuesta del servidor
        console.log("res Edit name", response);
        if (folderSelected.parent_id) {
          handleActualizar(idFolderActual);
        } else {
          handleCatalogo();
          setPatch("");
        }

        setOpenEditCarpetas(false);
        //setProgrees(false);
      })
      .catch((error) => {
        // Error 😨
        showNotificacion(
          "No es posible crear dos carpetas con nombres idénticos en la misma ubicación"
        );
        //setOpenEditCarpetas(false);
        //alert(error.data.message);
        setProgrees(false);
      });
  };
  const handleDescargarPDF = async (idDocu, nombre) => {
    const token = sessionStorage.getItem("Token");

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_URL}api/documentos/${idDocu}/download`,
      //responseType: "blob", // important
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config).then((response) => {
      const linkSource = `data:application/pdf;base64,${response.data}`;
      const downloadLink = document.createElement("a");
      const fileName = nombre;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      /*
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", nombre_archivo);
      document.body.appendChild(link);
      link.click();
      */
    });
    /*
    const token = sessionStorage.getItem("Token");
    axios
      .get(`${process.env.REACT_APP_URL}api/documentos/${idDocu}/download`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setbase64(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
      */
  };
  const handleDescargarCDO = (nombre, status, id) => {
    var nombre_archivo = nombre;
    var URL =
      "https://api.certificaciondigital.online/api/contrato/" +
      id +
      "/descargar-certificado";
    if (status === 3) {
      nombre_archivo = nombre + ".zip";
    } else {
      URL =
        "https://api.certificaciondigital.online/api/contrato/" +
        id +
        "/descargar";
    }

    var config = {
      method: "get",
      url: PROXY_URL + URL,
      responseType: "blob", // important
      headers: {
        Authorization: "Basic " + token,
      },
    };
    axios(config).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", nombre_archivo);
      document.body.appendChild(link);
      link.click();
    });
  };
  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  // FUNCION PARA VOLVER A LA CARPETA ANTERIOR
  const handleVolver = () => {
    if (idFather) {
      HandleChildren(idFather);
    } else {
      console.log("NO HAY PADRE");
    }
  };

  const handleHome = () => {
    setPatch("");
    setArraypatch([]);
    setItemDataOk([<Typography paragraph>Seleccione una Carpeta</Typography>]);
  };
  // FUNCION  PARA TRAER EL CONTENIDO DE UNA CARPETA
  const HandleChildren = (data) => {
    console.log(data, "HandleChildren");
    setProgrees(true);
    let idFolder;
    if (Object.keys(data).length == 8) {
      idFolder = data._id;
    } else {
      idFolder = data;
    }

    setItemDataOk([]);
    let token = sessionStorage.getItem("Token");
    axios
      .get(`${process.env.REACT_APP_URL}api/categorias/${idFolder}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setProgrees(false);
        console.log("CATALOGO2", res.data);
        setidFather(res.data[0].parent_id);
        setIdFolderActual(res.data[0]._id);
        const patch2 = res.data[0].path;
        const respo = patch2.replace(/\//g, ", ");
        let arr = respo.split(",");

        console.log("patchearreglos", arr);
        setArraypatch(arr);
        setPatch(res.data[0].path);

        const okreturn = handleItemDocu(res.data);

        setItemDataOk(okreturn);
      })
      .catch((error) => {
        setProgrees(false);
        console.error(error);
      });
  };

  // Funciones para agregar firmantes y editarlos
  const handleOpenForm = async (data) => {
    const documentStatusId = data.status.id;
    let tipoDocumento = data.type.id;
    if (tipoDocumento == 7) {
      showNotificacion("No se pueden editar firmantes en autofirma");
    } else {
      setIdprocces(tipoDocumento);
      if (data.stopped_at) {
        cerrarCarpetas(false);
        setidDocuDetenido(data.id);
        setabState(1);
        await setOpenForm(3);
      } else {
        cerrarCarpetas(false);
        setIdStatus(documentStatusId);
        setidDocumento(data.id);
        setabState(1);
        setOpenForm(1);
      }
    }
  };

  //funcion para iterar las carpetas y documentos dentro de una carpeta
  const handleItemDocu = (data) => {
    let collectionData = [];
    var itemdocs = null;
    var carpetas = null;
    console.log("DATAITEM", data[0].depth + 1);
    setDethFolder(data[0].depth + 1);

    if (data[0].documentos.length == 0 && data.length == 1) {
      return [<Typography paragraph>La carpeta esta vacia</Typography>];
    }
    if (data[0].documentos) {
      itemdocs = data[0].documentos.map((itemdocu) => {
        console.log(itemdocu, "handleItemDocu");
        setDataCarpeta(itemdocs);
        return (
          <div key={itemdocu.filename}>
            <ListItem button key={itemdocu.filename} style={{ padding: 0 }}>
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText
                style={{ marginLeft: "-26px", color: "black" }}
                primary={itemdocu.filename}
                secondary={
                  <React.Fragment>
                    {/* <Typography
                      component="span"
                      variant="body"
                      className={classes.inline}
                      color="textPrimary"
                    >
                      Fecha: {moment(itemdocu.created_at).format("DD/MM/YYYY ")}
                    </Typography> */}

                    <font className={classes.inline}>
                      Fecha: {moment(itemdocu.created_at).format("DD/MM/YYYY ")}
                    </font>
                    <Typography
                      component="span"
                      variant="body"
                      className={classes.inline}
                      color="textPrimary"
                    >
                      {`— ID: ${itemdocu.id}  — Estatus: ${itemdocu.status.name}   — Tipo: ${itemdocu.type.name} `}
                      {itemdocu.user && `— Emisor: ${itemdocu.user.name} `}
                    </Typography>
                  </React.Fragment>
                }
              />
              <div>
                <Tooltip id="tooltip-top" title="Mover" placement="top">
                  <IconButton
                    // size="small"
                    className={classes.iconSize}
                    onClick={() => handleInserFolder(itemdocu)}
                    aria-label="more"
                    edge="end"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                  >
                    <FolderIcon className={classes.iconSize} />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  id="tooltip-top"
                  title="Descargar Documento"
                  placement="top"
                >
                  <IconButton
                    className={classes.iconSize}
                    onClick={() => handleDetalles(itemdocu.id, "descarga")}
                    aria-label="more"
                    edge="end"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                  >
                    <GetAppIcon className={classes.iconSize} />
                  </IconButton>
                </Tooltip>
                {itemdocu.type.id == 11 && itemdocu.status.id == 1 ? (
                  <>
                    <Tooltip
                      id="tooltip-top"
                      title="Generar constancia de conservación"
                      placement="top"
                    >
                      <IconButton
                        className={classes.iconSize}
                        onClick={() => {
                          setOpenGenerarConstancia(true);
                          setidDocumentoConstancia(itemdocu.id);
                        }}
                        aria-label="more"
                        edge="end"
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                      >
                        <ClosedCaptionIcon className={classes.iconSize} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      id="tooltip-top"
                      title="Configurar proceso de firma"
                      placement="top"
                    >
                      <IconButton
                        className={classes.iconSize}
                        onClick={() =>
                          setNewAssign({
                            ...newAssignProcess,
                            openNewAssignProcess: true,
                            rowDataAssign: itemdocu,
                          })
                        }
                        aria-label="more"
                        edge="end"
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                      >
                        <PostAddIcon className={classes.iconSize} />
                      </IconButton>
                    </Tooltip>
                  </>
                ) : itemdocu.type.id != 7 &&
                  itemdocu.status.id != 2 &&
                  itemdocu.status.id != 3 &&
                  itemdocu.status.id != 5 ? (
                  <Tooltip
                    id="tooltip-top"
                    title={
                      itemdocu.stopped_at
                        ? "Editar Firmantes"
                        : "Agregar firmantes"
                    }
                    placement="top"
                  >
                    <IconButton
                      className={classes.iconSize}
                      onClick={() => handleOpenForm(itemdocu)}
                      aria-label="more"
                      edge="end"
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                    >
                      <GroupAddIcon className={classes.iconSize} />
                    </IconButton>
                  </Tooltip>
                ) : null}
                <Tooltip
                  id="tooltip-top"
                  title=" Ver documento"
                  placement="top"
                >
                  <IconButton
                    className={classes.iconSize}
                    onClick={() => handlePDFB64(itemdocu.id)}
                    aria-label="more"
                    edge="end"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                  >
                    <VisibilityIcon className={classes.iconSize} />
                  </IconButton>
                </Tooltip>
                {itemdocu.type.id != 11 && (
                  <Tooltip
                    id="tooltip-top"
                    title="Participantes"
                    placement="top"
                  >
                    <IconButton
                      className={classes.iconSize}
                      onClick={() =>
                        handleDetalles(itemdocu.id, "participantes")
                      }
                      aria-label="more"
                      edge="end"
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                    >
                      <PeopleIcon className={classes.iconSize} />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip
                  id="tooltip-top"
                  title="Información del documento"
                  placement="top"
                >
                  <IconButton
                    className={classes.iconSize}
                    onClick={() => handleDetalles(itemdocu.id, "info")}
                    aria-label="more"
                    edge="end"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                  >
                    <InfoIcon className={classes.iconSize} />
                  </IconButton>
                </Tooltip>

                <Tooltip
                  id="tooltip-top"
                  title="Adjuntos"
                  placement="top"
                >
                  <IconButton
                    className={classes.iconSize}
                    //onClick={() => handleinfoDocuAdjuntos(itemdocu.id, "info")}
                    onClick={() => handleDetalles(itemdocu.id, "adjuntos")}
                    aria-label="more"
                    edge="end"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                  >
                    {
                      itemdocu.adjuntos.length > 0 ?
                        <AttachFileIcon className={classes.iconSize} />
                        :
                        <BackupIcon className={classes.iconSize} />
                    }

                  </IconButton>
                </Tooltip>


                {itemdocu.status.id == 1 || itemdocu.status.id == 6 ? (
                  <Tooltip
                    id="tooltip-top"
                    title="Eliminar documento"
                    placement="top"
                  >
                    <IconButton
                      className={classes.iconSize}
                      onClick={() => handleOpenDelete(itemdocu)}
                      aria-label="more"
                      edge="end"
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                    >
                      <DeleteIcon className={classes.iconSize} />
                    </IconButton>
                  </Tooltip>
                ) : null}
              </div>
            </ListItem>
          </div>
        );
      });
    }
    collectionData.push(itemdocs);
    if (data.length > 1) {
      var dataFolder = data;
      dataFolder.splice(0, 1);

      carpetas = dataFolder.map((ListItemData, index) => {
        // console.log("dDPETTHT", depthFolder);
        if (ListItemData.depth == data[0].depth) {
          return (
            <ListItem button key={ListItemData.name} style={{ padding: 1 }}>
              <ListItemIcon onClick={() => HandleChildren(ListItemData)}>
                <FolderIcon />
              </ListItemIcon>
              <ListItemText
                style={{ marginLeft: "-26px" }}
                onClick={() => HandleChildren(ListItemData)}
                primary={ListItemData.name}
              />
              {ListItemData.child_id == null &&
                !ListItemData.documentos.length ? (
                <Tooltip
                  title="Borrar carpeta vacía"
                  classes={{
                    tooltip: classes2.tooltip,
                  }}
                  placement="bottom"
                >
                  <IconButton edge="end" aria-label="delete">
                    <DeleteIcon
                      className={classes.iconSize2}
                      onClick={() => {
                        setopenDeleteFolder(true);
                        setInfoFolder(ListItemData);
                        setFolderSelected(ListItemData);
                      }}
                    />
                  </IconButton>
                </Tooltip>
              ) : (
                ""
              )}
              <Tooltip title="Editar nombre" placement="bottom">
                <IconButton edge="end" aria-label="Edit">
                  <EditIcon
                    className={classes.iconSize2}
                    onClick={() => openEditName(ListItemData)}
                  />
                </IconButton>
              </Tooltip>
            </ListItem>
          );
        }
        return null;
      });
      collectionData.push(carpetas);
    }
    return collectionData;
  };
  const handleclosepdf = () => {
    setOpenPDF(false);
  };
  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function onChangeAlmacenar() {
    setArchivoAlmacenar(document.querySelector('input[type="file"]').files[0]);

    setOpenAlmacenar(true);
  }

  const recortarName = (data) => {
    if (data.length < 23) {
      // se chequea el regex de que el string no tenga espacio
      return data;
    } else {
      return ` ${data.substr(-35, 14) + "..."}`;
    }
  };

  return (
    <div>
      <div className={classes.root}>
        <AppBar
          // position="absolute"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, {
                  [classes.hide]: open,
                })}
              >
                <MenuIcon />
              </IconButton>
              {patch !== "" ? (
                <Tooltip
                  id="tooltip-top"
                  title="Atras"
                  placement="bottom"
                  classes={{
                    tooltip: classes2.tooltip,
                  }}
                >
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleVolver}
                    edge="start"
                  >
                    <ArrowBackIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                ""
              )}

              <Tooltip
                id="toolteip"
                title="Mis carpetas"
                placement="bottom"
                classes={{
                  tooltip: classes2.tooltip,
                }}
              >
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleHome}
                  edge="start"
                >
                  <HomeIcon />
                </IconButton>
              </Tooltip>
              <Tooltip id="toolteippatch" title={patch} placement="bottom">
                <Breadcrumbs

                  style={{ color: "#ffffff" }}
                  maxItems={6}
                  itemsAfterCollapse={2}
                  itemsBeforeCollapse={2}
                  aria-label="breadcrumb"
                >
                  {arrayPatch.map((value, index) => {
                    return (
                      <Typography style={{ fontSize: "15px", color: "#ffffff", cursor: "auto" }}>    {value}</Typography>


                    );
                  })}
                </Breadcrumbs>
              </Tooltip>

              <Tooltip
                id="tooltip"
                title="Cerrar"
                placement="bottom"
                classes={{
                  tooltip: classes2.tooltip,
                }}
              >
                <ClearIcon
                  onClick={() => {
                    handleCloseArchivo();
                    refreshTables();
                    setOpenForm(0);
                    setInfoFolder("");
                  }}
                  style={{ position: "absolute", right: "10px" }}
                />
              </Tooltip>
            </Grid>
          </Toolbar>
        </AppBar>
        <Drawer
          PaperProps={{
            style: {
              position: "absolute",
            },
          }}
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </div>
          <Divider />
          <List>
            {dataFolder.map((ListItemData, index) => {
              if (ListItemData.depth == 0) {
                return (
                  <ListItem
                    button
                    key={index + ListItemData.name}
                    onClick={() => HandleChildren(ListItemData)}
                    style={{ marginTop: "-10px" }}
                  >
                    <ListItemIcon>
                      <FolderIcon />
                    </ListItemIcon>
                    <Tooltip
                      id="tooltip"
                      title={ListItemData.name}
                      placement="bottom"
                    >
                      <ListItemText
                        style={{ marginLeft: "-26px" }}
                        primary={recortarName(ListItemData.name)}
                      />
                    </Tooltip>
                    {ListItemData.child_id == null &&
                      !ListItemData.documentos.length ? (
                      <Tooltip title="Borrar carpeta" placement="bottom">
                        <IconButton edge="end" aria-label="delete">
                          <DeleteIcon
                            className={classes.iconSize2}
                            onClick={() => {
                              setopenDeleteFolder(true);
                              setInfoFolder(ListItemData);
                              setFolderSelected(ListItemData);
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      ""
                    )}
                    <Tooltip title="Editar nombre" placement="bottom">
                      <IconButton edge="end" aria-label="Edit">
                        <EditIcon
                          className={classes.iconSize2}
                          onClick={() => openEditName(ListItemData)}
                        />
                      </IconButton>
                    </Tooltip>
                  </ListItem>
                );
              } else {
                return null;
              }
            })}
          </List>
          <Divider />
        </Drawer>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {/*AQUI SE RENDERIZA EL CONTENIDO DE LAS CARPETAS */}
          <div>{ItemDataok}</div>
        </main>
        <SpeedDial
          ariaLabel="SpeedDial openIcon example"
          className={classes.speedDial}
          hidden={hidden}
          icon={<SpeedDialIcon openIcon={<EditIcon />} />}
          onClose={handleClose}
          onOpen={handleOpen}
          open={openSpeed}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={handleClose}
            />
          ))}
        </SpeedDial>

        <DialogComp
          open={openPDF}
          title="Tu documento"
          maxWidth={"md"}
          content={
            pdfBase64.length === 0 ? (
              <Backdrop className={classespro.backdrop} open={open}>
                <CircularProgress
                  size={44}
                  className={classespro.buttonProgress}
                />
              </Backdrop>
            ) : (
              <div align="center">
                <ControlPanel
                  scale={scale}
                  setScale={setScale}
                  numPages={numPages}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                  file={`data: application / pdf; base64, ${pdfBase64} `}
                />
                <Document
                  error="Error al intentar cargar el PDF"
                  loading="Cargando..."
                  file={`data: application / pdf; base64, ${pdfBase64} `}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  <Page
                    scale={scale}
                    height={pdfHeigth}
                    width={pdfWidth}
                    renderTextLayer={false}
                    pageNumber={pageNumber}
                  />
                </Document>

                <div className="text-center">
                  <p>
                    Página {pageNumber || (numPages ? 1 : "--")} de{" "}
                    {numPages || "--"}
                  </p>
                  <button
                    className="btn btn-dark"
                    type="button"
                    disabled={pageNumber <= 1}
                    onClick={previousPage}
                  >
                    Atrás
                  </button>{" "}
                  <button
                    className="btn btn-dark"
                    type="button"
                    disabled={pageNumber >= numPages}
                    onClick={nextPage}
                  >
                    Siguiente
                  </button>
                </div>
              </div>
            )
          }
          buttons={[
            {
              buttonEvent: handleclosepdf,
              buttonName: "Cerrar",
              buttonColor: "secondary",
            },
          ]}
        />
        {/* DIALOGO PARA AGREGAR CARPETAS */}
        <DialogComp
          open={openCarpetas}
          title={" Ingresa la ruta y/o nombre de la carpeta:"}
          maxWidth={"md"}
          content={
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Ruta de la carpeta"
                variant="outlined"
                fullWidth
                value={folder}
                onChange={(e) => {
                  setFolder(e.target.value);
                }}
              />
            </div>
          }
          buttons={[
            {
              buttonEvent: () => setOpenCarpetas(false),
              buttonName: "Cerrar",
              buttonColor: "secondary",
            },
            {
              buttonEvent: () => crearCarpeta(),
              buttonName: "Guardar",
              buttonColor: "primary",
            },
          ]}
        />
        {/* DIALOGO PARA EDITAR EL NOMBRE DE LAS CARPETAS */}
        <DialogComp
          open={openEditCarpetas}
          title={"Escribe el nuevo nombre de la carpeta:"}
          maxWidth={"md"}
          content={
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Nombre de carpeta"
                variant="outlined"
                fullWidth
                value={nameFolder}
                onChange={(e) => {
                  setNameFolder(e.target.value);
                }}
              />
            </div>
          }
          buttons={[
            {
              buttonEvent: () => setOpenEditCarpetas(false),
              buttonName: "Cerrar",
              buttonColor: "secondary",
            },
            {
              buttonEvent: () => handleEditName(),
              buttonName: "Guardar",
              buttonColor: "primary",
            },
          ]}
        />
        {/* Dialogo de espera de descarga de paquete de documentos firmados */}
        <DialogComp
          open={openZip}
          title="Descarga de documento"
          content=" La carpeta con los documentos firmados se descargaran al lugar habitual de Descargas: Esto puede tardar unos segundos..."
          grid={true}
        />
        <DialogComp
          open={modalFirmantesCC}
          fullScreen={false}
          fullWidth={true}
          maxWidth={"lg"}
          onClose={() => {
            setModalFirmantesCC(false);
          }}
          title="AGREGAR FIRMANTE"
          content={
            <div>
              {listaFirmantes ? (
                <MaterialTable
                  localization={localization}
                  options={{
                    headerStyle: {
                      color: "green",
                    },
                    search: false,
                    paging: false,
                    actionsColumnIndex: -1,
                    exportButton: false,
                    exportAllData: true,
                  }}
                  columns={[
                    {
                      title: "Nombres",
                      field: "name",
                      render: (row) => (
                        <span>{row["name"] + " " + row["last_name"]}</span>
                      ),
                    },

                    {
                      title: "Prueba de vida",
                      field: "speech_required",
                      render: (row) => (
                        <span>
                          {row["speech_required"] == true ? (
                            <Tooltip
                              id="tooltip-top"
                              title="Se solicito prueba de vida"
                              placement="top"
                              classes={{
                                tooltip: classes2.tooltip,
                              }}
                            >
                              <IconButton
                                aria-label="Edit"
                                className={classes2.tableActionButton}
                              >
                                <RecordVoiceOverIcon
                                  className={
                                    classes2.tableActionButtonIcon +
                                    " " +
                                    classes2.edit
                                  }
                                />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            "No se solicitó"
                          )}
                        </span>
                      ),
                    },
                    { title: "Compañía", field: "company" },
                    {
                      title: "Medio de Notificación",
                      field: "notification_types_id",
                      render: (row) => (
                        <span>
                          {row["notification_types_id"] === 1
                            ? row["email"]
                            : row["phone_country_code"] + row["phone"]}
                        </span>
                      ),
                    },
                    {
                      title: "Estatus del Proceso",
                      field: "date_signed",
                      render: (row) => (
                        <span>
                          {row["refused_comment"] &&
                            row["refused_comment"] !== ""
                            ? "Rechazó"
                            : row["role"] === "Notificado"
                              ? "Notificado"
                              : row["date_signed"]
                                ? "Firmó"
                                : row["firmas"] && row["firmas"].length > 0
                                  ? "Pendiente de confirmar código"
                                  : "Pendiente de Firma"}
                        </span>
                      ),
                    },
                    {
                      title: "Registrado",
                      field: "created_at",
                      render: (row) => (
                        <span>
                          {row["created_at"]
                            ? moment(row["created_at"]).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )
                            : null}
                        </span>
                      ),
                    },
                    {
                      title: "Enviado",
                      field: "date_notified",
                      render: (row) => (
                        <span>
                          {row["date_notified"]
                            ? moment(row["date_notified"]).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )
                            : null}
                        </span>
                      ),
                    },
                    {
                      title: "Aviso de Privacidad Aceptado",
                      field: "date_accepted",
                      render: (row) => (
                        <span>
                          {row["date_accepted"]
                            ? moment(row["date_accepted"]).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )
                            : null}
                        </span>
                      ),
                    },
                    {
                      title: "Visto",
                      field: "date_accepted",
                      render: (row) => (
                        <span>
                          {row["date_accepted"]
                            ? moment(row["date_accepted"]).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )
                            : null}
                        </span>
                      ),
                    },
                    {
                      title: "Firmado",
                      field: "date_signed",
                      render: (row) => (
                        <span>
                          {row["date_signed"]
                            ? moment(row["date_signed"]).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )
                            : null}
                        </span>
                      ),
                    },
                    {
                      title: "Comentarios",
                      field: "refused_comment",
                    },
                  ]}
                  data={listaFirmantes}
                  title={
                    <Typography variant="h5" style={{ textTransform: "none" }}>
                      {tipoDoc === 6 ? "Notificados" : "Firmantes"}
                    </Typography>
                  }
                />
              ) : null}
              <hr />
              {listaNotificados ? (
                <MaterialTable
                  localization={localization}
                  options={{
                    headerStyle: {
                      color: "green",
                    },
                    search: false,
                    paging: false,
                    actionsColumnIndex: -1,
                    exportButton: false,
                    exportAllData: true,
                  }}
                  columns={[
                    { title: "Nombre", field: "name" },
                    { title: "Apellido", field: "last_name" },
                    { title: "Email", field: "email" },
                  ]}
                  data={listaNotificados}
                  title={
                    <Typography variant="h5" style={{ textTransform: "none" }}>
                      {tipoDoc === 6 ? "Observadores" : "Notificados"}
                    </Typography>
                  }
                />
              ) : null}
            </div>
          }
          buttons={[
            {
              buttonEvent: () => setModalFirmantesCC(false),
              buttonName: "Cerrar",
              buttonColor: "secondary",
            },
          ]}
        />
        {/* Dialogo de ver datos del documento info */}

        <DialogComp
          open={openDialogInfo}
          onClose={() => setopenFirmantesRechazados(false)}
          title="INFORMACIÓN DEL DOCUMENTO"
          content={<InfoDocu docuDataPerson={docuDataPerson} />}
          buttons={[
            {
              buttonEvent: handleCloseInfo,
              buttonName: "Cerrar",
              buttonColor: "secondary",
            },
            {
              buttonEvent: handleChangeDataDocuPerson,
              buttonName: "Modificar",
              buttonColor: "primary",
            },
          ]}
        />
        {/* Dialogo para modificar metadata  */}
        <DialogComp
          open={openModificar}
          title="Modificar datos"
          content={
            <>
              <Container maxWidth="xs">
                <Box width={1} bgcolor="grey.300" p={1} my={0.5}>
                  <Paper elevation={3} style={{ padding: 20 }}>
                    <Grid container>
                      <Grid item xs={6} sm={12}>
                        <p align="center">
                          <b>Información general del documento</b>
                        </p>
                        <hr />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        Descripción:
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          multiline
                          rows={2}
                          maxRows={4}
                          inputProps={{
                            maxlength: 360,
                          }}
                          placeholder=""
                          helperText={
                            descripcion && `${descripcion.length}/360`
                          }
                          value={descripcion}
                          //style={{ width: "100%" }}
                          onChange={(e) => {
                            console.log("descripcion", e.target.value); // Works
                            setDescripcion(e.target.value);
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        Inicio vigencia:
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="dateDesde"
                          type="date"
                          value={desde}
                          onChange={(e) => {
                            console.log("inicio", e.target.value); // Works
                            //setInicio(e.target.value);
                            setDesde(e.target.value);

                            if (e.target.value > hasta) {
                              setAlerta(
                                "La fecha de término debe ser superior a la fecha de inicio"
                              );
                            } else {
                              setAlerta("");
                            }
                            var date = new Date(e.target.value);
                            // var f = new Date();

                            // var hoy = new Date("Y-m-d");
                            if (date.getFullYear() > 2300) {
                              showNotificacion(
                                "La fecha de inicio es inválida"
                              );
                            }
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        Término vigencia:
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="dateHasta"
                          type="date"
                          value={hasta}
                          onChange={(e) => {
                            console.log("termino", e.target.value); // Works
                            setHasta(e.target.value);

                            if (e.target.value < desde) {
                              //alert("La fecha de término debe ser superior a la fecha de inicio")
                              setAlerta(
                                "La fecha de término debe ser superior a la fecha de inicio"
                              );
                            } else {
                              setAlerta("");
                            }
                            var date = new Date(e.target.value);
                            if (date.getFullYear() > 2300) {
                              showNotificacion(
                                "La fecha de término es inválida"
                              );
                            }
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      {alerta ? (
                        <Grid item xs={12} sm={12}>
                          <font size="2" color="red">
                            <b>{alerta}</b>
                          </font>
                        </Grid>
                      ) : null}

                      {hasta ? (
                        <React.Fragment>
                          <Grid item xs={12} sm={2}></Grid>
                          <Grid
                            item
                            xs={12}
                            sm={7}
                            className={classes.textodiaslabel}
                          >
                            <Checkbox
                              checked={checked}
                              onChange={handleChangeBox}
                              color="default"
                            //inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                            <font size="2" color="green">
                              Alerta de vencimiento:
                            </font>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={2}
                            className={classes.textodias}
                          >
                            <TextField
                              //variant="outlined"
                              id="standard-number"
                              type="number"
                              value={diasAlerta}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                min: 1,
                                max: 90,
                                style: {
                                  fontSize: 15,
                                  color: "green",
                                },
                              }}
                              style={{ width: "80%" }}
                              onChange={(e) => {
                                console.log("días", e.target.value); // Works
                                setDiasAlerta(e.target.value);
                                setDiasAlert(e.target.value);
                                if (e.target.value < 2 || e.target.value > 90) {
                                  showNotificacion(
                                    "La alerta de vencimiento debe comprender de mínimo 2 a máximo 90 días."
                                  );
                                  setDiasAlerta(30);
                                  setDiasAlert(30);
                                }
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={1}
                            className={classes.textodias}
                          >
                            <font size="2" color="green">
                              días
                            </font>
                          </Grid>
                        </React.Fragment>
                      ) : null}

                      {infoMetadata ? (
                        <>
                          <br />
                          {/* <br /> */}
                          <Grid item xs={6} sm={12}>
                            <p align="center">
                              <b>Campos adicionales</b>
                            </p>
                            <hr />
                          </Grid>
                          <Grid item xs={6} sm={12}>
                            <div align="center">
                              <Tooltip title="Agregar campo" placement="bottom">
                                <Fab
                                  size="small"
                                  color="primary"
                                  aria-label="add"
                                  onClick={handleOpenNuevoMetadato}
                                >
                                  <AddIcon />
                                </Fab>
                              </Tooltip>
                            </div>
                            <br />
                          </Grid>

                          <Grid item xs={6} sm={12}>
                            {Object.keys(infoMetadata).map(
                              (atributo, index) => (
                                <>
                                  <TextField
                                    // fullWidth
                                    size="small"
                                    margin="dense"
                                    key={index}
                                    label={atributo}
                                    value={infoMetadata[atributo]}
                                    style={{
                                      marginLeft: "8%",
                                    }}
                                    onChange={(e) => {
                                      handleChangeMetadatos(atributo, e);
                                    }}
                                    name="name"
                                    id={index.toString()}
                                    variant="outlined"
                                  />
                                  <Tooltip
                                    title="Eliminar campo adicional"
                                    placement="top"
                                  >
                                    <IconButton
                                      style={{
                                        marginTop: "4%",
                                        marginLeft: "8%",
                                      }}
                                      color="secondary"
                                      aria-label="Eliminar"
                                      size="small"
                                      onClick={() => {
                                        handleConfirmaEliminarMeta(atributo);
                                      }}
                                    >
                                      <HighlightOffIcon
                                        fontSize="medium"
                                        style={{
                                          color: "red",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              )
                            )}
                          </Grid>
                        </>
                      ) : null}
                    </Grid>
                  </Paper>
                </Box>
              </Container>
            </>
          }
          buttons={[
            {
              buttonEvent: handleCancelarActualizacion,
              buttonName: "Cancelar",
              buttonColor: "secondary",
            },
            {
              buttonEvent: handleModificar,
              buttonName: "Guardar",
              buttonColor: "primary",
            },
          ]}
        />

        {/* DIALOGO PARA ABRIR LA RUTAS DEL ARCHIVO */}
        <DialogComp
          open={sendFolder}
          fullWidth={true}
          maxWidth={"md"}
          title="Selecciona donde quieres ubicar el documento"
          content={
            <VistaArchivos
              setSendFolder={setSendFolder}
              idDocumento={idDocumentoFolder}
              handleActualizar={handleActualizar}
              idFolderActual={idFolderActual}
              refreshTables={refreshTables}
            />
          }
          buttons={[
            {
              buttonEvent: () => setSendFolder(false),
              buttonName: "Cerrar",
              buttonColor: "secondary",
            },
          ]}
        />
        {/* Dialogo para eliminar el documento  */}
        <DialogComp
          open={openDelete}
          onClose={() => setopenDelete(false)}
          title="Eliminar documento"
          content="¿Está seguro de eliminar este documento?"
          buttons={[
            {
              buttonEvent: () => setopenDelete(false),
              buttonName: "Cerrar",
              buttonColor: "secondary",
            },
            {
              buttonEvent: handleDelete,
              buttonName: "Eliminar",
              buttonColor: "primary",
            },
          ]}
        />

        {/* Dialogo para borrar carpetas */}
        <DialogComp
          open={openDeleteFolder}
          onClose={() => setopenDeleteFolder(false)}
          title="Eliminar Carpeta"
          content="¿Está seguro de eliminar esta carpeta?"
          buttons={[
            {
              buttonEvent: () => setopenDeleteFolder(false),
              buttonName: "Cerrar",
              buttonColor: "secondary",
            },
            {
              buttonEvent: handleDeleteFolder,
              buttonName: "Eliminar",
              buttonColor: "primary",
            },
          ]}
        />
        {/* DIALOGO PARA ABRIR EL COMPONENTE DE LOS BÓVEDA*/}
        <DialogComp
          open={openAlmacenar}
          title={""}
          fullWidth={true}
          fullScreen={true}
          maxWidth={"lg"}
          content={
            <div>
              {archivoAlmacenar ? (
                <Almacenar
                  refreshTables={refreshTables}
                  file={archivoAlmacenar}
                  carpeta={patch}
                  setOpen={setOpenAlmacenar}
                  setFile={setArchivoAlmacenar}
                  setRefresh={handleActualizar}
                  idFolderActual={idFolderActual}
                />
              ) : null}
            </div>
          }
        />

        {/* Dialog agregar metadata */}
        <DialogComp
          open={openDialogNewMeta}
          maxWidth={"md"}
          title="Agregar nuevo campo"
          content={
            <Grid container>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoFocus
                  required
                  margin="dense"
                  id="key"
                  name="nombreNewMeta"
                  label="Nombre del campo"
                  variant="outlined"
                  fullWidth
                  inputProps={{
                    maxlength: 128,
                  }}
                  value={nombreNewMeta}
                  onChange={(e) => {
                    handleChangeNewMetadata(e);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoFocus
                  required
                  margin="dense"
                  id="value"
                  label="Registra información del campo adicional”"
                  variant="outlined"
                  fullWidth
                  inputProps={{
                    maxlength: 128,
                  }}
                  name="valorNewMeta"
                  value={valorNewMeta}
                  onChange={(e) => {
                    handleChangeNewMetadata(e);
                  }}
                />
              </Grid>
            </Grid>
          }
          buttons={[
            {
              buttonEvent: handleAgregarNuevoMetadato,
              buttonDisabled: nombreNewMeta && valorNewMeta ? false : true,
              buttonName: "Agregar",
              buttonColor: "primary",
            },
            {
              buttonEvent: handleCancelarNuevoMeta,
              buttonName: "Cancelar",
              buttonColor: "secondary",
            },
          ]}
        />
        {/* DIALOGO PARA CONFIRMACION DE ELIMINACION DE METADATO*/}
        <DialogComp
          open={openConfirmarDeleteMeta}
          title={"¿Eliminar campo adicional?"}
          maxWidth={"sm"}
          buttons={[
            {
              buttonEvent: () => handleCancelarEliminarMeta(),
              buttonName: "Cancelar",
              buttonColor: "secondary",
            },
            {
              buttonEvent: () => handleEliminarMetadato(),
              buttonName: "Confirmar",
              buttonColor: "primary",
            },
          ]}
        />

        <DialogComp
          open={progrees}
          // title={"¿Eliminar metadato?"}
          bground={true}
          maxWidth={"sm"}
          content={
            <Backdrop className={classes.backdrop} open={progrees}>
              <CircularProgress size={44} className={classes.buttonProgress} />
            </Backdrop>
          }
        />

        {/* Dialogo para generar constancia  */}
        <DialogComp
          open={openGenerarConstancia}
          onClose={() => setOpenGenerarConstancia(false)}
          title="Constancia de Conservación"
          content="¿Está seguro de generar la constancia para este documento?"
          buttons={[
            {
              buttonEvent: () => setOpenGenerarConstancia(false),
              buttonName: "Cerrar",
              buttonColor: "secondary",
            },
            {
              buttonEvent: handleGenerarConstancia,
              buttonName: "Generar",
              buttonColor: "primary",
            },
          ]}
        />

        {/*DIALOGO PARA ABRIR EL COMPONENTE DE LEMP ADJUNTOS*/}
        <DialogComp
          open={openDialogAdjuntos}
          title={""}
          fullWidth={true}
          maxWidth={"lg"}
          content={
            <Adjuntos documento={docuDataPerson} refreshTables={refreshTables} />
          }
          buttons={[
            {
              buttonEvent: () => handleCloseAdjuntos(),
              buttonName: "Cerrar",
              buttonColor: "secondary",
            },
          ]}
        />

        <Snackbar
          place="tc"
          color="danger"
          icon={ErrorIcon}
          message={messageError}
          open={openAlert}
          closeNotification={() => setOpenAlert(false)}
          close
        />
      </div>
    </div>
  );
}
