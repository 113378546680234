import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Grid, Snackbar } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

export default function SessionExpiredModal(props) {
  const { sessionExpired } = useSelector((state) => state.app);
  const history = useHistory();

  return (
    <React.Fragment>
      <Dialog
        open={sessionExpired}
        keepMounted
        // onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Su sesión ha expirado.
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <DialogContentText id="alert-dialog-description">
              Por favor inicie sesión de nuevo para seguir utilizando Firma
              Digital Con-Certeza.
            </DialogContentText>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                localStorage.removeItem("uri");
                localStorage.removeItem("Token");
                localStorage.removeItem("Video1");
                window.location.href = "/";
              }}
            >
              Entendido
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
