/**
 * Servicio que expone en fachada las llamadas al microservicio de CDO.
 */

import CdoDocumentos from './CdoDocumentos';
import CdoFirmantes from './CdoFirmantes';
import CdoExpedientes from './CdoExpedientes';
import CdoAdmin from './CdoAdmin';
import CdoAuth from './CdoAuth';

const CdoApi = {
    CdoDocumentos,
    CdoFirmantes,
    CdoExpedientes,
    CdoAdmin,
    CdoAuth,
};

export default CdoApi;