import React from "react";
import Grid from "@material-ui/core/Grid";
import GridItem from "components/Grid/GridItem.js";
import TextField from "@material-ui/core/TextField";
import { DialogComp } from "components/Dialog/DialogComp";

export default function NcwGroupModal({ getter, setter, show, onSave }) {

    const isError = getter.name.length <= 0;

    const hSave = (ev) => {
        if (ev)
            ev.preventDefault();

        if (isError)
            return;

        setter({ ...getter, show: false, });
        onSave(getter);
    }

    const hCancel = () => {
        setter({ ...getter, show: false, });
    }


    const content = !show ? (<div></div>) : (
        <form onSubmit={hSave}>
            <Grid container>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <TextField
                        label="Nombre"
                        name="name"
                        variant="outlined"
                        value={getter.name}
                        onChange={(ev) => {
                            setter({ ...getter, name: ev.target.value });
                        }}
                        inputProps={{ required: true, minLength: 1 }}
                        error={getter.name.length <= 0}
                        margin="dense"
                        fullWidth
                        autoFocus
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <TextField
                        label="Descripción"
                        name="description"
                        variant="outlined"
                        value={getter.description}
                        onChange={(ev) => {
                            setter({ ...getter, description: ev.target.value });
                        }}
                        inputProps={{ required: true, minLength: 1 }}
                        error={getter.description.length <= 0}
                        margin="dense"
                        fullWidth
                    />
                </GridItem>
            </Grid>
            <input type="submit" style={{ display: "none" }} />
        </form>);

    return (
        <DialogComp open={show}
            title={getter.id === null || getter.id.length <= 0 ? "Crear nuevo grupo" : "Editar grupo"}
            content={content}
            fullWidth={"sm"}
            maxWidth={"sm"}
            buttons={[
                {
                    buttonEvent: hCancel,
                    buttonName: "Cancelar",
                    buttonColor: "secondary",
                },
                {
                    buttonEvent: hSave,
                    buttonName: "Guardar",
                    buttonColor: "primary",
                    buttonDisabled: isError
                },
            ]} />
    );
}