import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import styles2 from "assets/jss/material-dashboard-react/components/tasksStyle.js";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MaterialTable from "material-table";
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import GetAppIcon from '@material-ui/icons/GetApp';
import { DialogComp } from "components/Dialog/DialogComp";
import PDFView from "containers/Expedientes/PDFView";

const useStyles = makeStyles((theme) => ({
  input: {
    display: "none",
  },
  botonupload: {
    marginLeft: 5,
  },
  titulo: {
    color: "black",
    fontWeight: "bold"
  },
}));
const useStylestable = makeStyles(styles2);

const localization = {
  body: {
    emptyDataSourceMessage: "No hay datos por mostrar",
    addTooltip: "Añadir",
    deleteTooltip: "Eliminar",
    editTooltip: "Editar",
    filterRow: {
      filterTooltip: "Filtrar",
    },
  },
  header: {
    actions: "",
  },
  pagination: {
    firstAriaLabel: "Primera página",
    firstTooltip: "Primera página",
    labelDisplayedRows: "{from}-{to} de {count}",
    labelRowsPerPage: "Filas por página:",
    labelRowsSelect: "filas",
    lastAriaLabel: "Ultima página",
    lastTooltip: "Ultima página",
    nextAriaLabel: "Pagina siguiente",
    nextTooltip: "Pagina siguiente",
    previousAriaLabel: "Pagina anterior",
    previousTooltip: "Pagina anterior",
  },
  toolbar: {
    exportAriaLabel: "Exportar",
    exportTitle: "Exportar",
    exportCSVName: "Exportar a CVS",
    exportPDFName: "Exportar a PDF",
    searchPlaceholder: "Buscar",
    searchTooltip: "Buscar",
  },
};

export default function Adjuntos(props) {
  const classes = useStyles();
  const classes2 = useStylestable();
  const [nombreAdjunto, setNombreAdjunto] = useState("");
  const [adjuntos, setAdjuntos] = useState([]);
  const [file, setFile] = React.useState("");
  const [entro, setEntro] = React.useState(0);
  const [id, setId] = React.useState("");
  const [modalEliminar, setModalEliminar] = React.useState(false);
  const [adjuntoEliminar, setAdjuntoEliminar] = React.useState("");
  const [openZip, setOpenZip] = useState(false);
  const [imgUrl, setImgUrl] = useState();
  const [base64PDF, setbase64PDF] = useState();
  const [tipoAdjunto, setTipoAdjunto] = useState();
  const [modalImagen, setModalImagen] = React.useState(false);
  const [modalPDF, setModalPDF] = React.useState(false);
  //console.log("documento",props.documento);
  /*
    if (props.documento.adjuntos){
      setAdjuntos(props.documento.adjuntos);
    }
    */
  if (props.documento && entro == 0) {
    getAdjuntos(props.documento._id);
    setId(props.documento._id);
    setEntro(1);
  } else {
    if (props.documento._id != id) setEntro(0);
  }

  //funcion para visualizar imagenes adjuntas
  const previewAdjuntoPDF = (idDocu, fileName) => {
    //const nameFormat = fileName.slice(0, -4);
    //setOpenZip(true);
    let token = sessionStorage.getItem("Token");
    axios({
      url: `${process.env.REACT_APP_URL}api/adjuntos/download/${idDocu}`,
      method: "GET",
      responseType: "blob", // important
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const imageBlob = new Blob([response.data]);
        const reader = new FileReader();
        reader.readAsDataURL(imageBlob);
        reader.onloadend = () => {
          const base64data = reader.result;
          //console.log(base64data);
          setbase64PDF(base64data);
          setModalPDF(true);
        };
        /*
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${fileName}`);
        document.body.appendChild(link);
        link.click();
        */
        //setOpenZip(false);
      })
      .catch((error) => {
        console.error(error);
        //setOpenZip(false);
      });
  };

  //funcion para visualizar imagenes adjuntas
  const previewAdjuntoImagen = (idDocu, fileName) => {
    //const nameFormat = fileName.slice(0, -4);
    //setOpenZip(true);
    let token = sessionStorage.getItem("Token");
    axios({
      url: `${process.env.REACT_APP_URL}api/adjuntos/download/${idDocu}`,
      method: "GET",
      responseType: "blob", // important
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const imageBlob = new Blob([response.data]);
        const reader = new FileReader();
        reader.readAsDataURL(imageBlob);
        reader.onloadend = () => {
          const base64data = reader.result;
          //console.log(base64data);
          setImgUrl(base64data);
          setModalImagen(true);
        };
        /*
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${fileName}`);
        document.body.appendChild(link);
        link.click();
        */
        //setOpenZip(false);
      })
      .catch((error) => {
        console.error(error);
        //setOpenZip(false);
      });
  };

  //funcion para descargar adjuntos
  const downloadAdjunto = (idDocu, fileName) => {
    //const nameFormat = fileName.slice(0, -4);
    setOpenZip(true);
    let token = sessionStorage.getItem("Token");
    axios({
      url: `${process.env.REACT_APP_URL}api/adjuntos/download/${idDocu}`,
      method: "GET",
      responseType: "blob", // important
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${fileName}`);
        document.body.appendChild(link);
        link.click();
        setOpenZip(false);
      })
      .catch((error) => {
        console.error(error);
        setOpenZip(false);
      });
  };

  function handleEliminar() {
    console.log("eliminar ", adjuntoEliminar);
    let token = sessionStorage.getItem("Token");
    var config = {
      method: 'delete',
      url: `${process.env.REACT_APP_URL}api/adjuntos/${adjuntoEliminar._id}`,
      headers: {
        'Accept': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        getAdjuntos(adjuntoEliminar.documentos_id);
        props.refreshTables();
        setModalEliminar(false);
      })
      .catch(function (error) {
        console.log(error);
        //setAdjuntos([]);
      });
  }

  async function getAdjuntos(id_documento) {
    if (id_documento) {
      let token = sessionStorage.getItem("Token");
      var config = {
        method: 'get',
        url: `${process.env.REACT_APP_URL}api/adjuntos/${id_documento}`,
        headers: {
          'Accept': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      };

      axios(config)
        .then(function (response) {
          //console.log(JSON.stringify(response.data));
          setAdjuntos(response.data);
        })
        .catch(function (error) {
          console.log(error);
          setAdjuntos([]);
        });
    }
  }

  async function handleUploadAdjunto(file) {

    let token = sessionStorage.getItem("Token");
    let url = `${process.env.REACT_APP_URL}api/adjuntos`;
    const data = new FormData();
    data.append('documentos_id', props.documento._id);
    data.append("filename", file);
    data.append('description', nombreAdjunto);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
        Accept: " application/json",
        type: "formData",
      },
    };

    axios
      .post(url, data, config, {
        // receive two parameter endpoint url ,form data
      })
      .then((response) => {
        // Respuesta del servidor
        console.log(response);
        getAdjuntos(props.documento._id)
        setNombreAdjunto("");
        props.refreshTables();
      })
      .catch((error) => {
        // Error 😨
        console.log(error);
      });


  };


  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <h3 className={classes.titulo}>Agregar nuevo adjunto a {props.documento.filename}</h3>
        </Grid>
        <Grid item xs={6}>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name_adjunto"
            label="Ingrese descripción del adjunto"
            variant="outlined"
            fullWidth
            value={nombreAdjunto}
            onChange={(e) => {
              setNombreAdjunto(e.target.value);
            }}
          />
        </Grid>
        {nombreAdjunto ? (
          <Grid item xs={3}>
            <>
              <input accept="image/x-png,image/gif,image/jpeg,application/pdf,application/vnd.ms-excel,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.zip" className={classes.input} id="icon-button-file" type="file"
                onChange={(event) => {
                  handleUploadAdjunto(event.target.files[0]);
                }}
              />
              <label htmlFor="icon-button-file">
                <IconButton color="primary" aria-label="upload picture" component="span">
                  <CloudUploadIcon /> Cargar
                </IconButton>
              </label>
            </>
          </Grid>
        ) : null}
      </Grid>
      <hr />
      <Grid container>
        <Grid item xs={12}>
          {adjuntos ? (
            <MaterialTable
              localization={localization}
              options={{
                //exportButton: { csv: false, pdf: false },
                exportAllData: true,
                draggable: false,
                pageSize: 5, // make initial page size
                pageSizeOptions: [5, 10, 20],
                actionsColumnIndex: -1,
              }}
              columns={[
                {
                  title: "Nombre",
                  field: "filename",
                },
                {
                  title: "Descripción",
                  field: "description",
                },
                {
                  title: "Fecha",
                  field: "created_at",
                  render: (row) => (
                    <span>
                      {row["created_at"]
                        ? moment(
                          row["created_at"]
                        ).format("DD/MM/YYYY")
                        : null}
                    </span>
                  ),
                },
                {
                  title: "Registrado por",
                  field: "users.name",
                },
              ]}
              data={adjuntos}
              title={"Adjuntos (" + adjuntos.length + ")"}
              actions={[
                (rowData) => ({
                  icon: () => (
                    (rowData.filename.split(".")[1] == 'png' || rowData.filename.split(".")[1] == 'jpeg' || rowData.filename.split(".")[1] == 'jpg' || rowData.filename.split(".")[1] == 'pdf') ? (
                      <VisibilityIcon
                        className={
                          classes2.tableActionButtonIcon +
                          " " +
                          classes2.edit
                        }
                      />
                    ) : null
                  ),
                  tooltip: "Visualizar adjunto",
                  onClick: (event, rowData) => {
                    //if(rowData.filename.split(".")[1]=="png" || rowData.split(".")[1]=="pdf"){
                    //console.log(rowData.filename.split(".")[1])
                    if (rowData.filename.split(".")[1] == 'png' || rowData.filename.split(".")[1] == 'jpeg' || rowData.filename.split(".")[1] == 'jpg' || rowData.filename.split(".")[1] == 'pdf') {
                      if (rowData.filename.split(".")[1] == 'pdf')
                        previewAdjuntoPDF(rowData._id, rowData.filename);
                      else
                        previewAdjuntoImagen(rowData._id, rowData.filename);
                    }
                    else {
                      downloadAdjunto(rowData._id, rowData.filename);
                    }
                  },
                  // disabled: rowData.type.id === 5,
                }),
                /*
                {
                  icon: () => (
                    <VisibilityIcon
                      className={
                        classes2.tableActionButtonIcon +
                        " " +
                        classes2.edit
                      }
                    />
                  ),
                  tooltip: "Visualizar adjunto",
                  onClick: (event, rowData) => {
                    //if(rowData.filename.split(".")[1]=="png" || rowData.split(".")[1]=="pdf"){
                    //console.log(rowData.filename.split(".")[1])
                    if(rowData.filename.split(".")[1]=='png' || rowData.filename.split(".")[1]=='jpeg' || rowData.filename.split(".")[1]=='jpg' || rowData.filename.split(".")[1]=='pdf' ){
                      if(rowData.filename.split(".")[1]=='pdf')
                        previewAdjuntoPDF(rowData._id, rowData.filename);
                      else
                        previewAdjuntoImagen(rowData._id, rowData.filename);
                    }
                    else{
                      downloadAdjunto(rowData._id, rowData.filename);
                    }
                  },
                },
                */
                {
                  icon: () => (
                    <GetAppIcon
                      className={
                        classes2.tableActionButtonIcon +
                        " " +
                        classes2.edit
                      }
                    />
                  ),
                  tooltip: "Descargar adjunto",
                  onClick: (event, rowData) => {
                    downloadAdjunto(rowData._id, rowData.filename);
                  },
                },
                {
                  icon: () => (
                    <DeleteIcon
                      className={
                        classes2.tableActionButtonIcon +
                        " " +
                        classes2.edit
                      }
                    />
                  ),
                  tooltip: "Eliminar adjunto",
                  onClick: (event, rowData) => {
                    setAdjuntoEliminar(rowData);
                    setModalEliminar(true);
                  },
                },
              ]}
            />
          ) : null}
        </Grid>
      </Grid>
      {/*DIALOGO PARA CONFIRMAR ELIMINACIÓN*/}
      <DialogComp
        open={modalEliminar}
        title={"¿Eliminar archivo adjunto?"}
        fullWidth={true}
        maxWidth={"xs"}
        buttons={[
          {
            buttonEvent: () => handleEliminar(),
            buttonName: "Confirmar",
            buttonColor: "primary",
          },
          {
            buttonEvent: () => setModalEliminar(false),
            buttonName: "Cancelar",
            buttonColor: "secondary",
          },
        ]}
      />
      {/* Dialogo de espera de descarga de adjunto */}
      <DialogComp
        open={openZip}
        title="Descarga de archivo adjunto"
        content="El archivo se descargará en el lugar habitual de Descargas. Esto puede tardar unos segundos..."
        grid={true}
      />
      {/* Dialogo para preview imagen */}
      <DialogComp
        open={modalImagen}
        title=""
        content={
          <div>
            <img src={imgUrl} alt="" />
          </div>
        }
        buttons={[
          {
            buttonEvent: () => setModalImagen(false),
            buttonName: "Cerrar",
            buttonColor: "primary",
          },
        ]}
      />
      {/* Dialogo para preview pdf */}
      <DialogComp
        open={modalPDF}
        title=""
        content={
          <>
            {base64PDF ? <PDFView base64={base64PDF} /> : ("NO hay PDF")}
          </>
        }
        buttons={[
          {
            buttonEvent: () => setModalPDF(false),
            buttonName: "Cerrar",
            buttonColor: "primary",
          },
        ]}
      />
    </>
  );
}
