import axios from 'axios';
import { store } from './../redux/store/store';

const AxiosInterceptor = {
    Hook() {

        const { dispatch } = store;

        // Instalar hook en requests
        axios.interceptors.request.use(function (request) {
            // Indicar status de carga, sistema cargando.
            dispatch({
                type: "SET_LOADING",
                payload: true,
            });
            return request;
        }, function (error) {
            return Promise.reject(error);
        });

        // Instalar hook en responses
        axios.interceptors.response.use(function (response) {
            // Actualizar status de carga, a falso. La petición se completó.
            dispatch({
                type: "SET_LOADING",
                payload: false,
            });
            return response;
        }, function (error) {
            // Determinar si se encuentra en dashboard
            const bearerToken = sessionStorage.getItem("Token");
            const isDashboard = window.location.pathname.split('/').length >= 3 && bearerToken && bearerToken.length > 0;
            // Actualizar status de carga, a falso. La petición se completó (aunque hayan errores).
            if (error?.response?.status === 401 && isDashboard) {
                dispatch({
                    type: "SET_SESSION_EXPIRED",
                    payload: true,
                });
            }
            dispatch({
                type: "SET_LOADING",
                payload: false,
            });
            return Promise.reject(error);
        });
    }
}

export default AxiosInterceptor;