import React, { useEffect, useState } from "react";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Cdo from "../../services/Cdo";
import PVSpeechProcessor from "./PVSpeechProcessor";
import PVBrowserRecorder from './PVBrowserRecorder';
import PVBuiltinCapture from './PVBuiltinCapture';


const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxWidth: 500,
    },
    layout: {
        width: "auto",
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(1000 + theme.spacing(2) * 2)]: {
            width: 1000,
            marginLeft: "auto",
            marginRight: "auto",
        },
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    buttonProgress2: {
        color: green[500],
        alignItems: "center",
        top: "50%",
        left: "50%",
        marginTop: 12,
        marginLeft: 12,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },

    paper: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
}));

export default function PVProcessor(props) {
    const classes = useStyles();
    const { handleNext } = props;
    const [sstResult, setSstResult] = useState('');
    const [isFetchingFrase, setIsFetchingFrase] = useState(false);
    const [isBusy, setBusy] = useState(false);
    const [isSstError, setSstError] = useState(false);
    const [challenge, setChallenge] = useState({
        frase: null,
        clave: 0,
    });
    const [snack, setSnack] = useState({
        show: false,
        variant: 'error',
        text: '',
        duration: 9000,
    });

    const isMobile = (/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Windows Phone/i.test(navigator.userAgent)) ? true : false;

    async function fetchFrase() {
        for (let i = 1; i <= 3; i++) {
            setBusy(true);
            setIsFetchingFrase(true);
            try {
                const { frase, clave } = await Cdo.CdoFirmantes.obtenerFirmaChallenge(isMobile);
                const isOk = frase && frase.length > 0 && clave > 0;
                if (!isOk)
                    throw new Error('Invalid server response.');
                // Si la peticion es correcta, cambiar el estado de la app.
                setChallenge({
                    frase,
                    clave
                });
                break;
            } catch (e) {
                console.error('Error al obtener frase: ' + e);
            } finally {
                setIsFetchingFrase(false);
                await new Promise((solve) => setTimeout(solve, 1000));
                setBusy(false);
            }
        }
    }

    // Obtener frase para prueba de vida del servidor.
    useEffect(() => {
        fetchFrase();
    }, []);

    const validateSpeech = async (speech) => {
        try {
            const { videoBlobUrl, sstResult, requiresApollo } = speech;
            // Indicar estado ocupado.
            setBusy(true);
            // Obtener blob de video y validar speech en backend.
            const videoBlob = await fetch(videoBlobUrl).then(e => e.blob());
            const result = await Cdo.CdoFirmantes.validateFirmaChallenge(videoBlob, sstResult);
            console.log('validateSpeech', result);
            if (!result.ok) {
                setBusy(false);
                onSpeechError('');
                return;
            }
            setSnack({
                show: true,
                duration: 9000,
                variant: 'success',
                text: 'Prueba exitosa.'
            });
            setTimeout(() => handleNext({ ...speech, ...challenge, }), 5000);
        } catch (e) {
            setBusy(false);
            console.error('ValidateSpeech exception:', e);
        }
    }

    const onSpeechError = (speech) => {
        console.log({ speech })
        setSstResult(speech);
        setSstError(true);
    }

    return (
        <React.Fragment>
            <Backdrop className={classes.backdrop} open={isBusy}>
                <CircularProgress size={44} className={classes.buttonProgress} />
            </Backdrop>
            <Dialog
                open={isSstError}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    La prueba de vida no ha sido exitosa, por favor vuelve a intentarlo tomando en cuenta las siguientes recomendaciones
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <p>1.- Asegúrate de leer correctamente el mensaje.</p>
                        <p>2.- Habla fuerte y claro.</p>
                        <p>3.- No hagas pausas prolongadas durante la lectura.</p>
                        <p>4.- Evita estar en lugares con mucho ruido.</p>

                        {sstResult && sstResult.length > 0 ?
                            <center style={{ marginTop: "1rem" }}>
                                <Typography variant="h6" style={{ textTransform: "none" }}>
                                    Resultado <i>"{sstResult}"</i>
                                </Typography>
                            </center>
                            : null}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        autoFocus
                        onClick={() => { setSstError(false); if (PVSpeechProcessor.isCaptureAvailable() && isMobile) { fetchFrase() } }}
                    >
                        Entendido
                    </Button>
                </DialogActions>
            </Dialog>
            <center>
                <div className={classes.typo}>
                    <h3>{isMobile ? 'Repite el siguiente código durante la grabación' : 'Lee en voz alta la frase en color azul.'}</h3>
                </div>
                <div className={classes.typo}>
                    {!isFetchingFrase ? <h3
                        style={{
                            color: "#376CFA",
                        }}
                    >{challenge.frase}</h3> :
                        <CircularProgress size={50} style={{ marginTop: "1rem" }} />}
                </div>
                {PVSpeechProcessor.isMediaRecorderAvailable() && !isMobile ?
                    <PVBrowserRecorder
                        hOnReturn={props.onReturn}
                        hOnSpeech={validateSpeech}
                        hOnSpeechError={onSpeechError}
                        hOnReset={fetchFrase}
                        hDisabled={isBusy}
                        hOnError={(text) => {
                            setSnack({ show: true, variant: 'error', duration: 9000, text })
                        }}
                    /> :
                    PVSpeechProcessor.isCaptureAvailable() && isMobile ?
                        <PVBuiltinCapture
                            hOnSpeech={validateSpeech}
                            hOnSpeechError={onSpeechError}
                            hOnReset={fetchFrase}
                            hDisabled={isBusy}
                            hOnError={(text) => {
                                setSnack({ show: true, variant: 'error', duration: 9000, text })
                            }}
                        /> : null
                }
                <Snackbar
                    open={snack.show}
                    autoHideDuration={snack.duration}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    onClose={() => setSnack({ show: false })}
                >
                    <Alert elevation={6} variant="filled" onClose={() => setSnack({ show: false })} severity={snack.variant}>
                        {snack.text}
                    </Alert>
                </Snackbar>
            </center>
        </React.Fragment>
    );
}