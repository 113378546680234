import React from "react";
import axios from "axios";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Chip from '@material-ui/core/Chip';
import FaceIcon from '@material-ui/icons/Face';
import Avatar from '@material-ui/core/Avatar';
import DoneIcon from '@material-ui/icons/Done';
import Typography from "@material-ui/core/Typography";
import CircularProgress from '@material-ui/core/CircularProgress';
import MaterialTable from "material-table";
import InfoIcon from "@material-ui/icons/Info";
import Close from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import GroupIcon from '@material-ui/icons/Group';
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import styles2 from "assets/jss/material-dashboard-react/components/tasksStyle.js";
const useStylestable = makeStyles(styles2);

export default function Grupos() {
  const colorStatistics = "#024ebc";
  const classes2 = useStylestable();
  const [loading, setLoading] = React.useState(true);
  const [dataGrupos, setDataGrupos] = React.useState([]);
  const [dataSubGrupos, setDataSubGrupos] = React.useState([]);
  const [grupo, setGrupo] = React.useState("");
  const [subGrupo, setSubGrupo] = React.useState("");
  const [dataUsuarios, setDataUsuarios] = React.useState([]);
  const [documentos, setDocumentos] = React.useState([]);
  const [documentosFiltrados, setDocumentosFiltrados] = React.useState([]);
  const [modal, setModal] = React.useState(false);
  const [dataModal, setDataModal] = React.useState([]);
  const [desde, setDesde] = React.useState("");
  const [hasta, setHasta] = React.useState("");

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });

  const handleDelete = () => {
    console.info('You clicked the delete icon.');
  };

  const handleClick = (grupo) => {
    console.info('You clicked the Chip.', grupo);
  };

  /*
    const generalGroups = dataGrupos.map((item,i)=>{
      return (
        <Chip
          key={i}
          //avatar={<Avatar>M</Avatar>}
          label={ (item.grupogeneral==="") ? "Sin Definir":item.grupogeneral}
          clickable
          //color="success"
          //onDelete={handleDelete}
          onClick={()=>{
            console.log("Grupo general:",item.grupogeneral)
            console.log("Grupos Internos:",item.grupos.groups)
            setGrupo(item.grupogeneral);
            setDataSubGrupos(item.grupos.groups);
            setDataUsuarios([]);
          }}
          deleteIcon={<DoneIcon />}
          style={{background:"#00acc1",color:"white",margin:3}}
        />
      )
  });
  */
  const generalGroups = dataGrupos.map((item, i) => {
    return (
      <Button
        key={i}
        variant="outlined"
        color="primary"
        onClick={() => {
          console.log("Grupo general:", item.grupogeneral)
          console.log("Grupos Internos:", item.grupos.groups)
          setGrupo(item.grupogeneral);
          setDataSubGrupos(item.grupos.groups);
          setDataUsuarios([]);
        }}
        style={{ margin: 1 }}>
        {(item.grupogeneral === "") ? "Sin Definir" : item.grupogeneral}
      </Button>
    )
  });
  /*
  const subGroups = dataSubGrupos.map((item,i)=>{
    return (
      <Chip
        key={i}
        //avatar={<Avatar>M</Avatar>}
        label={ (item.name==="") ? "Sin Definir":item.name}
        clickable
        //color="success"
        //onDelete={handleDelete}
        onClick={()=>{
          console.log("Sub Grupo:",item.name)
          console.log("Usuarios:",item.users)
          //setSubGrupo(item.name);
  
          setSubGrupo({
            "name":item.name,
            "id":item.id
          });
  
          //todos los usuarios
          //setDataUsuarios(item.users);
  
          //solos los del GroupID
          var num = item.users.filter(function(user) {
            return user.group_id === item.id;
          });
          setDataUsuarios(num);
          setDocumentosFiltrados([])
  
        }}
        deleteIcon={<GroupIcon />}
        style={{background:colorStatistics,color:"white",margin:3}}
      />
    )
  });
  */

  const subGroups = dataSubGrupos.map((item, i) => {
    return (
      <Button
        key={i}
        variant="outlined"
        color="info"
        onClick={() => {
          console.log("Sub Grupo:", item.name)
          console.log("Usuarios:", item.users)
          //setSubGrupo(item.name);

          setSubGrupo({
            "name": item.name,
            "id": item.id
          });

          //todos los usuarios
          //setDataUsuarios(item.users);

          //solos los del GroupID
          var num = item.users.filter(function (user) {
            return user.group_id === item.id;
          });
          setDataUsuarios(num);
          setDocumentosFiltrados([])

        }}
        style={{ background: colorStatistics, color: "white", margin: 1 }}>
        {(item.name === "") ? "Sin Definir" : item.name}
      </Button>
    )
  });

  function getdocumentos() {
    let token = sessionStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_URL}api/documentos`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    };
    axios(config)
      .then(function (response) {
        let data = response.data;
        console.log("Documentos:", data);
        setDocumentos(data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function carga() {
    let token = sessionStorage.getItem("Token");
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_URL}api/roles/group`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    };
    axios(config)
      .then(function (response) {
        getdocumentos();
        let data = response.data;
        let grupos = [];
        console.log("GRUPOS", data);

        Object.keys(data).forEach(function (n, i) {
          var v = data[n];
          //console.log('name' + i + ': ' + n + ', value' + i + ': ' + v);
          grupos = [...grupos, { grupogeneral: n, grupos: v }]
        });
        console.log(grupos);
        setDataGrupos(grupos);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function total(idgrupo) {
    var num;
    if (documentosFiltrados.length === 0) {
      num = documentos.filter(function (documento) {
        return documento.roles_id === idgrupo;
      });
    } else {
      num = documentosFiltrados.filter(function (documento) {
        return documento.roles_id === idgrupo;
      });
    }

    return num.length;
  }

  function totalestatus(idgrupo, estatus) {
    var num;
    if (documentosFiltrados.length === 0) {
      num = documentos.filter(function (documento) {
        return (documento.roles_id === idgrupo && documento.status.id === estatus);
      });
    } else {
      num = documentosFiltrados.filter(function (documento) {
        return (documento.roles_id === idgrupo && documento.status.id === estatus);
      });
    }


    return num.length;
  }

  function numero(id) {
    var num;
    if (documentosFiltrados.length === 0) {
      num = documentos.filter(function (documento) {
        return documento.uid === id;
      });
    } else {
      num = documentosFiltrados.filter(function (documento) {
        return documento.uid === id;
      });
    }

    return num.length;
  }

  function estatus(id, estatus) {
    var num;
    if (documentosFiltrados.length === 0) {
      num = documentos.filter(function (documento) {
        return (documento.uid === id && documento.status.id === estatus);
      });
    } else {
      num = documentosFiltrados.filter(function (documento) {
        return (documento.uid === id && documento.status.id === estatus);
      });
    }

    return num.length;
  }

  const onChangeDesde = (e) => {
    setDesde(e.target.value);
    console.log("desde", e.target.value); // Works
  };

  const onChangeHasta = (e) => {
    setHasta(e.target.value);
    console.log("hasta", e.target.value); // Works
  };

  const handleFecha = () => {

  };

  React.useEffect(() => {
    //fecha
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let separator = "-";

    let fecha = `${year}${separator}${month < 10 ? `0${month}` : `${month}`
      }${separator}${date}`;
    setDesde(fecha);
    setHasta(fecha);

    if (dataGrupos.length === 0) {
      carga();
    }
  }, []);

  const downloadCsv = (data, fileName) => {
    const finalFileName = fileName.endsWith(".csv") ? fileName : `${fileName}.csv`;
    const a = document.createElement("a");
    a.href = URL.createObjectURL(new Blob([data], { type: "text/csv" }));
    a.setAttribute("download", finalFileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  const localization = {
    body: {
      emptyDataSourceMessage: "No hay datos por mostrar",
      addTooltip: "Añadir",
      deleteTooltip: "Eliminar",
      editTooltip: "Editar",
      filterRow: {
        filterTooltip: "Filtrar",
      },
    },
    header: {
      actions: "",
    },
    pagination: {
      firstAriaLabel: "Primera página",
      firstTooltip: "Primera página",
      labelDisplayedRows: "{from}-{to} de {count}",
      labelRowsPerPage: "Filas por página:",
      labelRowsSelect: "filas",
      lastAriaLabel: "Ultima página",
      lastTooltip: "Ultima página",
      nextAriaLabel: "Pagina siguiente",
      nextTooltip: "Pagina siguiente",
      previousAriaLabel: "Pagina anterior",
      previousTooltip: "Pagina anterior",
    },
    toolbar: {
      exportAriaLabel: "Exportar",
      exportTitle: "Exportar",
      exportCSVName: "Exportar a CVS",
      exportPDFName: "Exportar a PDF",
      searchPlaceholder: "Buscar",
      searchTooltip: "Buscar",
    },
  };

  return (
    <React.Fragment>
      {
        (dataGrupos.length > 0) ? (
          <React.Fragment>
            <Typography color="primary" gutterBottom>
              <font size="5">
                <b>COMPAÑÍAS</b>
              </font>
            </Typography>
            {generalGroups}
            <hr />
          </React.Fragment>
        ) : null
      }
      {
        (documentos.length === 0) ? (
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
            <CircularProgress />
          </div>
        ) : null
      }
      {
        (dataSubGrupos.length > 0) ? (
          <React.Fragment>
            <Typography gutterBottom>
              <font size="4">
                <b>GRUPOS {grupo}</b>
              </font>
            </Typography>
            {subGroups}
            <hr />
          </React.Fragment>
        ) : null
      }
      {
        dataUsuarios.length > 0 ? (
          <React.Fragment>
            <Grid container>
              <Grid item xs={6} sm={6}>
                <Card variant="outlined" style={{ borderColor: "white" }}>
                  <CardContent>
                    <Typography
                      color="textPrimary"
                      gutterBottom
                    >
                      <b>Rango de fechas:</b>
                    </Typography>
                    <hr />
                    <TextField
                      id="dateDesde"
                      label="Desde"
                      type="date"
                      onChange={onChangeDesde}
                      value={desde}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      id="dateHasta"
                      label="Hasta"
                      type="date"
                      onChange={onChangeHasta}
                      value={hasta}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    &nbsp;&nbsp;
                    <Button variant="outlined" color="primary" onClick={() => {
                      console.log("clic");

                      var startDate = new Date(desde + " 00:00:00");
                      var endDate = new Date(hasta + " 23:59:59");
                      var result = documentos.filter(function (a) {
                        return (
                          new Date(a.created_at) >= startDate &&
                          new Date(a.created_at) <= endDate
                        );
                      });

                      setDocumentosFiltrados(result);

                    }}>
                      CONSULTAR
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography
                      color="textPrimary"
                      gutterBottom
                    >
                      <b>TOTAL GRUPO {subGrupo.name}:</b>
                    </Typography>
                    <hr />
                    <Typography
                      color="textPrimary"
                      style={{ "margin": 0 }}
                    >
                      <font><b>{total(subGrupo.id)} Documentos</b></font><br />
                      <font color="#66bb6a">{totalestatus(subGrupo.id, 3)} Firmados</font>/
                      <font color="#ffa726">{totalestatus(subGrupo.id, 2)} En Espera</font>/
                      <font color="#ef5350">{totalestatus(subGrupo.id, 4)} Rechazados</font>/
                      <font color="#ab47bc">{totalestatus(subGrupo.id, 1)} Registrados</font>/
                      <font color="red">{totalestatus(subGrupo.id, 7)} Eliminados</font><br />
                    </Typography>

                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <MaterialTable
              localization={localization}
              options={{
                draggable: false,
                //exportButton: { csv: true, pdf: false },
                headerStyle: {
                  backgroundColor: colorStatistics,
                  color: "#FFF",
                },
                pageSize: 10, // make initial page size
                pageSizeOptions: [10, 20, 50, 100, 500, 1000],
                actionsColumnIndex: -1,
                actionsColumnIndex: 0,
              }}
              columns={[
                { title: "Nombre", field: "name" },
                { title: "Correo", field: "email" },
                {
                  title: "Documentos",
                  render: (row) => (
                    <span>
                      {numero(row.id)}
                    </span>
                  ),
                },
                {
                  title: "Firmados",
                  render: (row) => (
                    <span>
                      {estatus(row.id, 3)}
                    </span>
                  ),
                },
                {
                  title: "Espera",
                  render: (row) => (
                    <span>
                      {estatus(row.id, 2)}
                    </span>
                  ),
                },
                {
                  title: "Rechazados",
                  render: (row) => (
                    <span>
                      {estatus(row.id, 4)}
                    </span>
                  ),
                },
                {
                  title: "Registrados",
                  render: (row) => (
                    <span>
                      {estatus(row.id, 1)}
                    </span>
                  ),
                },
                {
                  title: "Eliminados",
                  render: (row) => (
                    <span>
                      {estatus(row.id, 7)}
                    </span>
                  ),
                },
              ]}
              data={dataUsuarios}
              title={"Usuarios " + subGrupo.name + " (" + dataUsuarios.length + ")"}
              actions={[
                {
                  icon: () =>
                    <InfoIcon
                      className={
                        classes2.tableActionButtonIcon +
                        " " +
                        classes2.edit
                      }
                    />,
                  tooltip: "Ver más detalles",
                  onClick: (event, rowData) => {
                    var num;
                    if (documentosFiltrados.length === 0) {
                      num = documentos.filter(function (documento) {
                        return documento.uid === rowData.id;
                      });
                    } else {
                      num = documentosFiltrados.filter(function (documento) {
                        return documento.uid === rowData.id;
                      });
                    }

                    setDataModal(num);
                    setModal(true);
                  },
                },
              ]}
            />
          </React.Fragment>
        ) : null
      }

      <Dialog
        open={modal}
        transition={Transition}
        keepMounted
        fullScreen={false}
        fullWidth={true}
        maxWidth={"lg"}
        onClose={() => setModal(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
        >
          {/*
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="transparent"
          onClick={() => setModal(false)}
        >
          <Close className={classes.modalClose} />
        </Button>
        */}
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
        >
          <MaterialTable
            localization={localization}
            options={{
              draggable: false,
              exportButton: { csv: true, pdf: false },
              headerStyle: {
                backgroundColor: colorStatistics,
                color: "#FFF",
              },
              pageSize: 20, // make initial page size
              pageSizeOptions: [20, 50, 100, 500, 1000],
              actionsColumnIndex: -1,
              actionsColumnIndex: 0,
            }}
            columns={[
              { title: "DOCUMENTO", field: "filename" },
              { title: "TIPO", field: "type.name" },
              {
                title: "FECHA",
                field: "created_at",
                render: (row) => (
                  <span>
                    {(row["created_at"]) ? moment(row["created_at"]).format("DD/MM/YYYY HH:mm:ss") : null}
                  </span>
                ),
              },
              { title: "ESTATUS", field: "status.name" },
              { title: "FIRMANTES", field: "firmantes_names" },
              { title: "ID", field: "_id" },
            ]}
            data={dataModal}
            title="Documentos"
          />
        </DialogContent>
        <DialogActions

        >
          <Button onClick={() => setModal(false)}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

    </React.Fragment>
  )

}
