import React from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MaterialTable from "material-table";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import InfoIcon from "@material-ui/icons/Info";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    float: "right",
  },
}));

export default function Firmantes(props) {
  const classes = useStyles();
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });

  const [value, setValue] = React.useState("1");
  const [estiloCelular, setEstiloCelular] = React.useState("gray");
  const [modalFirmantes, setModalFirmantes] = React.useState(
    props.modalFirmantes
  );
  const [listaFirmantes, setListaFirmantes] = React.useState([]);
  const [textoValidacionMail, setTextoValidacionMail] = React.useState("");
  const [datos, setDatos] = React.useState({
    nombre: "",
    apellidos: "",
    correo: "",
    codigo: "52",
    celular: "",
    rol: "",
    Compañia: "",
  });

  React.useEffect(() => {
    actualizarFirmantes(props.idDocumento);
  }, []);

  function validarMail(email) {
    let tokenSG =
      "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";
    var config = {
      async: true,
      crossDomain: true,
      method: "post",
      url: `https://api.sendgrid.com/v3/validations/email`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + tokenSG,
      },
      processData: false,
      data: `{\"email\":\"${email}\",\"source\":\"signup\"}`,
    };
    axios(config)
      .then(function (response) {
        console.log("Email Validation:", response);
        if (response.data.result.verdict === "Invalid") {
          setTextoValidacionMail("Correo no valido");
        } else {
          setTextoValidacionMail("");
        }
        //verdict: "Invalid"
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleClickFirmantes = () => {
    registraFirmantes();
  };

  function eliminarFirmante(id) {
    let tokenCC = sessionStorage.getItem("Token");

    var config = {
      method: "delete",
      url: `${process.env.REACT_APP_URL}api/firmantes/${id}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + tokenCC,
      },
    };

    axios(config)
      .then(function (response) {
        console.log("FirmanteCC eliminado:", response);
        actualizarFirmantes(props.idDocumento);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function registraFirmantes() {
    var result = await makePostFirmantes();
    console.log(result);
    setDatos({
      nombre: "",
      apellidos: "",
      correo: "",
      codigo: "52",
      celular: "",
      rol: "",
      Compañia: "",
    });
    actualizarFirmantes(props.idDocumento);
    console.log("Firmante OK");
  }

  function makePostFirmantes() {
    let token = sessionStorage.getItem("Token");

    const data = new FormData();

    data.append("name", datos.nombre);
    data.append("last_name", datos.apellidos);
    data.append("email", datos.correo);
    data.append("notification_types_id", value);
    data.append("phone_country_code", datos.codigo);
    data.append("phone", datos.celular);
    data.append("documentos_id", props.idDocumento);
    data.append("role", "Notificado");
    data.append("company", datos.Compañia);

    //var url = "https://api.con-certeza.mx/api/firmantes";

    //var url = `${process.env.REACT_APP_URL_DEV}api/notificados/`;
    //let url = `${process.env.REACT_APP_URL}api/firmantes/`;
    let url = `${process.env.REACT_APP_URL}api/firmantes`;
    var config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer  " + token,
      },
    };
    return new Promise(function (resolve, reject) {
      axios.post(url, data, config, {}).then(
        (response) => {
          var result = response.data;
          console.log("Processing Request");
          resolve(result);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  const actualizarFirmantes = (idDocumento) => {
    let token = sessionStorage.getItem("Token");

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_URL}api/documentos/${idDocumento}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    };
    axios(config)
      .then(function (response) {
        let arr = response.data.firmantes;
        console.log("Firmantes:", arr);
        setListaFirmantes(arr);
        props.setFirmantes(arr);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleInput = (event) => {
    setDatos({
      ...datos,
      [event.target.name]: event.target.value,
    });
  };

  const handleChange = async (event) => {
    await setValue(event.target.value);
  };

  let disableform = true;
  if (
    datos &&
    datos.nombre.length >= 3 &&
    datos.apellidos.length >= 3 &&
    datos.correo.length >= 5 &&
    datos.celular.length >= 8 &&
    datos.Compañia.length >= 3
  ) {
    disableform = false;
  }

  const localization = {
    body: {
      emptyDataSourceMessage: "No hay datos por mostrar",
      addTooltip: "Añadir",
      deleteTooltip: "Eliminar",
      editTooltip: "Editar",
      filterRow: {
        filterTooltip: "Filtrar",
      },
    },
    header: {
      actions: "",
    },
    pagination: {
      firstAriaLabel: "Primera página",
      firstTooltip: "Primera página",
      labelDisplayedRows: "{from}-{to} de {count}",
      labelRowsPerPage: "Filas por página:",
      labelRowsSelect: "filas",
      lastAriaLabel: "Ultima página",
      lastTooltip: "Ultima página",
      nextAriaLabel: "Pagina siguiente",
      nextTooltip: "Pagina siguiente",
      previousAriaLabel: "Pagina anterior",
      previousTooltip: "Pagina anterior",
    },
    toolbar: {
      exportAriaLabel: "Exportar",
      exportTitle: "Exportar",
      exportCSVName: "Exportar a CVS",
      exportPDFName: "Exportar a PDF",
      searchPlaceholder: "Buscar",
      searchTooltip: "Buscar",
    },
  };

  return (
    <React.Fragment>
      {/* MODAL FIRMANTES */}
      <Dialog
        open={modalFirmantes}
        transition={Transition}
        keepMounted
        fullScreen={false}
        fullWidth={true}
        maxWidth={"lg"}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        ></DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          <div>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Typography variant="h6" gutterBottom></Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  id="correo"
                  name="correo"
                  label="Correo electrónico"
                  value={datos.correo}
                  fullWidth
                  autoComplete="email"
                  onChange={handleInput}
                  onBlur={() => {
                    if (datos.correo) {
                      validarMail(datos.correo);
                    }
                  }}
                  inputProps={{ maxLength: 60 }}
                />
                <font color="red">{textoValidacionMail}</font>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="nombre"
                  name="nombre"
                  label="Nombre"
                  value={datos.nombre}
                  fullWidth
                  autoComplete="given-name"
                  onChange={handleInput}
                  inputProps={{ maxLength: 30 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="apellidos"
                  name="apellidos"
                  label="Apellidos"
                  value={datos.apellidos}
                  fullWidth
                  autoComplete="family-name"
                  onChange={handleInput}
                  inputProps={{ maxLength: 60 }}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <font color={estiloCelular} size="2">
                  Código *
                </font>
                <PhoneInput
                  countryCodeEditable={false}
                  inputStyle={{ width: "110%" }}
                  inputExtraProps={{
                    name: "codigo",
                    id: "codigo",
                    required: true,
                    autoFocus: true,
                    readonly: true,
                  }}
                  readonly
                  id="codigo"
                  name="codigo"
                  label="codigo"
                  onlyCountries={["us", "mx"]}
                  localization={{
                    "United States": "Estados Unidos",
                    Mexico: "México",
                  }}
                  country={"mx"}
                  value={datos.codigo}
                  onChange={(phone) => {
                    setEstiloCelular("gray");
                    setDatos({
                      ...datos,
                      codigo: phone,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  required
                  id="celular"
                  name="celular"
                  label="Celular"
                  value={datos.celular}
                  onChange={handleInput}
                  inputProps={{ maxLength: 15 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="Compañia"
                  name="Compañia"
                  label="Compañía"
                  value={datos.Compañia}
                  fullWidth
                  onChange={handleInput}
                  inputProps={{ maxLength: 60 }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Envío de notificación</FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="radios"
                  value={value}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="1"
                    name="1"
                    control={<Radio />}
                    label="Correo"
                  />
                  <FormControlLabel
                    value="2"
                    name="2"
                    control={<Radio />}
                    label="Mensaje de texto"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Button
                variant="contained"
                style={{ backgroundColor: "#00acc1", color: "white" }}
                onClick={handleClickFirmantes}
                disabled={disableform}
              >
                <Typography variant="button" style={{ textTransform: "none" }}>
                  Agregar
                </Typography>
              </Button>
            </Grid>
            <hr />
            <MaterialTable
              localization={localization}
              options={{
                exportButton: true,
                headerStyle: {
                  backgroundColor: "#26c6da",
                  color: "#FFF",
                },
                pageSize: 5, // make initial page size
                pageSizeOptions: [5, 10, 20, 50],
                actionsColumnIndex: -1,
                exportButton: true,
              }}
              columns={[
                { title: "Nombre", field: "name" },
                { title: "Apellido", field: "last_name" },
                {
                  title: "Tipo de Notificación",
                  field: "notification_types_id",
                  render: (row) => (
                    <span>
                      {row["notification_types_id"] === 1 ? "Email" : "SMS"}
                    </span>
                  ),
                },
                { title: "Rol", field: "role" },
                { title: "Correo", field: "email" },
                { title: "Cód.", field: "phone_country_code" },
                { title: "Teléfono", field: "phone" },
                { title: "Compañía", field: "company" },
              ]}
              data={listaFirmantes}
              title={""}
              actions={[
                {
                  icon: () => <DeleteForeverIcon />,
                  tooltip: "Eliminar firmante",
                  onClick: (event, rowData) => {
                    let tokenCC = sessionStorage.getItem("Token");

                    var config = {
                      method: "delete",
                      url: `${process.env.REACT_APP_URL}api/firmantes/${rowData._id}`,
                      headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        Authorization: "Bearer " + tokenCC,
                      },
                    };

                    axios(config)
                      .then(function (response) {
                        console.log("FirmanteCC eliminado:", response);
                        actualizarFirmantes(props.idDocumento);
                      })
                      .catch(function (error) {
                        console.log(error);
                      });
                  },
                },
              ]}
            />
          </div>
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
          <Button
            onClick={() => {
              setModalFirmantes(false);
              props.setModalFirmantes(false);
            }}
            color="primary"
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
