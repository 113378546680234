import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import Cdo from "../../services/Cdo";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";

export default function PVNoCameraAvailableDialog(props) {
    return (
        <React.Fragment>
            <Dialog
                open={props.show}
                keepMounted
                // onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">
                    {"No se puede establecer conexión con la cámara"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <Typography>
                            <div>
                                <div>
                                    No ha sido posible acceder a la cámara. Por favor, revise su configuración y vuelva a intentarlo.
                                </div>
                                <div style={{ width: "100%", textAlign: "right", marginTop: "1rem" }}>
                                    <Button
                                        size="large"
                                        variant="contained"
                                        color="secondary"
                                        style={{ marginRight: "1rem" }}
                                        onClick={props.onReturn}
                                    >
                                        Regresar
                                    </Button>
                                    <Button
                                        size="large"
                                        variant="contained"
                                        color="primary"
                                        onClick={props.onRetry}
                                    >
                                        Reintentar
                                    </Button>
                                </div>
                            </div>
                        </Typography>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}