import React, { useState, useRef, useEffect } from "react";
import Alert from "@material-ui/lab/Alert";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import GridItem from "components/Grid/GridItem.js";
import PhoneInput from "react-phone-input-2";
import { DialogComp } from "components/Dialog/DialogComp";
import { Checkbox, FormControlLabel, FormHelperText, FormControl, FormLabel, RadioGroup, Radio } from "@material-ui/core";

function EditarMedioNotificacion(props) {

    const { show, onHide, onSubmit, form } = props;
    const [getter, setter] = useState({
        idFirmante: '',
        email: '',
        phone_country_code: '',
        phone: '',
        name: '',
        last_name: '',
        notification_types_id: 0,
        speech_required: false,
        isPhone: false,
        old_phone: '',
        old_email: '',
        rfc: '',
        rfc_empresa: '',
        date_notified: null,
    });

    useEffect(() => {
        if (!show)
            return;

        setter({ ...form });
    }, [show]);

    const content = (
        <div>
            <Grid container>
                <GridItem sm={12}>
                    <Alert style={{ marginBottom: "1rem" }} severity="warning">
                        Advertencia: Incluso si no hace cambios, al guardar se enviará un recordatorio al firmante.
                    </Alert>
                </GridItem>
                <GridItem sm={12} md={6}>
                    <TextField
                        label="Nombre"
                        variant="outlined"
                        value={getter.name}
                        onChange={(ev) => {
                            setter({ ...getter, name: ev.target.value });
                        }}
                        margin="dense"
                        fullWidth
                        disabled
                    />
                </GridItem>
                <GridItem sm={6} md={6}>
                    <TextField
                        label="Apellidos"
                        variant="outlined"
                        value={getter.last_name}
                        onChange={(ev) => {
                            setter({ ...getter, last_name: ev.target.value });
                        }}
                        margin="dense"
                        fullWidth
                        disabled
                    />
                </GridItem>
                <GridItem sm={6}>
                    <TextField
                        label="Correo electrónico"
                        variant="outlined"
                        value={getter.email}
                        onChange={(ev) => {
                            setter({ ...getter, email: ev.target.value });
                        }}
                        margin="dense"
                        fullWidth
                    />
                </GridItem>
                <GridItem sm={12} md={2}>
                    <font color="gray" size="2">
                        Código
                    </font>
                    <PhoneInput
                        countryCodeEditable={false}
                        inputStyle={{ width: "110%" }}
                        inputExtraProps={{
                            name: "codigo",
                            id: "codigo",
                            required: true,
                            autoFocus: true,
                            readonly: true,
                        }}
                        readonly
                        id="codigo"
                        name="codigo"
                        label="codigo"
                        onlyCountries={["us", "mx"]}
                        localization={{
                            "United States": "Estados Unidos",
                            Mexico: "México",
                        }}
                        country={!getter.phone_country_code || getter.phone_country_code == 52 ? "mx" : "us"}
                        value={getter.phone_country_code}
                        onChange={(value) => {
                            setter({ ...getter, phone_country_code: value });
                        }}
                    />
                </GridItem>
                <GridItem sm={12} md={4}>
                    <div style={{ display: "block", marginTop: "12px" }}>
                        <TextField
                            id="celular"
                            margin="dense"
                            name="celular"
                            variant="outlined"
                            label={getter.notification_types_id == 2 ? "Celular *" : "Celular"}
                            value={getter.phone}
                            type={"text"}
                            inputProps={{ maxLength: 15 }}
                            onChange={(ev) => {
                                setter({ ...getter, phone: ev.target.value })
                            }}
                            error={getter.phone && getter.phone.length < 10 ? true : false}
                        />
                        <FormHelperText style={{ color: "red" }}>
                            {getter.notification_types_id == 2 && getter.phone.length < 10
                                ? "Registre el campo celular."
                                : ""}
                        </FormHelperText>
                    </div>
                </GridItem>
                {getter.cert_required === true ?
                    (<GridItem sm={6}>
                        <TextField
                            label="RFC firmante"
                            variant="outlined"
                            value={getter.rfc}
                            onChange={(ev) => {
                                setter({ ...getter, rfc: ev.target.value });
                            }}
                            margin="dense"
                            fullWidth
                            inputProps={{
                                maxLength: 13,
                                style: { textTransform: "uppercase" },
                            }}
                            error={getter.rfc.length > 2 ? false : true}
                        />
                    </GridItem>) : null}
                {getter.cert_required === true ?
                    (<GridItem sm={6}>
                        <TextField
                            label="RFC empresa"
                            variant="outlined"
                            value={getter.rfc_empresa}
                            onChange={(ev) => {
                                setter({ ...getter, rfc_empresa: ev.target.value });
                            }}
                            margin="dense"
                            fullWidth
                        />
                    </GridItem>) : null}
                <GridItem sm={12} md={12}>
                    {false ? (<FormControlLabel
                        control={
                            <Checkbox
                                checked={getter.speech_required}
                                onChange={(ev) => {
                                    setter({ ...getter, speech_required: ev.target.checked })
                                }}
                                name="checkedVida"
                                color="primary"
                            />
                        }
                        label="Solicitar prueba de vida"
                    />) : null}
                </GridItem>
                <GridItem sm={12} md={12}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Tipo de notificación</FormLabel>
                        <RadioGroup
                            name="radios"
                            value={getter.notification_types_id}
                            onChange={(ev) => {
                                setter({ ...getter, notification_types_id: ev.target.value })
                            }}
                        >
                            <FormControlLabel
                                style={{ margin: -5 }}
                                value="1"
                                name="1"
                                control={<Radio />}
                                label="Correo electrónico"
                            />
                            <FormControlLabel
                                style={{ margin: -5 }}
                                value="2"
                                name="2"
                                control={<Radio />}
                                label="Mensaje de texto"
                            />
                        </RadioGroup>
                    </FormControl>
                </GridItem>
            </Grid>
        </div>
    );

    return (<DialogComp
        open={show}
        title={"Editar medio de notificación"}
        maxWidth={"md"}
        content={content}
        buttons={[
            {
                buttonEvent: onHide,
                buttonName: "Cancelar",
                buttonColor: "secondary",
            },
            {
                buttonEvent: () => onSubmit({ ...getter }),
                buttonName: "Guardar",
                buttonColor: "primary",
            },
        ]}
    />)
}

export default EditarMedioNotificacion;
