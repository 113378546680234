import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import NewPassword from "./getNewPassword";
import password from "assets/img/password.svg";
import logo from "assets/img/ConCertezaAzul.png";
import axios from "axios";
import { useHistory } from "react-router-dom";

import { BrowserRouter as Router, useParams } from "react-router-dom";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Powered "}
      <Link color="inherit" href="https://certificaciondigital.online/">
        by CDO
      </Link>{" "}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    padding: theme.spacing(1),
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

export default function ChangePass() {
  const classes = useStyles();
  let { idfirma } = useParams();
  const [activeStep, setActiveStep] = React.useState(0);
  const [idDocumento, setidDocumento] = React.useState("");
  const [success, setSuccess] = React.useState(false);
  const history = useHistory();

  const steps = ["Confirme su firma en el documento"];

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const setFirmado = () => {
    handleNext();
    setSuccess(false);
  };
  const handlefinalizar = () => {
    history.push("/");
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <NewPassword handleNext={handleNext} success={success} />;

      default:
        throw new Error("Unknown step");
    }
  };

  // const handleBack = () => {
  //   setActiveStep(activeStep - 1);
  // };

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="absolute" color="default" className={classes.appBar}>
        <Toolbar>
          <img src={logo} alt="logo" width="220" height="60" />
        </Toolbar>
      </AppBar>
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          {/* <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper> */}
          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography
                  variant="subtitle1"
                  style={{ textTransform: "none" }}
                  align="center"
                >
                  El cambio de contraseña ha sido exitoso, por favor, inicie sesión utilizando la nueva contraseña.
                </Typography>
                <div align="center">
                  <img src={password} alt="logo" width="280" height="230" />
                </div>
                <div className={classes.buttons}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handlefinalizar}
                    className={classes.button}
                  >
                    Finalizar
                  </Button>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep)}
                {activeStep === steps.length - 2 ? (
                  ""
                ) : activeStep === steps.length - 1 ? (
                  ""
                ) : (
                  <div className={classes.buttons}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1
                        ? "Finalizar"
                        : activeStep === steps.length - 2
                        ? "Ver documento"
                        : activeStep === steps.length - 3
                        ? "Ver documento"
                        : "siguiente"}
                    </Button>
                  </div>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
        <Copyright />
      </main>
    </React.Fragment>
  );
}
