import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import ControlPanel from "components/controlPanel/ControlPanel";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "components/CustomButtons/Button.js";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import axios from "axios";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import { isMobile } from "react-device-detect";
import { BrowserRouter as Router, useParams } from "react-router-dom";
import { saveLog, saveLogCapture } from "containers/Logs/funcionesLog.js";
import logo from "assets/img/ConCertezaAzul.png";
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import CdoApi from "services/Cdo";


const useStyles = makeStyles((theme) => ({


  backdrop: {
    zIndex: theme.zIndex.drawer + 9999,
    color: "#fff",
  },

}));



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function Notificacion(props) {
  const classes = useStyles();


  const dispatch = useDispatch();

  //pdf
  const [base64, setbase64] = React.useState("");
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pdfHeigth, setpdfHeigth] = React.useState(475);
  const [pdfWidth, setpdfWidth] = React.useState(550);
  const [marginTop, setMarginTop] = React.useState(700);
  const [scale, setScale] = React.useState(0.7);
  const [tokenNew, setTokenNew] = React.useState('');
  const [success, setSuccess] = React.useState(true);



  const [openPrivacidad, setopenPrivacidad] = React.useState(true);
  const { user, signToken } = useSelector((state) => state.auth);


  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }


  //pdf
  let { idfirmante, idDoc } = useParams();



  const clickDownload = () => {
    console.log("download");
    const linkSource = `data:application/pdf;base64,${base64}`;
    const downloadLink = document.createElement("a");
    const fileName = "NOTIFICACION-CERTIFICADA.pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  const handleClose = () => {
    let newDate = new Date();
    let date_accepted = moment(newDate).format(
      "YYYYMMDD HH:mm:ss"
    );
    var config = {
      method: "PUT",
      url: `${process.env.REACT_APP_URL}api/firmantes/${idfirmante}?date_accepted=${date_accepted}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + tokenNew,
      },
    };

    axios(config)
      .then(function (response) {
        console.log("Firmante Actualizado:", response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
    setopenPrivacidad(false);
  };


  React.useEffect(() => {


    const f = async () => {
      document.body.style.overflow = "auto";


      if (isMobile) {
        setpdfHeigth(500);
        setpdfWidth(290);
      }
      // Si no hay token temporal, crear uno
      await dispatch({
        type: "SET_SIGN_TOKEN",
        payload: null,
      });
      let token = signToken;
      try {
        token = await CdoApi.CdoAuth.checkin(idDoc, idfirmante);
        setTokenNew(token)
        setSuccess(false)

      } catch (e) {
        // Si falla en generarse un token, entonces asignar uno invalido a proposito.
        token = "FAIL_TO_GENERATE";
        setSuccess(false)

      }
      window.sessionStorage.setItem("signToken", token);
      await dispatch({
        type: "SET_SIGN_TOKEN",
        payload: token,
      });


      //Guardar log visualizó

      //buscar datos firmante
      axios
        .get(`${process.env.REACT_APP_URL}api/firmantes/${idfirmante}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          saveLog(
            token,
            res.data.name + " " + res.data.last_name + " Visualizó notificación " + idDoc
          );
          saveLogCapture(
            token,
            res.data.name + " " + res.data.last_name + " Visualizó notificación " + idDoc
          );
        })
        .catch((error) => {
          console.error(error);
        });
      //fin log
      axios
        .get(`${process.env.REACT_APP_URL}api/documentos/${idDoc}/signed/download`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setbase64(res.data);
        })
        .catch((error) => {
          console.error(error);
        });
    };

    f();

  }, []);

  return (
    <React.Fragment>
      <AppBar position="absolute" color="default">
        <Toolbar>
          <img src={logo} alt="logo" width="100" height="auto" />
        </Toolbar>
      </AppBar>
      {base64.length === 0 ? (
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <CircularProgress />
        </div>
      ) : (
        <div align="center" style={{ marginTop: "12%" }}>

          <ControlPanel
            scale={scale}
            setScale={setScale}
            numPages={numPages}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            file={`data: application / pdf; base64, ${base64} `}
          />
          <Document
            error="Error al intentar cargar el PDF"
            loading="Cargando..."
            file={`data: application / pdf; base64, ${base64} `}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page
              scale={scale}
              height={pdfHeigth}
              width={pdfWidth}
              renderTextLayer={false}
              pageNumber={pageNumber}
            />
          </Document>

          <Button
            size="sm"
            color="info"
            type="button"
            onClick={clickDownload}>
            <CloudDownloadIcon />
          </Button>
        </div>
      )}

      {/*AVISO DE PRIVACIDAD*/}
      <Dialog
        open={openPrivacidad}
        TransitionComponent={Transition}
        keepMounted
        //onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Aviso de privacidad"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Typography>
              <div>
                <div>
                  Al ingresar al servicio de firma electrónica se entenderá
                  que estás de acuerdo con el Aviso de Privacidad de
                  Certificación Digital Online. Si deseas conocer el Aviso de
                  Privacidad, ingresa a: {""}
                  <a
                    href="https://certificaciondigital.online/avisos.html"
                    target="_blank"
                  >
                    Aviso de privacidad
                  </a>{" "}
                </div>
                <center>
                  <Button
                    size="large"
                    variant="contained"
                    color='success'
                    onClick={() => {
                      //setopenPrivacidad(false);
                      handleClose();
                    }}
                  >
                    Aceptar
                  </Button>
                </center>
              </div>
            </Typography>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      {/*FIN AVISO */}

      <Backdrop className={classes.backdrop} open={success}>
        <Grid>
          <CircularProgress size={44} className={classes.buttonProgress} />
          <br></br>
          <Typography variant="body2" color="textSecondary" align="center">
            Espera un poco
          </Typography>
        </Grid>
      </Backdrop>
    </React.Fragment>
  )

}
