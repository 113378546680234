import React, { useEffect, useState } from "react";
import { DialogComp } from "components/Dialog/DialogComp";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Switch, Tooltip } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { Alert } from "@material-ui/lab";
// import { saveLog, saveLogCapture } from "containers/Logs/funcionesLog.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import GridContainer from "components/Grid/GridContainer";
import Typography from "@material-ui/core/Typography";
import CardHeader from "components/Card/CardHeader";
import SignaturePad from "react-signature-canvas";
import Backdrop from "@material-ui/core/Backdrop";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";
import stylesf from "./styles.module.css";
import Grid from "@material-ui/core/Grid";
import Card from "components/Card/Card";
import PropTypes from "prop-types";
import axios from "axios";
import GridItem from "components/Grid/GridItem";
import DeviceOrientation, { Orientation } from "react-screen-orientation";

// import clsx from "clsx";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "300px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "100",
    },
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#04B9F3",
  },
  root2: {
    flexGrow: 1,
  },
  label: {
    textTransform: "capitalize",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonProgress2: {
    color: green[500],
    alignItems: "center",
    top: "50%",
    left: "50%",
    marginTop: 12,
    marginLeft: 12,
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function PaymentForm({
  // handleNext,
  imageB64,
  tipodoc,
  progrees,
  isLogged,
}) {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const classes = useStyles();

  const [firma, setFirma] = useState(true);
  const [disabledButton, setDisabledButton] = useState(true);
  const [aprobado, setAprobado] = useState(false);
  const [newFirma, setNewFirma] = useState(true);
  const [checkedB, setcheckedB] = useState(false);
  const [showSign, setShowSign] = useState(false);
  const [openDialog, setopenDialog] = useState(false);
  const [celular, setCelular] = useState(false)

  useEffect(() => {
    if (user.my_assets) {
      if (user.my_assets.firma) {
        setcheckedB(true);
      }
    }
    console.log("tipo", tipodoc);
    if (tipodoc !== 8) {
      setDisabledButton(false);
    }
  }, []);

  useEffect(() => {
    checkedB ? setShowSign(true) : setShowSign(false);
  }, [checkedB]);



  useEffect(() => {
    if (isMobile()) {
      setCelular(true)
    }
    ;
  }, []);

  const isMobile = () => {
    return (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/BlackBerry/i)
    );
  };


  const isIphone = () => {
    return (
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/iPad/i)
    );
  };



  const handleValidarProcesoFirma = () => {
    let base64 = firma.getTrimmedCanvas().toDataURL("image/png");
    let baseSplit = base64.split("data:image/png;base64,", 3);
    let base64Length = baseSplit[1];
    if (base64Length.length < 2000) {
      alert(
        "El tamaño de tu firma es muy pequeño, por favor vuelve a intentarlo"
      );
      return false;
    }
    setNewFirma(base64);
    if (isLogged && user.my_assets) {
      setopenDialog(true);
      return false;
    }
    imageB64(base64);
    clear();
  };

  const handleConfirmarGuardarFirma = async (value) => {
    if (value === 1) {
      let token = sessionStorage.getItem("Token");
      const URL = `${process.env.REACT_APP_URL}api/customassets`;
      var FormData = require("form-data");
      var data = new FormData();
      data.append("firma", newFirma);
      var config = {
        method: "post",
        url: URL,
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: " application/json",
        },
        data: data,
      };
      // console.log("calling");
      await guardarFirmaPredeterminada(config);
    }
    imageB64(newFirma);
    setopenDialog(false);
    clear();
  };

  const guardarFirmaPredeterminada = async (config) => {
    axios(config)
      .then(function ({ data }) {
        dispatch({
          type: "SET_SIGN",
          payload: data.firma,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleChangeSwitch = () => {
    setcheckedB(!checkedB);
  };

  const marcaAprobado = () => {
    if (tipodoc === 8) {
      return (
        <Grid item xs={12} sm={12}>
          <label for="casillaVerificacion" style={{ color: "red" }}>
            <input
              id="casillaVerificacion"
              name="casillaVerificacion"
              type="checkBox"
              checked={aprobado}
              onClick={() => console.log("Clicked!")}
              onChange={() => {
                setAprobado((aprobado) => !aprobado);
                setDisabledButton((disabledButton) => !disabledButton);
              }}
            />
            Confirmo que he leído el documento y acepto los términos y
            condiciones
          </label>
        </Grid>
      );
    }
  };

  const clear = () => {
    console.log(firma, "FIRMACLEAR");
    firma.clear();
  };

  return (
    <React.Fragment>
      {isLogged &&
        user.my_assets &&
        (user.my_assets.firma ? (
          <GridContainer>
            <Grid item xs={12}>
              <Typography component="h5" variant="h6" align="center">
                Usar mi firma precargada
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <GridContainer justifyContent="center" spacing={2}>
                <Grid item>No</Grid>
                <Grid item>
                  <Tooltip title="Da click para utilizar tu firma precargada">
                    <Switch
                      disabled={!user.my_assets.firma}
                      checked={checkedB}
                      onChange={handleChangeSwitch}
                      name="checkedB"
                      color="primary"
                    />
                  </Tooltip>
                </Grid>
                <Grid item>Si</Grid>
              </GridContainer>
            </Grid>
          </GridContainer>
        ) : (
          <>
            <Grid item xs={12}>
              <GridContainer justifyContent="center" spacing={2}>
                <Alert severity="info">
                  <strong> {`${user.name} `} </strong>
                  Recuerda que puedes precargar tu firma en el módulo
                  <strong> MI PERFIL</strong>
                </Alert>
              </GridContainer>
            </Grid>
            <br />
          </>
        ))}

      {showSign ? (
        <div className={classes.root2}>
          <GridContainer spacing={3}>
            <GridItem xs={12}>
              <center>
                <img src={user.my_assets.firma} alt="Mi firma" />
              </center>
            </GridItem>
            <GridItem xs={12}>
              {tipodoc === 8 ? marcaAprobado() : null}
            </GridItem>
            <br />
            <br />
            <GridItem xs={12}>
              <Button
                disabled={disabledButton}
                variant="contained"
                color="primary"
                style={{ float: "right", marginTop: "-3%" }}
                classes={{
                  root: classes.root, // class name, e.g. `classes-nesting-root-x`
                }}
                onClick={() => imageB64(user.my_assets.firma)}
              >
                ACEPTAR
              </Button>
            </GridItem>
          </GridContainer>
        </div>
      ) : (
        <Grid container spacing={3}>

          <Grid Item xs={12} sm={12} md={12}>
            <Card className={stylesf.container}>
              <CardHeader color="success">
                <center>
                  <h5 className={styles.cardTitleWhite}>
                    Traza tu firma en el área gris{" "}

                  </h5>
                  {celular == false ? (
                    <ArrowDownwardIcon></ArrowDownwardIcon>
                  ) : ""}
                </center>
              </CardHeader>
              {celular == false ? (
                <div className={stylesf.sigContainer}>
                  <SignaturePad
                    clearOnResize={false}
                    canvasProps={{
                      className: stylesf.sigPad,
                      height: "280%",
                    }}
                    ref={(ref) => {
                      setFirma(ref);
                    }}
                  />
                </div>
              ) : celular == true ? (
                <div>
                  <div className={stylesf.sigContainer}>
                    <DeviceOrientation lockOrientation={"landscape"}>
                      {/* Will only be in DOM in landscape */}
                      <Orientation orientation="landscape" alwaysRender={false}>
                        <SignaturePad
                          clearOnResize={false}
                          canvasProps={{
                            className: stylesf.sigPad,
                            height: "350%",
                          }}
                          ref={(ref) => {
                            setFirma(ref);
                          }}
                        />
                      </Orientation>

                      <Orientation orientation="portrait" alwaysRender={false}>

                        <SignaturePad
                          clearOnResize={false}
                          canvasProps={{
                            className: stylesf.sigPad,
                            height: "500%",
                          }}
                          ref={(ref) => {
                            setFirma(ref);
                          }}
                        />
                      </Orientation>
                    </DeviceOrientation>
                    {/* Will stay in DOM, but is only visible in portrait */}

                  </div>
                  <Grid item xs={12}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      align="center"
                    >
                      {
                        "Si  desea firmar con el celular en posición  horizontal asegúrese de tener la rotación automática activada en su dispositivo "
                      }
                    </Typography>
                    <hr />
                  </Grid>
                </div>
              ) : isIphone() ? (
                <div className={stylesf.sigContainer}>
                  <SignaturePad
                    clearOnResize={false}
                    canvasProps={{
                      className: stylesf.sigPad,
                      height: "550",
                    }}
                    ref={(ref) => {
                      setFirma(ref);
                    }}
                  />
                </div>

              ) : ""}
              {tipodoc === 8 ? marcaAprobado() : null}
            </Card>

            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
            >
              <Button
                onClick={clear}
                color="secondary"
                className={classes.button}
              >
                limpiar
              </Button>
              &nbsp;
              <Button
                variant="contained"
                color="primary"
                disabled={disabledButton}
                classes={{
                  root: classes.root, // class name, e.g. `classes-nesting-root-x`
                }}
                onClick={handleValidarProcesoFirma}
              >
                ACEPTAR
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}

      <DialogComp
        open={openDialog}
        onClose={() => setopenDialog(false)}
        title="Guardar Firma"
        content="¿Desea guardar esta firma como predeterminada?"
        buttons={[
          {
            buttonEvent: () => handleConfirmarGuardarFirma(2),
            buttonName: "No guardar",
            buttonColor: "secondary",
          },
          {
            buttonEvent: () => handleConfirmarGuardarFirma(1),
            buttonName: "Si, guardar",
            buttonColor: "primary",
          },
        ]}
      />
      <Backdrop className={classes.backdrop} open={progrees}>
        <CircularProgress size={44} className={classes.buttonProgress} />
      </Backdrop>
    </React.Fragment>
  );
}

PaymentForm.propTypes = {
  handleNext: PropTypes.func.isRequired,
  imageB64: PropTypes.func.isRequired,
  tipodoc: PropTypes.element.isRequired,
  progrees: PropTypes.element.isRequired,
  isLogged: PropTypes.element.isRequired,
};
