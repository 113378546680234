import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { DialogComp } from "components/Dialog/DialogComp";
import TextField from "@material-ui/core/TextField";

import EditIcon from "@material-ui/icons/Edit";
import MaterialTable from "material-table";
import axios from "axios";
import styles2 from "assets/jss/material-dashboard-react/components/tasksStyle.js";
import Button from "components/CustomButtons/Button.js";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import moment from "moment";

import DeleteIcon from "@material-ui/icons/Delete";

const localization = {
  body: {
    emptyDataSourceMessage: "No hay datos por mostrar",
    addTooltip: "Añadir",
    deleteTooltip: "Eliminar",
    editTooltip: "Editar",
    filterRow: {
      filterTooltip: "Filtrar",
    },
  },
  header: {
    actions: "Acciones",
  },
  pagination: {
    firstAriaLabel: "Primera página",
    firstTooltip: "Primera página",
    labelDisplayedRows: "{from}-{to} de {count}",
    labelRowsPerPage: "Filas por página:",
    labelRowsSelect: "filas",
    lastAriaLabel: "Ultima página",
    lastTooltip: "Ultima página",
    nextAriaLabel: "Pagina siguiente",
    nextTooltip: "Pagina siguiente",
    previousAriaLabel: "Pagina anterior",
    previousTooltip: "Pagina anterior",
  },
  toolbar: {
    exportAriaLabel: "Exportar",
    exportTitle: "Exportar",
    exportCSVName: "Exportar a CVS",
    exportPDFName: "Exportar a PDF",
    searchPlaceholder: "Buscar",
    searchTooltip: "Buscar",
  },
};
const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStylestable = makeStyles(styles2);
const useStyles = makeStyles(styles);

export default function Maps() {
  const classes2 = useStylestable();
  const classes = useStyles();

  const [clases, setClases] = useState([]);
  const [nameclase, setNameClase] = useState("");
  const [OpenName, setOpenName] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [Editclase, setEditClase] = useState("");
  const [idClase, setIdClase] = useState("");
  const [openEdit, setOpenEdit] = useState(false);

  useEffect(() => {
    handleClases();
  }, []);

  const InputName = (event) => {
    setNameClase(event.target.value);
  };

  const handleClases = () => {
    let token = sessionStorage.getItem("Token");
    axios
      .get(`${process.env.REACT_APP_URL}api/clases`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("RESPCORPO", res.data);
        setClases(res.data);
      })
      .catch((error) => {
        console.error(error);
        setClases([]);
      });
  };

  // alert functions

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const EditName = (event) => {
    setEditClase(event.target.value);
  };
  const openAlert = (error) => {
    setOpenSnack(true);
    setMessageError(error);
  };

  const sendDataName = () => {
    const data = new FormData();
    let token = sessionStorage.getItem("Token");

    data.append("name", nameclase);

    var url = `${process.env.REACT_APP_URL}api/clases`;
    var config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer  " + token,
      },
    };
    axios.post(url, data, config, {}).then(
      (response) => {
        var result = response.data;

        handleClases();
        setOpenName(false);
      },
      (error) => {
        let textoerror = JSON.stringify(error.response.data);
        openAlert(textoerror.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ""));
      }
    );
  };

  const handleEditClase = (data) => {
    setEditClase(data.name);
    setOpenEdit(true);
    setIdClase(data._id);
  };

  const sendEditClase = () => {
    let token = sessionStorage.getItem("Token");

    var url = `${process.env.REACT_APP_URL}api/clases/${idClase}?name=${Editclase}`;
    var config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer  " + token,
      },
    };
    axios.put(url, {}, config, {}).then(
      (response) => {
        var result = response.data;

        handleClases();
        setOpenEdit(false);
      },
      (error) => {
        let textoerror = JSON.stringify(error.response.data);
        openAlert(textoerror.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ""));
      }
    );
  };

  const handleDeleteclase = (data) => {
    let urlDeleteDoc = `${process.env.REACT_APP_URL}api/clases/${data._id}`;
    let token = sessionStorage.getItem("Token");

    axios
      .delete(urlDeleteDoc, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {},
        // receive two parameter endpoint url ,form data
      })
      .then((response) => {
        // Respuesta del servidor
        handleClases();
      })
      .catch((error) => {
        let textoerror = JSON.stringify(error.response.data);
        openAlert(textoerror.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ""));
      });
  };

  return (
    <React.Fragment>
      <Button
        onClick={() => {
          setOpenName(true);
        }}
        color="success"
        size="small"
        startIcon={<AddCircleOutlineIcon />}
      >
        Registrar nueva clase de documento
      </Button>
      <MaterialTable
        localization={localization}
        data={clases}
        options={{
          draggable: false,
          pageSize: 20, // make initial page size
          pageSizeOptions: [20, 50, 100],
          actionsColumnIndex: -1,
          exportButton: false,
          search: false,
        }}
        columns={[
          { title: "Nombre", field: "name" },
          {
            title: "Fecha de creación",
            field: "created_at",
            render: (row) => (
              <span>
                {row["created_at"]
                  ? moment(row["created_at"]).format("DD/MM/YYYY")
                  : null}
              </span>
            ),
          },
          { title: "ID", field: "_id" },
        ]}
        title={"Clases de documentos"}
        actions={[
          {
            icon: () => (
              <EditIcon
                className={classes2.tableActionButtonIcon + " " + classes2.edit}
              />
            ),
            tooltip: "Editar",
            onClick: (event, rowData) => {
              handleEditClase(rowData);
            },
          },
          {
            icon: () => (
              <DeleteIcon
                className={classes2.tableActionButtonIcon + " " + classes2.edit}
              />
            ),
            tooltip: "Eliminar Clase",
            onClick: (event, rowData) => {
              handleDeleteclase(rowData);
            },
          },
        ]}
      />

      <DialogComp
        open={OpenName}
        onClose={() => setOpenName(false)}
        maxWidth={"sm"}
        fullWidth={false}
        title="Agregar clase de documento"
        content={
          <Grid container spacing={2}>
            <Grid item xs={3} sm={12}>
              <TextField
                required
                id="nameclase "
                name="nameclase"
                label="Nombre "
                value={nameclase}
                fullWidth
                autoComplete="name"
                onChange={InputName}
                inputProps={{ maxLength: 30 }}
              />
            </Grid>
          </Grid>
        }
        buttons={[
          {
            buttonEvent: () => sendDataName(),
            buttonName: "Aceptar",
            buttonColor: "success",
          },
        ]}
      />
      <DialogComp
        open={openEdit}
        maxWidth={"sm"}
        fullWidth={false}
        title="Editar clase de documento"
        content={
          <Grid container spacing={2}>
            <Grid item xs={3} sm={12}>
              <TextField
                required
                id="nameclase "
                name="nameclase"
                label="Nombre "
                value={Editclase}
                fullWidth
                autoComplete="name"
                onChange={EditName}
                inputProps={{ maxLength: 30 }}
              />
            </Grid>
          </Grid>
        }
        buttons={[
          {
            buttonEvent: () => sendEditClase(),
            buttonName: "Aceptar",
            buttonColor: "success",
          },
        ]}
      />
      <div className={classes.root}>
        <Snackbar
          open={openSnack}
          autoHideDuration={9000}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          onClose={handleCloseSnack}
        >
          <Alert onClose={handleCloseSnack} severity="error">
            {messageError}
          </Alert>
        </Snackbar>
      </div>
    </React.Fragment>
  );
}
