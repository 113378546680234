import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import clsx from "clsx";
import Check from "@material-ui/icons/Check";
import { useHistory } from "react-router-dom";
// import CssBaseline from "@material-ui/core/CssBaseline";
// import AppBar from "@material-ui/core/AppBar";
// import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import ButtonC from "components/CustomButtons/Button.js";

import PublishIcon from "@material-ui/icons/Publish";
import Typography from "@material-ui/core/Typography";
import FormFirmantes from "containers/vistoBueno/formulariovistoBueno";
// import Previewpdf from "./PreviewPDF/PreviewPDF";
import AddressFormDocument from "containers/vistoBueno/DocumentVistoBueno";
import mensaje from "assets/img/mensaje.png";
import Slide from "@material-ui/core/Slide";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";
import StepConnector from "@material-ui/core/StepConnector";
import SettingsIcon from "@material-ui/icons/Settings";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import VideoLabelIcon from "@material-ui/icons/VideoLabel";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

//app bar para aviso de actualizacion
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
//*libreria para reproducir video
//import ReactPlayer from "react-player";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  completed: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage:
      " linear-gradient(79deg, rgba(76,175,80,1) 0%, rgba(102,187,106,1) 63%, rgba(92,184,96,1) 100%)",
  },
});

const useStylespro = makeStyles(styles);
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginTop: theme.spacing(-9),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(1) * 1)]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  appBar: {
    top: "auto",
    position: "absolute",
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
  paper: {
    // marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    borderRadius: "5px",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CheckoutNew(props) {
  const ref = React.createRef();
  function goTop() {
    ref.current.scrollIntoView();
  }
  function handleGoBackHome() {
    const Location = window.location.href;
    let uri = localStorage.getItem("uri");

    if (
      Location == `http://localhost:3000/${uri}/dashboard` ||
      Location == `https://frontdev.con-certeza.mx/${uri}/dashboard` ||
      Location == ` https://con-certeza.mx/${uri}/dashboard`
    ) {
      setOpenForm(0);
      console.log("entrovalidacion");
    } else {
      history.push(`/${uri}/dashboard`);
      console.log("no entro");
    }
  }
  const history = useHistory();
  const [tokenstate, setTokenstate] = React.useState("");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [idFlow, setIFLow] = React.useState(false);
  const [openDelete, setopenDelete] = React.useState(false);
  const [Register, setRegister] = React.useState(false);
  const [openGuardado, setOpenGuardado] = React.useState(false);
  const handleCloseGuardado = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenGuardado(false);
  };

  const mainPanel = React.createRef();
  const classespro = useStylespro();
  const {
    tabs,
    idDocumentotable,
    idprocces,
    idDocumentoDetenido,
    setOpenForm,
    setActiveRender,
  } = props;
  // let { id } = useParams();

  const [idRequired, setIdRequired] = React.useState(false);
  const [asistido, setAsistido] = React.useState(false);

  const [descripcion, setDescripcion] = React.useState("");
  const [inicio, setInicio] = React.useState("");
  const [termino, setTermino] = React.useState("");
  const [diasAlert, setDiasAlert] = React.useState(0);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    setidDocumento("");
    let token = sessionStorage.getItem("Token");

    setTokenstate(token);

    if (tabs == "1") {
      if (idDocumentoDetenido) {
        setidDocumento(idDocumentoDetenido);
        setRegister(true);

        handleDialog();
      } else {
        handleDialog();
        setidDocumento(idDocumentotable);
        setRegister(true);
      }
    } else if (idprocces == "1") {
      setIFLow(true);

      console.log("TABS2", idprocces);
      setIdRequired(true);
    }
  }, [idDocumentotable]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // console.log("tokenLOCAL STORAGE CHECKOU", tokenstate);
  const classes = useStyles();

  const [activeStep, setActiveStep] = React.useState(0);
  const [idDocumento, setidDocumento] = React.useState("");
  const [openSnack, setOpenSnack] = React.useState(false);
  const [messageError, setMessageError] = React.useState("");
  const [progress, setprogress] = React.useState(false);
  const [widthstate, setWidth] = React.useState(600);

  const steps = ["Carga", "Registra"];

  function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    const icons = {
      1: <PublishIcon />,
      2: <GroupAddIcon />,
      3: <VideoLabelIcon />,
    };

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        {completed ? <Check /> : icons[String(props.icon)]}
      </div>
    );
  }

  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
  };
  function onChangeValue(event) {
    setIdRequired(event.target.value);
  }

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };
  const handleDialog = () => {
    setActiveStep(1);
  };

  const senFirmante = () => {
    setTimeout(handleNext, 800);
  };

  const sendDocument = () => {
    setTimeout(handleNext, 500);
  };

  // alert functions

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const openAlert = (error) => {
    setOpenSnack(true);
    setMessageError(error);
  };

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${tokenstate}`,
      Accept: " application/json",
      type: "formData",
    },
  };

  function saveMetadata(idDoc) {
    console.log("SAVE METADATA");
    let token = sessionStorage.getItem("Token");
    let endpoint = `${process.env.REACT_APP_URL}api/documentos/${idDoc}?description=${descripcion}&meta[inicio]=${inicio}&meta[termino]=${termino}`;
    console.log(endpoint);
    /*
    let endpoint="";
    if(descripcion!==""){
      endpoint =`${process.env.REACT_APP_URL}api/documentos/${idDoc}?description=${descripcion}&meta[inicio]=${inicio}&meta[termino]=${termino}`;
    }
    else{
      endpoint =`${process.env.REACT_APP_URL}api/documentos/${idDoc}?meta[inicio]=${inicio}&meta[termino]=${termino}`;
    }
    */

    var config = {
      method: "PUT",
      url: endpoint,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(config)
      .then(function (response) {
        console.log("METADATA ACTUALIZADA:", response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const onChangePDF = async (event) => {
    const data = new FormData();
    setprogress(true);
    var file = document.querySelector('input[type="file"]').files[0];
    data.append("filename", file);
    var valorasistido;
    asistido ? (valorasistido = 1) : (valorasistido = 0);
    data.append("asistido", valorasistido);
    if (idRequired === true) {
      data.append("documento_types_id", 2);
    }
    if (idprocces === 2) {
      data.append("documento_types_id", 8);
    }
    if (descripcion !== "") {
      data.append("description", descripcion);
    }

    // if (inicio !== "" || termino !== "") {
    //   data.append("meta[vigencia_start]", inicio + "00:00:00");
    //   data.append("meta[vigencia_end]", termino + "23:59:59");
    // }

    if (inicio !== "") {
      data.append("meta[vigencia_start]", inicio + "00:00:00");
    }
    if (termino !== "") {
      data.append("meta[vigencia_end]", termino + "23:59:59");
    }
    if (diasAlert > 0) {
      data.append("meta[dias_alerta]", diasAlert);
    }
    console.log("fiLE NEW", data);
    let url = `${process.env.REACT_APP_URL}api/documentos`;

    axios
      .post(url, data, config, {
        // receive two parameter endpoint url ,form data
      })
      .then((response) => {
        // Respuesta del servidor
        let data = response.data[0];
        setidDocumento(data.id);
        sendDocument();
        setprogress(false);
        //saveMetadata(data.id);
        //muestra mensaje autoguardado
        setOpenGuardado(true);
      })
      .catch((error) => {
        // Error 😨
        if (error.response.data.errors) {
          //openAlert("El archivo debe ser de tipo PDF sin ninguna encriptacion");
          let textoerror = JSON.stringify(error.response.data.errors);
          openAlert(textoerror.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ""));
        } else if (error.response.data.message) {
          openAlert(error.response.data.message);
        } else {
          openAlert("Su documento tiene algun problema");
        }

        setprogress(false);
      });
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <AddressFormDocument
            onChangePDF={onChangePDF}
            progress={progress}
            setAsistido={setAsistido}
            asistido={asistido}
            setDescripcion={setDescripcion}
            setInicio={setInicio}
            setTermino={setTermino}
            setDiasAlert={setDiasAlert}
            setActiveRender={setActiveRender}
          />
        );
      case 1:
        return (
          <FormFirmantes
            idDocumento={idDocumento}
            senFirmante={senFirmante}
            handleDelete={handleDelete}
            idRequired={idRequired}
            setIdRequired={setIdRequired}
            Register={Register}
            idprocces={idprocces}
            idDocumentoDetenido={idDocumentoDetenido}
          />
        );

      default:
        throw new Error("Unknown step");
    }
  };

  const dialogDelete = () => {
    setopenDelete(true);
  };

  const handleDelete = () => {
    goTop();
    let urlDeleteDoc = `${process.env.REACT_APP_URL}api/documentos/${idDocumento}`;
    console.log("URL DELETE");
    axios
      .delete(urlDeleteDoc, {
        headers: {
          Authorization: `Bearer ${tokenstate}`,
        },
        data: {},
        // receive two parameter endpoint url ,form data
      })
      .then((response) => {
        // Respuesta del servidor
        setopenDelete(false);
        console.log("respuest a data", response);
        setActiveStep(0);
      })
      .catch((error) => {
        // Error 😨
        if (error.response) {
          console.log(error.response.data.message);
          openAlert(error.response.data.message);
        } else if (error.request) {
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  if (activeStep === 1 && widthstate === 600) {
    setWidth(1000);
  }
  if (activeStep === 0 && widthstate === 1000) {
    setWidth(600);
  }

  return (
    <React.Fragment>
      {activeStep == 0 ? (
        <Button
          size="large"
          style={{ marginTop: "-9px" }}
          startIcon={<ArrowBackIcon />}
          onClick={() => setActiveRender(0)}
        >
          Regresar
        </Button>
      ) : (
        ""
      )}
      <main className={classes.layout} style={{ width: widthstate }} ref={ref}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h5" align="center">
            VISTO BUENO
          </Typography>
          <Stepper
            activeStep={activeStep}
            className={classes.stepper}
            connector={<ColorlibConnector />}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography
                  variant="h6"
                  gutterBottom
                  align="center"
                  style={{ textTransform: "none" }}
                >
                  Creación de proceso de firma exitoso, se notificará a los
                  involucrados.
                </Typography>

                <div align="center">
                  <img src={mensaje} alt="logo" width="260" height="220" />
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep)}
                {activeStep === steps.length - 1 ? (
                  <div className={classes.buttons}>
                    <ButtonC
                      color="info"
                      size="md"
                      type="button"
                      onClick={() => {
                        handleGoBackHome();
                      }}
                      className={classes.button}
                    >
                      Guardar en Registrados
                    </ButtonC>
                    <ButtonC
                      color="danger"
                      size="md"
                      type="button"
                      onClick={dialogDelete}
                      className={classes.button}
                    >
                      Eliminar
                    </ButtonC>
                  </div>
                ) : activeStep === steps.length - 2 ? (
                  ""
                ) : (
                  <React.Fragment>
                    <div className={classes.buttons}>
                      {activeStep !== 0 && (
                        <Button
                          onClick={handleDelete}
                          className={classes.button}
                          color="secondary"
                        >
                          <Typography
                            variant="button"
                            style={{ textTransform: "none" }}
                          >
                            Eliminar
                          </Typography>
                        </Button>
                      )}
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className={classes.button}
                      >
                        {activeStep === steps.length - 1 ? (
                          "Mandar a firma"
                        ) : activeStep === steps.length - 2 ? (
                          <Typography
                            variant="button"
                            style={{ textTransform: "none" }}
                          >
                            Registrar firmantes
                          </Typography>
                        ) : activeStep === steps.length - 3 ? (
                          "Ver documento"
                        ) : (
                          "siguiente"
                        )}
                      </Button>
                    </div>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
      </main>

      <div className={classes.root}>
        <Snackbar
          open={openSnack}
          autoHideDuration={9000}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          onClose={handleCloseSnack}
        >
          <Alert onClose={handleCloseSnack} severity="error">
            {messageError}
          </Alert>
        </Snackbar>
      </div>
      <div>
        <Dialog
          // fullWidth={true}
          // maxWidth="md"

          open={openDelete}
          TransitionComponent={Transition}
          keepMounted
          // onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Eliminar documento"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <Typography variant="subtitle1" style={{ textTransform: "none" }}>
                ¿Está seguro de eliminar este documento?
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setopenDelete(false)}>Cerrar</Button>
            <Button onClick={handleDelete} color="secondary">
              Eliminar
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={openGuardado}
          autoHideDuration={4000}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          onClose={handleCloseGuardado}
        >
          <Alert onClose={handleCloseGuardado} severity="info">
            Documento Guardado en Registrados
          </Alert>
        </Snackbar>
      </div>
    </React.Fragment>
  );
}
