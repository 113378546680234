import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import axios from "axios";

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import ControlPanel from "components/controlPanel/ControlPanel";

import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStylesprogrees = makeStyles((theme) => ({
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonProgress2: {
    color: green[500],
    alignItems: "center",
    top: "50%",
    left: "50%",
    marginTop: 12,
    marginLeft: 12,
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function Preview(props) {
  const classespro = useStylesprogrees();
  const { idDocumento, handleDelete } = props;
  const [open, setOpen] = React.useState(true);
  const [base64, setbase64] = React.useState("");
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pdfHeigth, setpdfHeigth] = React.useState(475);
  const [pdfWidth, setpdfWidth] = React.useState(550);
  const [scale, setScale] = React.useState(1.0);

  React.useEffect(() => {
    if (idDocumento !== "") {
      HandleDocuBase64();
    }
    if (isMobile()) {
      setpdfHeigth(500);
      setpdfWidth(290);
    }
  }, [idDocumento]);

  const HandleDocuBase64 = () => {
    const token = sessionStorage.signToken;

    axios
      .get(
        `${process.env.REACT_APP_URL}api/documentos/${idDocumento}/download`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setbase64(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const isMobile = () => {
    return (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/BlackBerry/i)
    );
  };

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  var areYouReallySure = false;
  function areYouSure() {
    if (allowPrompt) {
      if (!areYouReallySure && true) {
        areYouReallySure = true;
        var confMessage = "Por favor termine de registrar el firmante ";

        return confMessage;
      }
    } else {
      allowPrompt = true;
    }
  }

  var allowPrompt = true;

  window.onbeforeunload = areYouSure;
  return (
    <div>
      {base64.length === 0 ? (
        <Backdrop className={classespro.backdrop} open={open}>
          <CircularProgress size={44} className={classespro.buttonProgress} />
        </Backdrop>
      ) : (
        <React.Fragment>
          <Grid
            container
            spacing={3}
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Typography variant="h6" gutterBottom>
              vista previa
            </Typography>
            <div>
              <br></br>
            </div>
          </Grid>
          <div>
            <ControlPanel
              scale={scale}
              setScale={setScale}
              numPages={numPages}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              file={`data: application / pdf; base64, ${base64} `}
            />
            <Document
              error="Error al intentar cargar el PDF"
              loading="Cargando..."
              file={`data: application / pdf; base64, ${base64} `}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page
                scale={scale}
                height={pdfHeigth}
                width={pdfWidth}
                renderTextLayer={false}
                pageNumber={pageNumber}
              />
            </Document>
            <div className="text-center">
              <p>
                Página {pageNumber || (numPages ? 1 : "--")} de{" "}
                {numPages || "--"}
              </p>
              <button
                className="btn btn-dark"
                type="button"
                disabled={pageNumber <= 1}
                onClick={previousPage}
              >
                Atrás
              </button>{" "}
              <button
                className="btn btn-dark"
                type="button"
                disabled={pageNumber >= numPages}
                onClick={nextPage}
              >
                Siguiente
              </button>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}
