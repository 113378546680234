import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import Card from "components/Card/Card";
import Button from "@material-ui/core/Button";

import CardHeader from "components/Card/CardHeader";
import SignaturePad from "react-signature-canvas";
import axios from "axios";
import {
  BrowserRouter as Router,
  useParams,
  useHistory,
} from "react-router-dom";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import Hidden from "@material-ui/core/Hidden";
import clsx from "clsx";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import { saveLog, saveLogCapture } from "containers/Logs/funcionesLog.js";
import { makeStyles } from "@material-ui/core/styles";

import stylesf from "./styles.module.css";
import { useDispatch, useSelector } from "react-redux";
import { FormLabel, Switch, Tooltip } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import { Alert, AlertTitle } from "@material-ui/lab";
import { DialogComp } from "components/Dialog/DialogComp";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "300px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "100",
    },
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#04B9F3",
  },
  label: {
    textTransform: "capitalize",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonProgress2: {
    color: green[500],
    alignItems: "center",
    top: "50%",
    left: "50%",
    marginTop: 12,
    marginLeft: 12,
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function PaymentForm(props) {
  const { handleNext, imageB64, progrees } = props;
  const { my_assets } = useSelector((state) => state.auth.user);
  //id=idDocumento idfirmante=idFirmante
  const classes = useStyles();
  let { idDoc, idfirmante } = useParams();
  const [firma, setFirma] = useState(true);
  const [newFirma, setNewFirma] = useState(true);
  const [checkedB, setcheckedB] = useState(
    my_assets && my_assets.firma ? true : false
  );
  const [showSign, setShowSign] = useState(false);
  const [openDialog, setopenDialog] = useState(false);

  useEffect(() => {
    if (checkedB) {
      setShowSign(true);
    } else {
      setShowSign(false);
    }
  }, [checkedB]);

  const history = useHistory();

  const dispatch = useDispatch();

  const clear = () => {
    firma.clear();
  };

  const url = `${process.env.REACT_APP_URL}api/firmas`;


  const handleValidarProcesoFirma = () => {
    let base64 = firma.getTrimmedCanvas().toDataURL("image/png");
    let base64Split = base64.split("data:image/png;base64,", 3);
    let base64Length = base64Split[1];
    if (base64Length.length < 2000) {
      alert(
        "El tamaño de tu firma es muy pequeño, por favor vuelve a intentarlo"
      );
      return false;
    }
    setNewFirma(base64);
    setopenDialog(true);
  };

  const handleChangeSwitch = () => {
    setcheckedB(!checkedB);
  };

  const guardarFirmaPredeterminada = async (config) => {
    axios(config)
      .then(function ({ data }) {
        dispatch({
          type: "SET_SIGN",
          payload: data.firma,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleConfirmarGuardarFirma = async (value) => {
    if (value === 1) {
      let token = sessionStorage.getItem("Token");
      const URL = `${process.env.REACT_APP_URL}api/customassets`;
      var FormData = require("form-data");
      var data = new FormData();
      data.append("firma", newFirma);
      var config = {
        method: "post",
        url: URL,
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: " application/json",
        },
        data: data,
      };
      // console.log("calling");
      await guardarFirmaPredeterminada(config);
    }
    // console.log("Continua con el flujo");
    imageB64(newFirma);
    setopenDialog(false);
    clear();
  };

  return (
    <React.Fragment>
      {my_assets && my_assets.firma ? (
        <GridContainer>
          <Grid item xs={12}>
            <Typography component="h5" variant="h6" align="center">
              Usar mi firma precargada
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <GridContainer justifyContent="center" spacing={2}>
              <Grid item>No</Grid>
              <Grid item>
                <Tooltip title="Da click para utilizar tu firma precargada">
                  <Switch
                    disabled={!my_assets.firma}
                    checked={checkedB}
                    onChange={handleChangeSwitch}
                    name="checkedB"
                    color="primary"
                  />
                </Tooltip>
              </Grid>
              <Grid item>Si</Grid>
            </GridContainer>
          </Grid>
        </GridContainer>
      ) : (
        <>
          <Grid item xs={12}>
            <GridContainer justifyContent="center" spacing={2}>
              <Alert severity="info">
                Recuerda que puedes precargar tu firma en el módulo
                <strong> MI PERFIL</strong>
              </Alert>
            </GridContainer>
          </Grid>
          <br />
        </>
      )}
      {showSign ? (
        <>
          <center>
            <img src={my_assets.firma} alt="Mi firma" />
          </center>
          <Button
            variant="contained"
            color="primary"
            style={{ float: "right", marginTop: "-3%" }}
            classes={{
              root: classes.root, // class name, e.g. `classes-nesting-root-x`
            }}
            onClick={() => imageB64(my_assets.firma)}
          >
            ACEPTAR
          </Button>
        </>
      ) : (
        <Grid container spacing={3}>
          <Grid Item xs={12} sm={12} md={12}>
            <Card className={stylesf.container}>
              <CardHeader color="success">
                <center>
                  <h5 className={styles.cardTitleWhite}>
                    Traza tu firma en el área gris{" "}
                  </h5>
                  <ArrowDownwardIcon></ArrowDownwardIcon>
                </center>
              </CardHeader>
              <Hidden only={["sm", "xs"]}>
                <div className={stylesf.sigContainer}>
                  <SignaturePad
                    canvasProps={{ className: stylesf.sigPad, height: "280%" }}
                    ref={(ref) => {
                      setFirma(ref);
                    }}
                  />
                </div>
              </Hidden>
              <Hidden only={["lg", "md"]}>
                <div className={stylesf.sigContainer}>
                  <SignaturePad
                    canvasProps={{ className: stylesf.sigPad, height: "760%" }}
                    ref={(ref) => {
                      setFirma(ref);
                    }}
                  />
                </div>
                <Grid item xs={12}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    align="center"
                  >
                    {
                      "Si  desea firmar con el celular en posicion  horizontal asegúrese de tener la rotación automática activada en su dispositivo "
                    }
                  </Typography>
                </Grid>
              </Hidden>
            </Card>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
            >
              <Button
                onClick={clear}
                color="secondary"
                className={classes.button}
              >
                limpiar
              </Button>
              &nbsp;
              <Button
                variant="contained"
                color="primary"
                classes={{
                  root: classes.root, // class name, e.g. `classes-nesting-root-x`
                }}
                onClick={handleValidarProcesoFirma}
              >
                ACEPTAR
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}

      <DialogComp
        open={openDialog}
        onClose={() => setopenDialog(false)}
        title="Guardar Firma"
        content="¿Desea guardar esta firma como predeterminada?"
        buttons={[
          {
            buttonEvent: () => handleConfirmarGuardarFirma(2),
            buttonName: "No guardar",
            buttonColor: "secondary",
          },
          {
            buttonEvent: () => handleConfirmarGuardarFirma(1),
            buttonName: "Si, guardar",
            buttonColor: "primary",
          },
        ]}
      />

      <Backdrop className={classes.backdrop} open={progrees}>
        <CircularProgress size={44} className={classes.buttonProgress} />
      </Backdrop>
    </React.Fragment>
  );
}
