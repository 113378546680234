import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
//imput
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import axios from "axios";
import clsx from "clsx";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import MenuItem from "@material-ui/core/MenuItem"
import InputLabel from "@material-ui/core/InputLabel"

import Select from "@material-ui/core/Select"
import FormControl from "@material-ui/core/FormControl"
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  input: {
    display: "none",
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: "250px"
    ,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const useStylesprogrees = makeStyles((theme) => ({
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonProgress2: {
    color: green[500],
    alignItems: "center",
    top: "50%",
    left: "50%",
    marginTop: 12,
    marginLeft: 12,
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
export default function ChangeDocument(props) {
  const { onChangePDF, progress, setAsistido, asistido } = props;
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [user, setUser] = React.useState("");
  const timer = React.useRef();
  const classespro = useStylesprogrees();
  const [idClases, setIdClases] = React.useState("")
  const [selectClases, setSelectClases] = React.useState([])

  const [tipoClases, setTipoClases] = React.useState({
    codigo: "Selecciona la clase de documento ",
    name: "Selecciona la clase de documento ",
  })

  React.useEffect(() => {
    handleclases();

    getUser();
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  function handleclases() {
    let token = sessionStorage.getItem("Token");
    axios
      .get(`${process.env.REACT_APP_URL}api/clases`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("RESPCORPO", res.data);
        var RESPCORPO = res.data;
        var collection = []
        RESPCORPO.map(function (data) {
          var clasesItem = {
            codigo: data._id,
            name: data.name,
          }
          collection.push(clasesItem)
        })
        console.log("COLLECTION", collection)
        setSelectClases(collection)
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const getUser = () => {
    let token = sessionStorage.getItem("Token");
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_URL}api/auth/user`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(config)
      .then(function (response) {
        //console.log("User:",response.data);
        setUser(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const handlechangeClases = (event) => {

    console.log("idclases", event.target.value)
    setIdClases(event.target.value)
    let datal = {
      codigo: event.target.value,
      name: event.target.value,
    }
    setTipoClases(datal)
  }


  const onChange = () => {
    onChangePDF(idClases);

    if (!loading) {
      setSuccess(false);
      setLoading(true);
      timer.current = setTimeout(() => {
        setSuccess(true);
        setLoading(false);
      }, 4000);
    }
  };

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  //const personalizar = () => setAsistido(asistido => !asistido);

  const marcaAsistido = () => {
    if (user.permissions.search("stats.cdo") >= 0) {
      return (
        <Grid item xs={12} sm={12}>
          <label htmlFor="contained-button-file">
            <input
              type="checkBox"
              checked={asistido}
              onClick={() => console.log("Clicked!")}
              onChange={() => setAsistido((asistido) => !asistido)}
            />
            Es un proceso asistido
          </label>
        </Grid>
      );
    }
  };

  return (
    <React.Fragment>
      <Grid
        container
        spacing={1}
        direction="column"
        justify="center"
        alignItems="center"
      >
        <center>
          {selectClases.length > 1 ? (
            <FormControl className={classes.formControl}  >
              <InputLabel id="clase">Clases de documento</InputLabel>

              <Select
                labelId="clase"
                id="clase"
                value={tipoClases.codigo}
                onChange={handlechangeClases}
              >
                <MenuItem value="Selecciona la clase de documento " disabled>
                  Selecciona  la clase de documento
                </MenuItem>
                {selectClases.map(({ codigo, name }, index) => (
                  <MenuItem key={index} value={codigo}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : ""}
        </center>
        <br></br>
      </Grid>
      <Grid
        container
        spacing={3}
        direction="column"
        justify="center"
        alignItems="center"
      >

        <Grid item xs={12} sm={12}>
          <input
            accept="pdf/*"
            className={classes.input}
            id="contained-button-file"
            multiple
            type="file"
            name="PDF"
            onChange={onChange}
          />
          <label htmlFor="contained-button-file">
            <Button
              variant="contained"
              color="primary"
              component="span"
              disabled={loading}
              className={buttonClassname}
            >
              <Typography variant="h6" style={{ textTransform: "none" }}>
                CARGA DOCUMENTO PDF
              </Typography>
            </Button>
          </label>
        </Grid>
        <div>
          <br></br>
          <br></br>
        </div>
      </Grid>

      <Backdrop className={classespro.backdrop} open={progress}>
        <CircularProgress size={44} className={classespro.buttonProgress} />
      </Backdrop>
    </React.Fragment>
  );
}
