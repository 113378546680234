import React from "react";
import axios from "axios";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Typography from "@material-ui/core/Typography";
import TextField from '@material-ui/core/TextField';
import SortByAlphaIcon from '@material-ui/icons/SortByAlpha';
import SearchIcon from '@material-ui/icons/Search';
import AppBar from '@material-ui/core/AppBar';
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import EditIcon from '@material-ui/icons/Edit';
import PostAddIcon from '@material-ui/icons/PostAdd';
import DeleteIcon from '@material-ui/icons/Delete';
import WebAssetIcon from '@material-ui/icons/WebAsset';
import QueueIcon from '@material-ui/icons/Queue';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

import ReactFlow2, {
  removeElements,
  addEdge,
  Controls,
  Handle
} from "react-flow-renderer";

const InputNode = ({ type, data, id }) => {
  const classes = useStyles();
  function nombreGrupo(idgrupo) {
    let nombre;
    if (data.lista_grupos) {
      nombre = data.lista_grupos.find(item => item._id == idgrupo);
      if (nombre)
        return (nombre.name);
    }
    return (null);
  }
  function nombreCatalogo(idcatalogo) {
    let nombre;
    if (data.lista_catalogos) {
      nombre = data.lista_catalogos.find(item => item._id == idcatalogo);
      if (nombre)
        return (nombre.name);
    }
    return (null);
  }
  return (
    <>
      <Handle type="target" position="left" />
      {/*
      type: {type}
      <br />
      */}
      <p className={classes.p}><font size="1"><b>Proceso:</b></font></p>
      <p className={classes.p}><font size="1">{data.value}</font></p>
      <hr />
      <p className={classes.p}><font size="1"><b>Grupo responsable:</b></font></p>
      <p className={classes.p}><font size="1">{nombreGrupo(data.workgroup_id)}</font></p>
      <p className={classes.p}>&nbsp;</p>
      <p className={classes.p}></p>
      <p className={classes.p}><font size="1"><b>Catálogos:</b></font></p>
      {data.catalogos && data.catalogos.map((item, key) => (
        <p key={key} className={classes.p}><font size="1"><b>-</b>{nombreCatalogo(item)}</font></p>
      ))}
      <Handle type="source" position="right" id="a" />
    </>
  );
};



const nodeTypes = {
  InputNode: InputNode,
  //TextAreaNode: TextAreaNode,
  //BooleanNode: BooleanNode,
  //Input2Node: Input2Node
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
  botondanger: {
    backgroundColor: "red",
    color: "white"
  },
  botongreen: {
    backgroundColor: "green",
    color: "white"
  },
  botonwhite: {
    backgroundColor: "white",
    color: "gray"
  },
  iconsmall: {
    fontSize: 16,
    margin: 2,
    marginTop: 11, //15 center
    padding: 0,
  },
  iconsmall2: {
    fontSize: 8,
    margin: 2,
    //marginTop:15, //15 center
    //padding:0,
    cursor: "default"
  },
  nombrecatalogo: {
    fontSize: 16,
    marginTop: 7,
    marginLeft: 10,
  },
  nombrearchivo: {
    marginTop: 6,
    fontSize: 14,
  },
  iconarchivo: {
    marginTop: 0,
    fontSize: 10,
  },
  paper: {
    width: "95%",
  },
  colapse: {
    marginLeft: 20
  },
  p: {
    width: "90%",
    margin: "0",
    lineHeight: "0.7"
  },

  formControl: {
    margin: theme.spacing(1),
    //minWidth: 120,
    width: "100%"
  },


}));

export default function Flujos() {
  const classes = useStyles();
  const [modalNuevo, setModalNuevo] = React.useState(false);
  const [step, setStep] = React.useState(0);
  const [nombreFlujo, setNombreFlujo] = React.useState("");
  const [flujos, setFlujos] = React.useState("");
  const [flujo, setFlujo] = React.useState("");
  const [nombreFlujoModificar, setNombreFlujoModificar] = React.useState("");
  const [flujoModificar, setFlujoModificar] = React.useState("");
  const [modalModificar, setModalModificar] = React.useState(false);
  const [modalEliminar, setModalEliminar] = React.useState(false);
  const [flujoEliminar, setFlujoEliminar] = React.useState("");
  const [modalAgregar, setModalAgregar] = React.useState(false);
  const [flujoGrafica, setFlujoGrafica] = React.useState("");
  const [modalVisualizar, setModalVisualizar] = React.useState(false);
  const [grupos, setGrupos] = React.useState("");
  const [catalogos, setCatalogos] = React.useState("");
  const [modalEditar, setModalEditar] = React.useState(false);
  const [idGrupo, setIdGrupo] = React.useState("");
  const [idCatalogo, setIdCatalogo] = React.useState("");
  const [nodosFlujo, setNodosFlujo] = React.useState("");
  const [modalConfirmar, setModalConfirmar] = React.useState(false);
  const [order, setOrder] = React.useState("");

  {/*ReactFlow*/ }
  const [elements, setElements] = React.useState([]);
  const [objectEdit, setObjectEdit] = React.useState({});
  const onElementClick = (event, object) => {
    console.log(object);
    if (object.type == "InputNode") {
      setIdGrupo(object.data.workgroup_id)
      setObjectEdit(object);
      setModalEditar(true);
    }
  };

  const onLoad = (reactFlowInstance) => {
    console.log('flow loaded:', reactFlowInstance);
    reactFlowInstance.fitView();
  };

  const onElementsRemove = (elementsToRemove) =>
    setElements((els) => removeElements(elementsToRemove, els));

  const onConnect = (params) =>
    setElements((els) => {
      return addEdge({ ...params, arrowHeadType: "arrowclosed" }, els);
    });
  const onPaneClick = () => {
    setObjectEdit({});
  };
  const initialInputFull = (grupos, catalogos) => {
    return ({
      type: "InputNode",
      data: { value: "", workgroup_id: "", catalogos: [], lista_grupos: grupos, lista_catalogos: catalogos },
      style: { border: "1px solid #777", padding: 10, width: 150 },
      position: { x: 250, y: 25 }
    });
  }

  async function save(nodo) {
    console.log("nodo en save", nodo);
    var dataForm = new FormData();
    dataForm.append('flow_id', nodo.flow_id);
    dataForm.append('workgroup_id', nodo.workgroup_id);
    dataForm.append('name', nodo.name);
    dataForm.append('step_id', nodo.step_id);
    dataForm.append('prev_step', nodo.prev_step);
    if (nodo.catalogos) {
      for (var i = 0; i < nodo.catalogos.length; i++) {
        dataForm.append('catalogos[]', nodo.catalogos[i]);
      }
    }


    let token = sessionStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;
    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_URL}api/flownodes`,
      headers: {
        'Accept': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: dataForm
    };

    return new Promise(function (resolve, reject) {
      //axios.post(url, data, config, {})
      axios(config)
        .then(
          (response) => {
            var result = response.data;
            console.log("Processing Request");
            resolve(result);
          },
          (error) => {
            reject(error);
            console.log(error);
            alerta("Error al crear nodos", "error")
          }
        );
    });

    /*
          axios(config)
            .then(function (response) {
              console.log("GUARDO:",response.data);
            })
            .catch(function (error) {
              console.log(error);
              alerta("Error al crear nodos","error")
            });
    */
  }

  async function clear() {
    let token = sessionStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;
    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_URL}api/flownodes/clear/${flujoModificar._id}`,
      headers: {
        'Accept': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    return new Promise(function (resolve, reject) {
      //axios.post(url, data, config, {})
      axios(config)
        .then(
          (response) => {
            var result = response.data;
            console.log("Processing Request");
            resolve(result);
          },
          (error) => {
            reject(error);
          }
        );
    });

  }

  async function handleGuardarNodos(nodosF) {
    console.log("guardar", nodosF);
    //limpiar flujo
    await clear();

    for (var i = 0; i < nodosF.length; i++) {
      //for (var i = nodosF.length-1; i >=0; i--) {
      //console.log(nodosF[i]);
      await save(nodosF[i]);
    }
    await getFlujos();
    setModalVisualizar(false);
    setFlujoModificar("");
    setElements([]);
  }

  const onSubmit = () => {
    console.log("elements", elements);

    var guardar = true;
    var nodos = [];
    for (var i = 0; i < elements.length; i++) {
      if (elements[i].type == "InputNode") {
        var previo = "";
        //validar datos completos
        if (elements[i].data.value == '' || elements[i].data.workgroup_id == '') {
          alerta("Datos de los nodos incompletos", "error");
          guardar = false;
        }
        //fin validar
        //determinar nodos previos
        for (var j = 0; j < elements.length; j++) {
          if (elements[j].target == elements[i].id) {
            previo = elements[j].source;
          }
        }
        //fin determinar
        nodos = [...nodos,
        {
          step_id: elements[i].id,
          name: elements[i].data.value,
          flow_id: flujoModificar._id,
          workgroup_id: elements[i].data.workgroup_id,
          catalogos: elements[i].data.catalogos,
          prev_step: previo
        }
        ]
      }
    }
    console.log("Nodos", nodos);
    if (guardar && nodos.length > 0) {
      setNodosFlujo(nodos);
      handleGuardarNodos(nodos);
    }
    else {
      alerta("El Flujo no contiene nodos", "error");
    }

  };

  {/*FIN ReactFlow*/ }
  const [openMensaje, setOpenMensaje] = React.useState(false);
  const [mensaje, setMensaje] = React.useState(false);
  const [severity, setSeverity] = React.useState("info");
  const handleCloseMensaje = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenMensaje(false);
  };


  function alerta(texto, severidad) {
    setOpenMensaje(true);
    setMensaje(texto);
    setSeverity(severidad);
  }

  async function getFlujos() {
    let token = sessionStorage.getItem("Token")
    //let token = sessionStorage.getItem("Token");
    var config = {
      method: 'get',
      url: `${process.env.REACT_APP_URL}api/flows`,
      headers: {
        'Accept': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    };
    axios(config)
      .then(function (response) {
        console.log("Flujos:", response.data);
        setFlujos(response.data);
      })
      .catch(function (error) {
        console.log(error);
        setFlujos("");
      });
  }

  async function handleGuardarNuevo() {

    var dataForm = new FormData();
    dataForm.append('name', nombreFlujo);
    let token = sessionStorage.getItem("Token")
    //let token = sessionStorage.getItem("Token");
    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_URL}api/flows`,
      headers: {
        'Accept': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: dataForm
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        setModalNuevo(false);
        getFlujos();
        alerta("Nuevo flujo creado", "info")
        setFlujoModificar(response.data);
        //setModalAgregar(true);
        setModalVisualizar(true);
      })
      .catch(function (error) {
        console.log(error);
        alerta("Error al crear flujo", "error");
      });
  }

  function ListItemsTreeView() {
    return (
      <div>
        {flujos.map((flujo) => (
          <React.Fragment key={flujo._id}>
            <TreeView
              //key={catalogo._id}
              className={classes.root}
              defaultCollapseIcon={<AccountTreeIcon color="primary" className={classes.colapse} />}
              defaultExpandIcon={<AccountTreeIcon color="primary" className={classes.colapse} />}
            >
              <TreeItem nodeId="0"
                label={
                  <Grid container>
                    <Grid item xs={10}>
                      <Typography className={classes.nombrecatalogo}>
                        {flujo.name}
                      </Typography>
                    </Grid>

                    <Grid item xs={2}>
                      <Tooltip title="Ver nodos" placement="top">
                        <AccountTreeIcon
                          className={classes.iconsmall}
                          color="primary"
                          onClick={() => {
                            setFlujoModificar(flujo);
                            console.log("FLUJO:", flujo);
                            setModalVisualizar(true);
                            //crear gráfico
                            var nodos = [];
                            var x = 0;
                            //procesos
                            //ordenar
                            flujo.nodes.sort(function (a, b) {
                              if (a.step_id > b.step_id) {
                                return 1;
                              }
                              if (a.step_id < b.step_id) {
                                return -1;
                              }
                              // a must be equal to b
                              return 0;
                            });
                            //fin ordenar
                            for (var i = 0; i < flujo.nodes.length; i++) {
                              x = x + 100;
                              nodos = [...nodos,
                              {
                                type: 'InputNode',
                                id: flujo.nodes[i].step_id,
                                data: {
                                  value: flujo.nodes[i].name,
                                  workgroup_id: flujo.nodes[i].workgroup_id,
                                  catalogos: flujo.nodes[i].catalogos,
                                  flow_id: flujo._id,
                                  lista_grupos: grupos,
                                  lista_catalogos: catalogos,
                                },
                                position: { x: x, y: 25 },
                                style: {
                                  border: "1px solid #777",
                                  padding: 10,
                                  width: 80
                                }
                              }
                              ]
                            }
                            //flechas
                            for (var i = 0; i < flujo.nodes.length; i++) {
                              if (flujo.nodes[i].prev_step) {
                                nodos = [...nodos,
                                {
                                  arrowHeadType: 'arrowclosed',
                                  id: flujo.nodes[i].prev_step + "-" + flujo.nodes[i].step_id,
                                  source: flujo.nodes[i].prev_step,
                                  sourceHandle: "a",
                                  target: flujo.nodes[i].step_id,
                                  targetHandle: null
                                }
                                ]
                              }
                            }
                            console.log("elements", nodos);
                            setElements(nodos);
                          }}
                        />
                      </Tooltip>
                      {/*
                    <Tooltip title="Editar nodos del flujo" placement="top">
                      <QueueIcon
                        className={classes.iconsmall}
                        color="primary"
                        onClick={()=>{
                          setFlujoModificar(flujo);
                          setModalAgregar(true);
                        }}
                      />
                    </Tooltip>
*/}
                      <Tooltip title="Editar nombre del flujo" placement="top">
                        <EditIcon
                          className={classes.iconsmall}
                          color="primary"
                          onClick={() => {
                            setFlujoModificar(flujo);
                            setNombreFlujoModificar(flujo.name);
                            setModalModificar(true);
                          }}
                        />
                      </Tooltip>
                      <Tooltip title="Eliminar flujo" placement="top">
                        <DeleteIcon
                          className={classes.iconsmall}
                          color="primary"
                          onClick={() => {
                            setFlujoEliminar(flujo);
                            setModalEliminar(true);
                          }}
                        />
                      </Tooltip>
                    </Grid>
                  </Grid>
                }
                className={classes.root}
              >
                {flujo.nodes.map((item, key) => (
                  <TreeItem nodeId={item._id} key={key} label={
                    <Grid container>
                      <Grid item>
                        <WebAssetIcon
                          className={classes.iconarchivo}
                        //color="primary"
                        />
                      </Grid>
                      <Grid item>
                        <Typography className={classes.nombrearchivo}>
                          {item.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  } />
                ))}
              </TreeItem>
            </TreeView>
            <hr />
          </React.Fragment>
        ))}
      </div>
    );
  };

  async function handleModificar() {
    let token = sessionStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;
    var config = {
      method: 'put',
      url: `${process.env.REACT_APP_URL}api/flows/${flujoModificar._id}?name=${nombreFlujoModificar}`,
      headers: {
        'Accept': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        setModalModificar(false);
        getFlujos();
        alerta("Flujo modificado", "info")
      })
      .catch(function (error) {
        console.log(error);
        alerta("Error al modificar", "warning")
      });
  }

  function handleEliminar() {
    let token = sessionStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;
    var config = {
      method: 'delete',
      url: `${process.env.REACT_APP_URL}api/flows/${flujoEliminar._id}`,
      headers: {
        'Accept': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    };
    axios(config)
      .then(function (response) {
        console.log("Flujo Eliminado:", response.data);
        getFlujos();
        setModalEliminar(false);
        alerta("El flujo ha sido eliminado", "warning")
      })
      .catch(function (error) {
        console.log(error);
        alerta("Error al eliminar flujo", "error")
      });
  }


  async function getGrupos() {
    let token = sessionStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;
    var config = {
      method: 'get',
      url: `${process.env.REACT_APP_URL}api/workgroups`,
      headers: {
        'Accept': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    };
    axios(config)
      .then(function (response) {
        //console.log("Grupos:",response.data);
        setGrupos(response.data);
      })
      .catch(function (error) {
        console.log(error);
        setGrupos("");
      });
  }

  async function getCatalogos() {
    let token = sessionStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;
    var config = {
      method: 'get',
      url: `${process.env.REACT_APP_URL}api/catalogos`,
      headers: {
        'Accept': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    };
    axios(config)
      .then(function (response) {
        //console.log("Catalogos:",response.data);
        setCatalogos(response.data);
      })
      .catch(function (error) {
        console.log(error);
        setCatalogos("");
      });
  }

  function nombreCatalogo(idcatalogo) {
    const nombre = catalogos.find(item => item._id == idcatalogo);
    if (nombre) {
      return (nombre.name);
    }
    return (null);
  }

  function handleOrdenar() {
    if (order == "" || order == "des") {
      setOrder("asc");
      flujos.sort(function (a, b) {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
    }
    else {
      setOrder("des");
      flujos.sort(function (a, b) {
        if (a.name < b.name) {
          return 1;
        }
        if (a.name > b.name) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
    }
  }

  React.useEffect(() => {
    getFlujos();
    getGrupos();
    getCatalogos();
  }, []);

  let disabledNuevo = true;
  if (nombreFlujo != "") {
    disabledNuevo = false;
  }
  let disabledModificar = true;
  if (nombreFlujoModificar != "") {
    disabledModificar = false;
  }

  const onClose = () => {
    console.log("close");
    getFlujos();
    setModalAgregar(false);
  }

  return (
    <React.Fragment>
      <Grid container justify="center">
        <Paper elevation={3} className={classes.paper}>
          {/*BOTONES SUPERIORES*/}
          {step == 0 ? (
            <React.Fragment>
              <IconButton
                aria-label="Add"
                color="primary"
                onClick={() => {
                  setModalNuevo(true);
                }}
              >
                <Tooltip title="Crear nuevo flujo" placement="top">
                  <AddCircleIcon />
                </Tooltip>
              </IconButton>

              <IconButton
                aria-label="Add"
                color="primary"
                onClick={() => {
                  handleOrdenar();
                }}
              >
                <Tooltip title="Ordenar flujos" placement="top">
                  <SortByAlphaIcon />
                </Tooltip>
              </IconButton>

              {/*
       <IconButton aria-label="Add" color="primary">
         <Tooltip title="Buscar catálogo" placement="top">
           <SearchIcon />
         </Tooltip>
       </IconButton>
       */}
            </React.Fragment>
          ) : null}

          {step == 1 ? (<IconButton aria-label="Add" color="primary">
            <Tooltip title="Regresar a flujos" placement="top">
              <ArrowBackIcon
                onClick={() => { setStep(0) }}
              />
            </Tooltip>
          </IconButton>) : null}
          {/*BOTONES SUPERIORES*/}

          {/*LISTADO DE FLLUJOS*/}
          <AppBar position="static">
            <b><font style={{ margin: 10 }}>Flujos</font></b>
          </AppBar>
          <div style={{ background: "white" }}>
            {step == 0 && flujos ? (<ListItemsTreeView />) : null}
          </div>
          {/*FIN LISTADO DE FLUJOS*/}
        </Paper>
      </Grid>

      {/*DIALOGO NUEVO FLUJO*/}
      <Dialog
        fullWidth={true}
        maxWidth='lg'
        open={modalNuevo}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
        </DialogTitle>
        <DialogContent>
          {step == 0 ? (
            <React.Fragment>
              <Typography variant="h6" style={{ textTransform: "none" }}>
                Por favor ingrese el nombre del Flujo:
              </Typography>
              <TextField
                autoFocus
                required
                margin="dense"
                id="name"
                label="Nombre del flujo"
                variant="outlined"
                fullWidth
                value={nombreFlujo}
                onChange={(e) => {
                  setNombreFlujo(e.target.value)
                }}
              />
            </React.Fragment>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setModalNuevo(false)
              setNombreFlujo("");
              setStep(0);
            }}
            className={classes.botondanger}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            disabled={disabledNuevo}
            onClick={() => {
              handleGuardarNuevo();
              setModalNuevo(false)
              //setStep(1)
            }}
            className={classes.botongreen}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
      {/* FIN NUEVO CATÁLOGO*/}

      {/*DIALOGO MODIFICAR FLUJO*/}
      <Dialog
        open={modalModificar}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
        </DialogTitle>
        <DialogContent>
          <Typography variant="h6" style={{ textTransform: "none" }}>
            Por favor completar datos:
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Nombre del flujo"
            variant="outlined"
            fullWidth
            value={nombreFlujoModificar}
            onChange={(e) => {
              setNombreFlujoModificar(e.target.value)
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setModalModificar(false)
            }}
          //className={classes.botondanger}
          >
            Cerrar
          </Button>
          <Button
            variant="contained"
            disabled={disabledModificar}
            onClick={() => {
              handleModificar();
            }}
            className={classes.botongreen}
          >
            Modificar
          </Button>
        </DialogActions>
      </Dialog>
      {/* FIN MODIFICAR FLUJO*/}

      {/*DIALOGO ELIMINAR FLUJO*/}
      <Dialog
        open={modalEliminar}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
        </DialogTitle>
        <DialogContent>
          <Typography variant="h6" style={{ textTransform: "none" }}>
            ¿Está seguro de eliminar el flujo?<br /> <b>{flujoEliminar.name}</b>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setModalEliminar(false)
            }}
          //className={classes.botondanger}
          >
            Cerrar
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleEliminar();
            }}
            className={classes.botondanger}
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
      {/* FIN ELIMINAR FLUJO*/}

      {/*DIALOGO AGREGAR NODOS*/}
      <Dialog
        fullWidth={true}
        maxWidth='lg'
        open={modalAgregar}
        onClose={onClose}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>

        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"

            onClick={() => {
              setModalAgregar(false);
              setFlujoModificar("");
              //setAgregarSiguiente(false);
              //setNombreArchivo("");
            }}
            className={classes.botondanger}
          >
            Cancelar
          </Button>
          {/*
        <Button
          variant="contained"
          //disabled={disabledAgregar}
          onClick={() => {
            //handleAgregarArchivo();
          }}
          className={classes.botongreen}
        >
          Agregar
        </Button>
        */}
        </DialogActions>
      </Dialog>
      {/* FIN AGREGAR NODOS*/}

      {/*DIALOGO VER NODOS*/}
      <Dialog
        fullWidth={true}
        maxWidth='lg'
        open={modalVisualizar}
        //onClose={onClose}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <b>Flujo: </b>{flujoModificar.name}
          <hr />
        </DialogTitle>
        <DialogContent>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={() => {
              //setTextoFlujo(false);
              const id = `${elements.length + 1}`;
              setElements([...elements, { ...initialInputFull(grupos, catalogos), id }]);
            }}
          >
            Agregar Nodo
          </Button>
          <hr />
          <div style={{ height: 500 }}>
            <ReactFlow2
              elements={elements}
              onElementsRemove={onElementsRemove}
              onConnect={onConnect}
              deleteKeyCode={46} /* 'delete'-key */
              onElementClick={onElementClick}
              onPaneClick={onPaneClick}
              nodeTypes={nodeTypes}
              onLoad={onLoad}
              snapToGrid={true}
              snapGrid={[15, 15]}
            >
              <Controls />
            </ReactFlow2>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setModalVisualizar(false);
              setFlujoModificar("");
              setElements([]);
              //setAgregarSiguiente(false);
              //setNombreArchivo("");
            }}
          //className={classes.botondanger}
          >
            Cerrar
          </Button>
          <Button
            //className={classes.button}
            variant="contained"
            className={classes.botongreen}
            //color="primary"
            onClick={() => {
              onSubmit();
            }}
          >
            Guardar Flujo
          </Button>
        </DialogActions>
      </Dialog>
      {/* FIN VER FLUJO*/}

      {/*DIALOGO AGREGAR NODOS*/}
      <Dialog
        fullWidth={true}
        maxWidth='sm'
        open={modalEditar}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          Configurar
        </DialogTitle>
        <DialogContent>
          {objectEdit.type === "InputNode" && (
            <React.Fragment>
              <TextField
                autoFocus
                required
                margin="dense"
                id="name"
                label="Ingrese nombre del proceso"
                variant="outlined"
                fullWidth
                value={objectEdit.data.value}
                onChange={(e) => {
                  setObjectEdit({
                    ...objectEdit,
                    data: { ...objectEdit.data, value: e.target.value }
                  });

                  const newElement = elements.map((item) => {
                    if (item.id === objectEdit.id) {
                      return {
                        ...item,
                        data: { ...item.data, value: e.target.value }
                      };
                    }
                    return item;
                  });

                  setElements(newElement);
                }}
              />
            </React.Fragment>
          )}

          {grupos.length > 0 ?
            (
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-helper-label">Seleccione grupo *</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  required
                  id="demo-simple-select"
                  value={idGrupo}
                  onChange={(e) => {
                    setIdGrupo(e.target.value)
                    setObjectEdit({
                      ...objectEdit,
                      data: { ...objectEdit.data, workgroup_id: e.target.value }
                    });

                    const newElement = elements.map((item) => {
                      if (item.id === objectEdit.id) {
                        return {
                          ...item,
                          data: { ...item.data, workgroup_id: e.target.value }
                        };
                      }
                      return item;
                    });

                    setElements(newElement);
                  }}
                >
                  {grupos.map((item, key) => (
                    <MenuItem key={key} value={item._id}>{item.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : null}

          {catalogos.length > 0 ?
            (
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-helper-label">Seleccione catálogo</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={idCatalogo}
                  onChange={(e) => {
                    setIdCatalogo(e.target.value)
                    if (objectEdit.data.catalogos)
                      var arrayCatalogo = [...objectEdit.data.catalogos, e.target.value];
                    else
                      var arrayCatalogo = [e.target.value];
                    //console.log("array:",arrayCatalogo);
                    setObjectEdit({
                      ...objectEdit,
                      data: { ...objectEdit.data, catalogos: arrayCatalogo }
                    });

                    const newElement = elements.map((item) => {
                      if (item.id === objectEdit.id) {
                        return {
                          ...item,
                          data: { ...item.data, catalogos: arrayCatalogo }
                        };
                      }
                      return item;
                    });

                    setElements(newElement);

                  }}
                >
                  {catalogos.map((item, key) => (
                    <MenuItem key={key} value={item._id}>{item.name}</MenuItem>
                  ))}
                </Select>
                {objectEdit.data ? (
                  //JSON.stringify(objectEdit.data.catalogos)
                  objectEdit.data.catalogos && objectEdit.data.catalogos.map((item, key) => (
                    <p key={key} className={classes.p}><font size="1">
                      <b>-</b>{nombreCatalogo(item)}</font>
                      <Tooltip title="Eliminar catálogo" placement="top">
                        <DeleteIcon
                          className={classes.iconsmall2}
                          color="primary"
                          onClick={() => {
                            console.log("clic eliminar catalogo", item)
                            const arrayCatalogo = objectEdit.data.catalogos;
                            const index = arrayCatalogo.indexOf(item);
                            if (index > -1) {
                              arrayCatalogo.splice(index, 1);
                            }
                            //console.log(arrayCatalogo);
                            setObjectEdit({
                              ...objectEdit,
                              data: { ...objectEdit.data, catalogos: arrayCatalogo }
                            });

                            const newElement = elements.map((item) => {
                              if (item.id === objectEdit.id) {
                                return {
                                  ...item,
                                  data: { ...item.data, catalogos: arrayCatalogo }
                                };
                              }
                              return item;
                            });

                            setElements(newElement);
                          }}
                        />
                      </Tooltip>
                    </p>
                  ))
                ) : null}
              </FormControl>
            ) : null}

        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setModalEditar(false);
              setIdCatalogo("");
              //setAgregarSiguiente(false);
              //setNombreArchivo("");
            }}
          //className={classes.botondanger}
          >
            Cerrar
          </Button>
          {/*
        <Button
          variant="contained"
          //disabled={disabledAgregar}
          onClick={() => {
            //handleAgregarArchivo();
          }}
          className={classes.botongreen}
        >
          Agregar
        </Button>
        */}
        </DialogActions>
      </Dialog>
      {/* FIN AGREGAR NODOS*/}


      {/*MENSAJE*/}
      <Snackbar
        open={openMensaje}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        onClose={handleCloseMensaje}>
        <Alert onClose={handleCloseMensaje} severity={severity}>
          {mensaje}
        </Alert>
      </Snackbar>
      {/*FIN MENSAJE*/}
    </React.Fragment>
  )
}
