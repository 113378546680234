import React, { useEffect, useState } from "react";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import PVSpeechProcessor from "./PVSpeechProcessor";

const useStyles = makeStyles((theme) => ({
}));

export default function PVBuiltinCapture(props) {

    const { hOnError, hOnSpeech, hOnSpeechError, hDisabled } = props;
    const [isLoading, setLoading] = useState(false);
    const [videoUrlBlob, setVideoUrlBlob] = useState(null);
    const [wasValidated, setWasValidated] = useState(false);
    const webcamRef = React.useRef(null);
    const inputRef = React.useRef(null);
    const requiresApollo = true;

    const handleStartCaptureClick = React.useCallback(() => {
        setVideoUrlBlob(null)
        inputRef.current.addEventListener('change', handleStopCaptureClick);
        inputRef.current.click();
    }, [webcamRef]);

    const handleStopCaptureClick = React.useCallback((e) => {
        var file = e.target.files[0];
        const blob = URL.createObjectURL(file);
        webcamRef.current.src = blob;
        setVideoUrlBlob(blob);
        setWasValidated(false);
    }, [webcamRef]);

    const validateSpeech = React.useCallback(async () => {
        try {
            const file = inputRef.current.files[0];
            if (!file) {
                console.error("Video not available on input.");
                return;
            }
        } catch (e) {
            console.error(e.message, e.stackTrace);
            alert('Error al transcribir: ', e.message);
        }
    });

    const processRecording = () => {
        if (isLoading) {
            return;
        }

        if (videoUrlBlob === null) {
            hOnError('Debe grabar una prueba de vida para continuar.');
        }

        hOnSpeech({
            videoBlobUrl: videoUrlBlob,
            sstResult: '',
            requiresApollo: true,
        })
        setWasValidated(true);
    }

    const isMobile = (/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Windows Phone/i.test(navigator.userAgent)) ? true : false;

    const sendBtnDisabled = hDisabled || isLoading || videoUrlBlob === null || wasValidated;

    return (
        <React.Fragment>
            <center>
                <b style={{ display: isMobile ? 'block' : 'none', marginBottom: '1rem' }}>
                    Al confirmar el código acepta que está de acuerdo con el contenido del documento que se le presentó.
                </b>
                <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    style={{ marginRight: '1rem' }}
                    disabled={isLoading || hDisabled}
                    onClick={() => {
                        handleStartCaptureClick();
                    }}
                >
                    <Typography variant="h6">Grabar</Typography>
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    style={{
                        marginLeft: '2rem',
                    }}
                    component="span"
                    disabled={sendBtnDisabled}
                    onClick={() => {
                        processRecording();
                    }}
                >
                    <Typography variant="h6">Validar</Typography>
                </Button>
                <br />
                <br />
                <div>
                    <input ref={inputRef} type="file" accept="video/*" capture="user" style={{ display: "none" }} />
                    <video ref={webcamRef} style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        left: 0,
                        right: 0,
                        textAlign: "center",
                        zindex: 9,
                        width: "90%",
                        height: 480,
                        display: videoUrlBlob !== null ? "block" : "none",
                    }} controls></video>
                </div>
            </center>
        </React.Fragment >
    );
}