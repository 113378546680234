import React, { useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import TextField from "@material-ui/core/TextField";

// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/FooterLogin.js";
import Grid from "@material-ui/core/Grid";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Parallax from "components/Parallax/Parallax.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";
// import SectionBasics from "./Sections/SectionBasics.js";
// import SectionNavbars from "./Sections/SectionNavbars.js";
// import SectionTabs from "./Sections/SectionTabs.js";
import SectionPills from "./Sections/SectionPills.js";
// import SectionNotifications from "./Sections/SectionNotifications.js";
// import SectionTypography from "./Sections/SectionTypography.js";
// import SectionJavascript from "./Sections/SectionJavascript.js";
import SectionCarousel from "./Sections/SectionCarousel.js";
// import SectionCompletedExamples from "./Sections/SectionCompletedExamples.js";
// import SectionLogin from "./Sections/SectionLogin.js";
// import SectionExamples from "./Sections/SectionExamples.js";
// import SectionDownload from "./Sections/SectionDownload.js";
import SignUp from "containers/signUp/signUp.js";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import styles from "assets/jss/material-kit-react/views/components.js";

import logo from "assets/img/ConCertezaAzul.png";
import hats from "./whats.css";

const useStyles = makeStyles(styles);

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "white",
      borderColor: "white",
      borderWidth: 2,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
      borderColor: "white",
      borderWidth: 2,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",

        borderWidth: 2,
      },
      "&:hover fieldset": {
        borderColor: "yellow",

        borderWidth: 2,
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",

        borderWidth: 2,
      },
    },
  },
})(TextField);

const useStyles2 = makeStyles((theme) => ({
  whatsapp: {
    position: "fixed",
    width: "80px",
    height: "80px",
    bottom: " 60px",
    right: " 60px",
    backgroundColor: " #25d366",
    color: "#ffff",
    borderRadius: "60px",
    textAlign: "center",
    fontSize: "50px",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function Components(props) {
  const classes = useStyles();
  const classess2 = useStyles2();

  useEffect(() => {

    // Requerimiento Jueves 5 de Enero 2023: Si cae sobre el sitio principal de V1, redirigir a landing page de con certeza.
    window.location.replace('https://con-certeza.mx');
  }, []);

  return (<div></div>);
}