import React from "react";
import axios from "axios";
import moment from "moment";
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import AppBar from '@material-ui/core/AppBar';
import Tooltip from '@material-ui/core/Tooltip';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import PersonIcon from '@material-ui/icons/Person';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import GroupIcon from '@material-ui/icons/Group';
import Typography from "@material-ui/core/Typography";
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import FolderIcon from '@material-ui/icons/Folder';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Button from '@material-ui/core/Button';
import InfoIcon from '@material-ui/icons/Info';
import RestorePageIcon from '@material-ui/icons/RestorePage';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import TextField from '@material-ui/core/TextField';
import PDFView from "./PDFView";
import ExpedienteGraph from "./ExpedienteGraph";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  grafico: {
    //backgroundColor:"#3f51b5",
    //color:"white",
    margin: 0,
    borderStyle: "groove",
    borderColor: "#3f51b5",
    //borderColor: "#fb8c00",
    //borderColor: "#00acc1",
    width: "100%"
  },
  appbar: {
    width: "100%",
    backgroundColor: "#3f51b5",
    //backgroundColor:"#fb8c00",
    //backgroundColor:"#00acc1",
    color: "white",
    margin: 0,
    textAlign: "center"
    //    borderStyle: "groove",
    //    borderColor: "#3f51b5",
  },
  nodoname: {
    width: "100%",
    //backgroundColor:"#3f51b5",
    backgroundColor: "GhostWhite",
    //color:"white",
    margin: 0,
    textAlign: "left"
    //    borderStyle: "groove",
    //    borderColor: "#3f51b5",
  },
  iconsmall: {
    fontSize: 10,
    margin: 2,
    marginTop: 8, //15 center
    padding: 0,
  },
  iconsmall2: {
    fontSize: 10,
    margin: 2,
    marginTop: 5, //15 center
    padding: 0,
  },
  iconsmall3: {
    fontSize: 8,
    //margin:2,
    //marginTop:5, //15 center
    //padding:0,
  },
  iconcheck: {
    fontSize: 8,
    //color:"green",
    maxWidth: '8em',
    maxHeight: '2em',
    minWidth: '8em',
    minHeight: '2em',
    backgroundColor: "green",
    color: "white",
    margin: 2,
    //marginTop:5, //15 center
    //padding:0,
  },
  iconopen: {
    fontSize: 8,
    //color:"red",
    maxWidth: '8em',
    maxHeight: '2em',
    minWidth: '8em',
    minHeight: '2em',
    backgroundColor: "red",
    color: "white",
    margin: 2,
    //marginTop:5, //15 center
    //padding:0,
  },
  iconsave: {
    fontSize: 5,
    //color:"green",
    maxWidth: '8em',
    maxHeight: '2em',
    minWidth: '8em',
    minHeight: '2em',
    //backgroundColor:"green",
    //color:"white",
    margin: 2,
    //marginTop:5, //15 center
    //padding:0,
  },
  icondescartar: {
    fontSize: 5,
    //color:"green",
    maxWidth: '8em',
    maxHeight: '2em',
    minWidth: '8em',
    minHeight: '2em',
    //backgroundColor:"red",
    //color:"white",
    margin: 2,
    //marginTop:5, //15 center
    //padding:0,
  },
  colapse: {
    marginLeft: 20,
    color: "orange"
  },
  colapse1: {
    marginLeft: 20,
  },
  nombrecatalogo: {
    fontSize: 14,
    marginTop: 0,
    marginLeft: 10,
    textAlign: "left"
  },
  nombrearchivo: {
    marginTop: 6,
    fontSize: 10,
  },
  iconarchivo: {
    marginTop: 0,
    fontSize: 8,
    //color:"orange"
  },
  iconperson: {
    color: "black",
    marginTop: 0,
    fontSize: 10,
  },
  semaforopendiente: {
    color: "red",
    fontSize: "10",
    marginRight: 2
  },
  semaforocompletado: {
    color: "green",
    fontSize: "10",
    marginRight: 2
  },
  botondanger: {
    backgroundColor: "red",
    color: "white"
  },
  botongreen: {
    fontSize: "8",
    backgroundColor: "green",
    color: "white"
  },
  input: {
    display: "none"
  },
  botonupload: {
    marginLeft: 5,
  },
}));

export default function ExpedienteCarpetas(props) {
  const classes = useStyles();
  const [grupos, setGrupos] = React.useState("");
  const [catalogos, setCatalogos] = React.useState("");
  const [entro, setEntro] = React.useState(0);
  const [documentos, setDocumentos] = React.useState("");
  const [nombreArchivo, setNombreArchivo] = React.useState("");
  const [modalVer, setModalVer] = React.useState(false);
  const [base64, setBase64] = React.useState(false);

  const [modalCompletar, setModalCompletar] = React.useState(false);
  const [modalAbrir, setModalAbrir] = React.useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [modalRechazar, setModalRechazar] = React.useState(false);
  const [motivoRechazo, setMotivoRechazo] = React.useState("");
  const [nodo, setNodo] = React.useState("");
  const [modalAgregar, setModalAgregar] = React.useState(false);
  const [modalEliminar, setModalEliminar] = React.useState(false);
  const [documentoEliminar, setDocumentoEliminar] = React.useState("");
  const [documentoShow, setDocumentoShow] = React.useState("");
  const [customCode, setCustomCode] = React.useState("");
  const [file, setFile] = React.useState("");
  const [guardar, setGuardar] = React.useState(false);
  const [expediente, setExpediente] = React.useState(false);
  const [descripcion, setDescripcion] = React.useState("");

  const [openMensaje, setOpenMensaje] = React.useState(false);
  const [mensaje, setMensaje] = React.useState(false);
  const [severity, setSeverity] = React.useState("info");
  const handleCloseMensaje = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenMensaje(false);
  };

  function alerta(texto, severidad) {
    setOpenMensaje(true);
    setMensaje(texto);
    setSeverity(severidad);
  }


  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  async function handleUpload(file) {
    let b64 = await getBase64(file);
    setBase64(b64);
    setGuardar(true);
    setModalAgregar(false);
    setModalVer(true);
  }

  async function getCatalogos() {
    let token = sessionStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;
    var config = {
      method: 'get',
      url: `${process.env.REACT_APP_URL}api/catalogos`,
      headers: {
        'Accept': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    };
    axios(config)
      .then(function (response) {
        setCatalogos(response.data);
      })
      .catch(function (error) {
        console.log(error);
        setCatalogos("");
      });
  }

  async function getGrupos() {
    let token = sessionStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;
    var config = {
      method: 'get',
      url: `${process.env.REACT_APP_URL}api/workgroups`,
      headers: {
        'Accept': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    };
    axios(config)
      .then(function (response) {
        console.log("Grupos:", response.data);
        setGrupos(response.data);
      })
      .catch(function (error) {
        console.log(error);
        setGrupos("");
      });
  }

  async function getDocumentos() {
    let token = sessionStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;
    var config = {
      method: 'get',
      url: `${process.env.REACT_APP_URL}api/expedientes/documentos/${props.expediente._id}`,
      headers: {
        'Accept': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    };
    axios(config)
      .then(function (response) {
        console.log("Documentos:", response.data)
        setDocumentos(response.data);
      })
      .catch(function (error) {
        console.log(error);
        setDocumentos("");
      });
  }


  function DisplayGrupo(props) {
    const grupo = grupos.find(item => item._id == props.idgrupo);
    if (grupo)
      return (
        <React.Fragment key={grupo._id}>
          <TreeView
            className={classes.root}
            defaultCollapseIcon={<PeopleOutlineIcon color="primary" className={classes.colapse1} />}
            defaultExpandIcon={<GroupIcon color="primary" className={classes.colapse1} />}
          >
            <TreeItem nodeId="0"
              label={
                <Grid container>
                  <Grid item xs={11}>
                    <Typography className={classes.nombrecatalogo}>
                      <font size="2">{grupo.name}</font>
                    </Typography>
                  </Grid>
                </Grid>
              }
              className={classes.root}
            >
              {grupo.members.map((item, key) => (
                <TreeItem nodeId={item._id} key={key} label={
                  <Grid container>
                    <Grid item>
                      <PersonIcon
                        className={classes.iconperson}
                      />
                    </Grid>
                    <Grid item>
                      <Typography className={classes.nombrearchivo}>
                        <font size="2">{item.user.name}</font>
                      </Typography>
                    </Grid>
                  </Grid>
                } />
              ))}
            </TreeItem>
          </TreeView>
        </React.Fragment>
      );
    return (null)
  }

  function DisplayAnexos(props) {
    if (documentos) {
      //const docs = documentos.find(item => item.meta.expediente_node_id == props.nodo._id && item.meta.anexo );
      let docs = []
      for (var i = 0; i < documentos.length; i++) {
        if (documentos[i].meta.expediente_node_id == props.nodo._id && documentos[i].meta.anexo)
          docs = [...docs, documentos[i]]
      }
      console.log("Anexs:", docs)
      return (
        <>
          {docs.length > 0 ? (
            <TreeView
              className={classes.root}
              defaultCollapseIcon={<FolderOpenIcon color="primary" className={classes.colapse} />}
              defaultExpandIcon={<FolderIcon color="primary" className={classes.colapse} />}
              expanded={["0"]}
            >
              <TreeItem nodeId="0"
                label={
                  <Grid container>
                    <Grid item xs={11}>
                      <Typography className={classes.nombrecatalogo}>
                        <font size="2">Anexos</font>
                      </Typography>
                    </Grid>
                  </Grid>
                }
                className={classes.root}
              >
                {/*display anexos*/}
                {docs && docs.map((itemdoc, key) => (
                  <TreeItem nodeId={itemdoc._id} key={key} label={
                    <Grid container>
                      <Grid item>
                        <Typography className={classes.nombrearchivo}>
                          <font size="2"><b>{itemdoc.filename + " #" + itemdoc.meta.custom_id}</b></font>
                        </Typography>
                      </Grid>
                      <Grid item>
                        <React.Fragment>
                          <Tooltip title="Ver documento" placement="top">
                            <VisibilityIcon
                              className={classes.iconsmall}
                              color="primary"
                              onClick={() => {
                                //console.log("ver",item)
                                alerta("Abriendo documento, por favor espere unos segundos", "info")
                                handleBase64(itemdoc._id);
                              }}
                            />
                          </Tooltip>

                          <Tooltip title="Información del documento" placement="top">
                            <InfoIcon
                              className={classes.iconsmall}
                              color="primary"
                              onClick={() => {
                                //console.log("info",item)
                                setNombreArchivo(itemdoc);
                                setDocumentoShow(itemdoc);
                                setModalShow(true);
                              }}
                            />
                          </Tooltip>
                          {props.otros || props.status == 2 ? false : (
                            <>
                              <Tooltip title="Eliminar documento" placement="top">
                                <DeleteIcon
                                  className={classes.iconsmall}
                                  color="primary"
                                  onClick={() => {
                                    console.log(itemdoc)
                                    setNombreArchivo(itemdoc);
                                    setDocumentoEliminar(itemdoc);
                                    setModalEliminar(true);
                                  }
                                  }
                                />
                              </Tooltip>
                            </>
                          )}
                        </React.Fragment>


                      </Grid>
                    </Grid>
                  } />
                ))}
                {/*fin anexos*/}
              </TreeItem>
            </TreeView>
          ) : null}

        </>
      )
    }
    return (null)
  }

  function DisplayCatalogo(props) {
    function encontrado(itemdoc) {
      var doc = ""
      if (documentos)
        doc = documentos.find(documento => (documento.filename == itemdoc.name + ".pdf" && documento.meta.expediente_node_id == props.nodo._id));
      if (doc)
        return (doc)
      return (false)
    }

    if (catalogos) {
      const catalogo = catalogos.find(item => item._id == props.idcatalogo);
      if (catalogo)
        return (
          <React.Fragment key={catalogo._id}>
            <TreeView
              className={classes.root}
              defaultCollapseIcon={<FolderOpenIcon color="primary" className={classes.colapse} />}
              defaultExpandIcon={<FolderIcon color="primary" className={classes.colapse} />}
              expanded={["0"]}
            >
              <TreeItem nodeId="0"
                label={
                  <Grid container>
                    <Grid item xs={11}>
                      <Typography className={classes.nombrecatalogo}>
                        <font size="2">{catalogo.name}</font>
                      </Typography>
                    </Grid>
                  </Grid>
                }
                className={classes.root}
              >
                {catalogo.documentos.map((itemdoc, key) => (
                  <TreeItem nodeId={itemdoc._id} key={key} label={
                    <Grid container>
                      <Grid item>
                        <Typography className={classes.nombrearchivo}>
                          {encontrado(itemdoc) ? (
                            encontrado(itemdoc).reason ? (
                              <>
                                <font size="2" color="red">
                                  <b>
                                    {itemdoc.name +
                                      " #" +
                                      encontrado(itemdoc).meta.custom_id}
                                  </b>
                                </font>
                                <font size="1">{itemdoc.required === "1" ? <b>(Requerido)</b> : <>(Opcional)</>}</font>
                              </>
                            ) : (
                              <>
                                <font size="2">
                                  <b>
                                    {itemdoc.name +
                                      " #" +
                                      encontrado(itemdoc).meta.custom_id}
                                  </b>
                                </font>
                                <font size="1">{itemdoc.required === "1" ? <b>(Requerido)</b> : <>(Opcional)</>}</font>
                              </>
                            )
                          ) : (
                            <font size="2" color="gray">
                              {itemdoc.name} <font size="1">{itemdoc.required === "1" ? <b>(Requerido)</b> : <>(Opcional)</>}</font>
                            </font>
                          )}
                        </Typography>
                      </Grid>
                      <Grid item>
                        {encontrado(itemdoc) ? (
                          <React.Fragment>
                            <Tooltip title="Ver documento" placement="top">
                              <VisibilityIcon
                                className={classes.iconsmall}
                                color="primary"
                                onClick={() => {
                                  //console.log("ver",item)
                                  alerta("Abriendo documento, por favor espere unos segundos", "info")
                                  setNombreArchivo(itemdoc);
                                  if (documentos) {
                                    const doc = documentos.find(documento => (documento.filename == itemdoc.name + ".pdf" && documento.meta.expediente_node_id == props.nodo._id));
                                    if (doc) {
                                      handleBase64(doc._id);
                                    }
                                    else {
                                      setModalAgregar(true);
                                    }
                                  }
                                  else {
                                    setModalAgregar(true);
                                  }

                                }}
                              />
                            </Tooltip>

                            <Tooltip title="Información del documento" placement="top">
                              <InfoIcon
                                className={classes.iconsmall}
                                color="primary"
                                onClick={() => {
                                  //console.log("info",item)
                                  setNombreArchivo(itemdoc);
                                  if (documentos) {
                                    const doc = documentos.find(documento => (documento.filename == itemdoc.name + ".pdf" && documento.meta.expediente_node_id == props.nodo._id));
                                    //console.log("ENTROOOOOOOOOOOOOOOOOO",doc)
                                    setDocumentoShow(doc);
                                    setModalShow(true);
                                  }
                                }}
                              />
                            </Tooltip>
                            {props.status != 2 ? (
                              <>
                                <Tooltip title="Rechazar documento" placement="top">
                                  <RestorePageIcon
                                    className={classes.iconsmall}
                                    color="secondary"
                                    onClick={() => {
                                      setNodo(props.nodo);
                                      setNombreArchivo(itemdoc);
                                      if (documentos) {
                                        const doc = documentos.find(documento => (documento.filename == itemdoc.name + ".pdf" && documento.meta.expediente_node_id == props.nodo._id));
                                        setDocumentoShow(doc);
                                        setModalRechazar(true);
                                      }
                                    }}
                                  />
                                </Tooltip>
                                {/*
                    <Tooltip title="Eliminar documento" placement="top">
                    <DeleteIcon
                      className={classes.iconsmall}
                      color="primary"
                      onClick={()=>{
                        console.log(itemdoc)
                        setNombreArchivo(itemdoc);
                        const doc = documentos.find(documento => (documento.filename == itemdoc.name+".pdf" && documento.meta.expediente_node_id == props.nodo._id ));
                        if(doc){
                          setDocumentoEliminar(doc);
                          //console.log("documento:",doc);
                          setModalEliminar(true);
                        }
                        else{
                            alert("Documento no disponible");
                        }

                      }}
                    />
                    </Tooltip>
                    */}
                              </>
                            ) : null}

                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            {(props.otros || props.status == 2) ? null : (
                              <Tooltip title="Agregar documento al catálogo" placement="top">
                                <CloudUploadIcon
                                  className={classes.iconsmall}
                                  color="primary"
                                  onClick={() => {
                                    //console.log("ITEM",item)
                                    //console.log("NODO",props.nodo)
                                    setNodo(props.nodo);
                                    setNombreArchivo(itemdoc);
                                    setModalAgregar(true);
                                  }}
                                />
                              </Tooltip>
                            )}
                          </React.Fragment>
                        )}

                      </Grid>
                    </Grid>
                  } />
                ))}
              </TreeItem>
            </TreeView>
          </React.Fragment>
        );
      return (null);
    }
    return (null);
  }

  function handleBase64(id) {
    let token = sessionStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;
    var config = {
      method: 'get',
      url: `${process.env.REACT_APP_URL}api/documentos/${id}/preview/download`,
      headers: {
        'Accept': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    };
    axios(config)
      .then(function (response) {
        //console.log("base64:",response.data)
        setBase64("data: application / pdf; base64, " + response.data);
        setGuardar(false);
        setModalVer(true);
      })
      .catch(function (error) {
        console.log(error);
        setBase64("");
      });
  }

  console.log("EXPEDIENTE", props.expediente);
  if (props.expediente && entro == 0) {
    getDocumentos();
    setExpediente(props.expediente)
    setEntro(1);
  }
  else {
    if (props.expediente != expediente)
      setEntro(0);
  }

  function handleGuardar() {
    console.log("guardar", file);
    const data = new FormData();
    //data.append("filename", file);
    //data.append("filename", file,nombreArchivo.name+"-"+customCode+".pdf");
    data.append("filename", file, nombreArchivo.name + ".pdf");

    if (descripcion !== "") {
      data.append("description", descripcion);
    }

    data.append("documento_types_id", 10);
    data.append("expediente_id", props.expediente._id);
    data.append("meta[custom_id]", customCode);
    data.append("meta[expediente_node_id]", nodo._id);

    let url = `${process.env.REACT_APP_URL}api/expedientes/documento`;
    let token = sessionStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;
    var config = {
      method: "POST",
      url: url,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios
      .post(url, data, config, {})
      .then((response) => {
        // Respuesta del servidor
        console.log(response.data)
        getDocumentos();
        setModalVer(false)
      })
      .catch((error) => {
        // Error 😨
        console.log(error)
      });

  }

  function handleRechazar() {
    console.log("Rechazar");
    console.log(documentoShow)
    const data = new FormData();
    data.append("reason", motivoRechazo);

    let url = `${process.env.REACT_APP_URL}api/expedientes/refuse/${documentoShow._id}`;
    let token = sessionStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;
    var config = {
      method: "POST",
      url: url,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      data: data,
    };

    axios(config)
      //.post(url, config, {})
      .then((response) => {
        console.log(response.data)
        getDocumentos();
        alerta("El documento ha sido rechazado", "warning")
        setModalRechazar(false)

        if (props.getExpedientes) {
          props.getExpedientes();
        }

        if (props.setModalReportar) {
          props.setModalReportar(false);
        }

      })
      .catch((error) => {
        // Error 😨
        console.log(error);
      });
  }

  function handleEliminar() {
    console.log("Eliminar");
    console.log(documentoEliminar)
    let url = `${process.env.REACT_APP_URL}api/documentos/${documentoEliminar._id}`;
    let token = sessionStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;
    var config = {
      method: "DELETE",
      url: url,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(config)
      //.post(url, config, {})
      .then((response) => {
        console.log(response.data)
        getDocumentos();
        setModalEliminar(false)
        /*
        if(props.getExpedientes){
          props.getExpedientes();
        }
        */
      })
      .catch((error) => {
        // Error 😨
        console.log(error)
      });
  }

  React.useEffect(() => {
    getGrupos();
    getCatalogos();
  }, []);

  return (
    <React.Fragment>
      <Grid container justify="center">
        <AppBar position="static" className={classes.appbar}>
          <b>FLUJO</b>
        </AppBar>
        <div className={classes.grafico}>
          <ExpedienteGraph expediente={props.expediente} height={100} />
        </div>
      </Grid>
      <Grid container justify="center">
        <AppBar position="static" className={classes.appbar}>
          <b>CARPETAS</b>
        </AppBar>
        <div className={classes.grafico}>
          {props.expediente && props.expediente.nodes.map((item, key) => (
            <Grid container key={key} >
              <Grid item xs={11}>
                <div className={classes.nodoname}>
                  <font size="2" style={{ textAlign: "left", marginLeft: 10 }}><b>- {item.name}</b></font>
                </div>
              </Grid>
              <Grid item xs={1} style={{ textAlign: "right" }} className={classes.nodoname}>
                {item.status == 0 ?
                  (
                    <Tooltip title="Pendiente" placement="top">
                      <FiberManualRecordIcon className={classes.semaforopendiente} style={{ fontSize: 12 }} />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Completado" placement="top">
                      <CheckCircleIcon className={classes.semaforocompletado} style={{ fontSize: 12 }} />
                    </Tooltip>
                  )}
              </Grid>
              <Grid item xs={12}>
                {grupos ?
                  <DisplayGrupo idgrupo={item.workgroup_id} />
                  : null}
                {item.catalogos && item.catalogos.map((cat, key) => (
                  <DisplayCatalogo key={key} idcatalogo={cat} nodo={item} status={props.expediente.status} />
                ))}
                <DisplayAnexos nodo={item} documentos={documentos} status={props.expediente.status} />
              </Grid>
              <Grid item xs={12}>
                {/*
            <Button
              style={{float:"left"}}
              variant="contained"
              color="secondary"
              size="small"
              className={classes.icondescartar}
            >
              Descartar
            </Button>
            <Button
              style={{float:"left"}}
              variant="contained"
              color="primary"
              size="small"
              className={classes.iconsave}
            >
              Guardar
            </Button>
            */}

              </Grid>
            </Grid>
          ))}
        </div>
      </Grid>

      {/*DIALOGO VER PDF*/}
      <Dialog
        fullWidth={true}
        maxWidth='md'
        open={modalVer}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {nombreArchivo.name}
        </DialogTitle>
        <DialogContent>
          {base64 ? <PDFView base64={base64} /> : ("NO hay PDF")}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            //color="primary"
            onClick={() => {
              setModalVer(false)
            }}
            className={classes.botondanger}
          >
            Cerrar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              //setModalVer(false)
              //const linkSource = `data:application/pdf;base64,${pdf}`;
              const downloadLink = document.createElement("a");
              //const fileName = "vct_illustration.pdf";
              downloadLink.href = base64;
              downloadLink.download = nombreArchivo.name + ".pdf";
              downloadLink.click();
            }}
          //className={classes.botondanger}
          >
            Descargar
          </Button>
          {guardar ? (
            <Button
              variant="contained"
              //color="primary"
              onClick={() => {
                //setModalVer(false)
                handleGuardar();
              }}
              className={classes.botongreen}
            >
              Guardar
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
      {/* FIN VER PDF*/}

      {/*DIALOGO AGREGAR*/}
      <Dialog
        fullWidth={true}
        maxWidth='xs'
        open={modalAgregar}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {nombreArchivo.name}
        </DialogTitle>
        <DialogContent>
          <React.Fragment>
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  required
                  margin="dense"
                  id="name_archivo"
                  label="Código interno"
                  variant="outlined"
                  fullWidth
                  value={customCode}
                  onChange={(e) => {
                    setCustomCode(e.target.value)
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="descripcion_archivo"
                  label="Descripción / Comentarios"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={2}
                  value={descripcion}
                  onChange={(e) => {
                    setDescripcion(e.target.value);
                  }}
                />
              </Grid>

              {customCode ? (
                <Grid item xs={12}>
                  Por favor selecione documento
                  <>
                    <input
                      accept="application/pdf"
                      className={classes.input}
                      id="contained-button-file"
                      multiple
                      type="file"
                      onChange={(event) => {
                        setFile(event.target.files[0]);
                        handleUpload(event.target.files[0])
                      }}
                    />
                    <label htmlFor="contained-button-file">
                      <CloudUploadIcon
                        className={classes.botonupload}
                        color="primary"
                      />
                    </label>
                  </>
                </Grid>
              ) : null}
            </Grid>
          </React.Fragment>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            //color="primary"
            onClick={() => {
              setModalAgregar(false)
            }}
            className={classes.botondanger}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      {/* FIN AGREGAR*/}

      {/*DIALOGO INFO*/}
      <Dialog
        fullWidth={true}
        maxWidth='sm'
        open={modalShow}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Documento
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {documentoShow ? (
              <>
                <b>Nombre:</b> {documentoShow.filename}
                <br></br>
                <b>Descripción / Comentarios:</b> {documentoShow.description}
                <br></br>
                <b>Fecha de registro:</b> {moment(documentoShow.created_at).format(
                  "DD/MM/YYYY HH:mm:ss"
                )}
                <br></br>
                <b>Código:</b> {documentoShow.meta && documentoShow.meta.custom_id ? documentoShow.meta.custom_id : null}
                <br></br>
                <b>Usuario:</b> {documentoShow.users && documentoShow.users.name ? documentoShow.users.name + " (" + documentoShow.users.email + ")" : null}
                <br></br>
                <b>Estatus:</b> {documentoShow.status && documentoShow.status.name ? documentoShow.status.name : null}
                <br></br>
                {documentoShow.reason ? (
                  <React.Fragment>
                    <font color="red"><b>{documentoShow.reason}</b></font>
                    <br></br>
                    <font color="red">Fecha de rechazo:</font>{" "}
                    <font color="red">
                      {moment(documentoShow.stopped_at).format(
                        "DD/MM/YYYY HH:mm:ss"
                      )}
                    </font>
                    <br></br>
                  </React.Fragment>
                ) : null}
                <b>Hash:</b> <font size="1">{documentoShow.hash}</font>
                <br></br>
                <b>ID:</b> {documentoShow._id}
                <br></br>
                <b>Tamaño:</b> {documentoShow.size}
                <br></br>
              </>
            ) : null}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setModalShow(false)
            }}
          //          className={classes.botondanger}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      {/* FIN SHOW INFO*/}

      {/*DIALOGO RECHAZAR*/}
      <Dialog
        fullWidth={true}
        maxWidth='sm'
        open={modalRechazar}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Documento {documentoShow ? documentoShow.filename : null}
        </DialogTitle>
        <DialogContent>
          <React.Fragment>
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  required
                  margin="dense"
                  id="motivo_rechazo"
                  label="Motivo de rechazo"
                  variant="outlined"
                  fullWidth
                  value={motivoRechazo}
                  onChange={(e) => {
                    setMotivoRechazo(e.target.value)
                  }}
                />
              </Grid>
            </Grid>
          </React.Fragment>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            //color="primary"
            onClick={() => {
              setModalRechazar(false)
              setMotivoRechazo("")
            }}
            className={classes.botondanger}
          >
            Cerrar
          </Button>
          {motivoRechazo ? (
            <Button
              variant="contained"
              //color="primary"
              onClick={() => {
                handleRechazar();
              }}
              className={classes.botondanger}
            >
              Rechazar
            </Button>
          ) : null}

        </DialogActions>
      </Dialog>
      {/* FIN RECHAZAR*/}

      {/*DIALOGO ELIMINAR*/}
      <Dialog
        fullWidth={true}
        maxWidth='xs'
        open={modalEliminar}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Documento: {nombreArchivo.name}
        </DialogTitle>
        <DialogContent>
          ¿Está seguro de eliminar este documento?
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            //color="primary"
            onClick={() => {
              setModalEliminar(false)
            }}
            className={classes.botondanger}
          >
            Cerrar
          </Button>
          <Button
            variant="contained"
            //color="primary"
            onClick={() => {
              handleEliminar();
            }}
            className={classes.botondanger}
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
      {/* FIN ELIMINAR*/}

      {/*MENSAJE*/}
      <Snackbar
        open={openMensaje}
        autoHideDuration={2000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        onClose={handleCloseMensaje}>
        <Alert onClose={handleCloseMensaje} severity={severity}>
          {mensaje}
        </Alert>
      </Snackbar>
      {/*FIN MENSAJE*/}


    </React.Fragment>
  )
}
