import Http from './HttpProvider';

const CdoFirmantes = {
    async obtenerFirmaChallenge(isMobile) {
        return Http.Fetch('GET', `api/firmas/challenge?mobile=${isMobile}`, {}, 'application/json', true);
    },
    async isValidPhrase(phrase) {
        return Http.Fetch('POST', `api/firmas/isValidPhrase`, { phrase }, 'application/json', true);
    },
    async validateFirmaChallenge(videoBlob, sstResult) {
        const formData = new FormData();
        formData.append('speech_video', videoBlob);
        formData.append('speech_text_result', sstResult);
        return Http.Fetch('POST', 'api/firmas/validateChallenge', formData, 'multipart/form-data', true);
    }
}

export default CdoFirmantes;