import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import MaterialTable from "material-table";
//modal
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// @material-ui/icons
import InfoIcon from "@material-ui/icons/Info";
import SearchIcon from "@material-ui/icons/Search";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Close from "@material-ui/icons/Close";
import CircularProgress from '@material-ui/core/CircularProgress';

import moment from "moment";
import axios from "axios";
import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";
import styles2 from "assets/jss/material-dashboard-react/components/tasksStyle.js";
const useStyles = makeStyles(styles);
const useStylestable = makeStyles(styles2);

export default function Firmantes() {
  const colorStatistics = "#024ebc";
  const classes = useStyles();
  const classes2 = useStylestable();
  const [loading, setLoading] = React.useState(true);
  const [modal, setModal] = React.useState(false);
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });

  const [detalleFirmante, setDetalleFirmante] = React.useState([{}]);
  const [grouped, setGrouped] = React.useState([{}]);

  const [documentos, setDocumentos] = React.useState({
    totalgeneral: 0,
    totales: 0,
    last30: 0,
    last7: 0,
    today: 0,
  });

  const [tablasDocumentos, setTablasDocumentos] = React.useState({
    resultTotal: [{}],
    resultYear: [{}],
    resultMonth: [{}],
    resultWeek: [{}],
    resultToday: [{}],
  });

  const [sortedField, setSortedField] = React.useState(null);
  const [tablaActual, setTablaActual] = React.useState([]);
  const [titulo, setTitulo] = React.useState("");
  const [desde, setDesde] = React.useState("");
  const [hasta, setHasta] = React.useState("");

  function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  function setFechas() {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let separator = "-";

    let fecha = `${year}${separator}${month < 10 ? `0${month}` : `${month}`
      }${separator}${date}`;
    setDesde(fecha);
    setHasta(fecha);
  }

  React.useEffect(() => {
    let token = sessionStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let separator = "-";

    let fecha = `${year}${separator}${month < 10 ? `0${month}` : `${month}`
      }${separator}${date}`;
    setDesde(fecha);
    setHasta(fecha);

    //FIRMANTES
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_URL}api/firmantes`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    };
    axios(config)
      .then(function (response) {
        let pp = response.data;

        const agrupado = groupBy(pp, (p) => p.email);
        setGrouped(agrupado);

        let arr = pp.filter(
          (ele, ind) => ind === pp.findIndex((elem) => elem.email === ele.email)
        );
        console.log(arr);

        var startDate = new Date(year, 0, 1);
        var endDate = new Date();
        var thirtyDaysAgo = new Date(new Date() - 1000 * 60 * 60 * 24 * 30);
        var sevenDaysAgo = new Date(new Date() - 1000 * 60 * 60 * 24 * 7);
        var oneDaysAgo = new Date(new Date() - 1000 * 60 * 60 * 24 * 1);

        var resultYear = arr.filter(function (a) {
          return (
            new Date(a.created_at) >= startDate &&
            new Date(a.created_at) <= endDate
          );
        });

        var resultMonth = arr.filter(function (a) {
          return (
            new Date(a.created_at) >= thirtyDaysAgo &&
            new Date(a.created_at) <= endDate
          );
        });

        var resultWeek = arr.filter(function (a) {
          return (
            new Date(a.created_at) >= sevenDaysAgo &&
            new Date(a.created_at) <= endDate
          );
        });

        var resultToday = arr.filter(function (a) {
          return (
            new Date(a.created_at) >= oneDaysAgo &&
            new Date(a.created_at) <= endDate
          );
        });

        setDocumentos({
          ...documentos,
          totalgeneral: arr.length,
          totales: resultYear.length,
          last30: resultMonth.length,
          last7: resultWeek.length,
          today: resultToday.length,
        });

        setTablasDocumentos({
          resultTotal: arr,
          resultYear: resultYear,
          resultMonth: resultMonth,
          resultWeek: resultWeek,
          resultToday: resultToday,
        });

        const data = resultYear;
        /*
        data.map(function (item) {
          item.created_at = moment(item.created_at).format("DD-MM-YYYY");
          item.firmantes_names = item.firmantes_names.replace(/,/g, " , ");
          return item;
        });
        */
        console.log(data);
        setTablaActual(data);
        setTitulo("AÑO EN CURSO (" + data.length + ")");
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });

    //FIN DOCUMENTOS
  }, []);

  const handleTotal = () => {
    setFechas();
    setTablaActual(tablasDocumentos.resultTotal);
    setTitulo("TOTAL (" + tablasDocumentos.resultTotal.length + ")");
  };

  const handleYear = () => {
    setFechas();
    setTablaActual(tablasDocumentos.resultYear);
    setTitulo("AÑO EN CURSO (" + tablasDocumentos.resultYear.length + ")");
  };

  const handleMonth = () => {
    setFechas();
    setTablaActual(tablasDocumentos.resultMonth);
    setTitulo("ÚLTIMOS 30 DÍAS (" + tablasDocumentos.resultMonth.length + ")");
  };

  const handleWeek = () => {
    setTablaActual(tablasDocumentos.resultWeek);
    setTitulo("ÚLTIMOS 7 DÍAS (" + tablasDocumentos.resultWeek.length + ")");
  };

  const handleDay = () => {
    setFechas();
    setTablaActual(tablasDocumentos.resultToday);
    setTitulo("ÚLTIMO DÍA");
  };

  const onChangeDesde = (e) => {
    setDesde(e.target.value);
    console.log("desde", e.target.value); // Works
  };

  const onChangeHasta = (e) => {
    setHasta(e.target.value);
    console.log("hasta", e.target.value); // Works
  };

  const handleFecha = () => {
    let arr = tablasDocumentos.resultTotal;

    var startDate = new Date(desde + " 00:00:00");
    var endDate = new Date(hasta + " 23:59:59");

    var result = arr.filter(function (a) {
      return (
        new Date(a.created_at) >= startDate &&
        new Date(a.created_at) <= endDate
      );
    });
    setTablaActual(result);
    setTitulo("INTERVALO PERSONALIZADO ( " + result.length + " )");
    /*
        let token = sessionStorage.getItem("Token");
        //DOCUMENTOS
        var config = {
          method: "get",
          url: `${process.env.REACT_APP_URL}api/firmantes`,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        };
        axios(config)
          .then(function (response) {
            let pp = response.data;
            let arr = pp.filter(
              (ele, ind) => ind === pp.findIndex((elem) => elem.email === ele.email)
            );
    
            var startDate = new Date(desde+" 00:00:00");
            var endDate = new Date(hasta+" 23:59:59");
    
            var result = arr.filter(function (a) {
              return (
                new Date(a.created_at) >= startDate &&
                new Date(a.created_at) <= endDate
              );
            });
            setTablaActual(result);
            setTitulo("INTERVALO PERSONALIZADO ( "+result.length+" )");
          })
          .catch(function (error) {
            console.log(error);
          });
        //FIN DOCUMENTOS
        */
  };

  const localization = {
    body: {
      emptyDataSourceMessage: "No hay datos por mostrar",
      addTooltip: "Añadir",
      deleteTooltip: "Eliminar",
      editTooltip: "Editar",
      filterRow: {
        filterTooltip: "Filtrar",
      },
    },
    header: {
      actions: "",
    },
    pagination: {
      firstAriaLabel: "Primera página",
      firstTooltip: "Primera página",
      labelDisplayedRows: "{from}-{to} de {count}",
      labelRowsPerPage: "Filas por página:",
      labelRowsSelect: "filas",
      lastAriaLabel: "Ultima página",
      lastTooltip: "Ultima página",
      nextAriaLabel: "Pagina siguiente",
      nextTooltip: "Pagina siguiente",
      previousAriaLabel: "Pagina anterior",
      previousTooltip: "Pagina anterior",
    },
    toolbar: {
      exportAriaLabel: "Exportar",
      exportTitle: "Exportar",
      exportCSVName: "Exportar a CVS",
      exportPDFName: "Exportar a PDF",
      searchPlaceholder: "Buscar",
      searchTooltip: "Buscar",
    },
  };

  function documento(id) {
    if (id) {
      console.log(id);
      return "hola";
    }
  }

  const tiempo = (date1, date2) => {

    var dateOneObj = new Date(date1);
    const dateTwoObj = new Date(date2);
    if (date2 && !date1) {
      dateOneObj = new Date(date2);
    }

    const milliseconds = Math.abs(dateTwoObj - dateOneObj);
    const hour = milliseconds / 36e5;
    const num = Math.floor(milliseconds / 1000 / 60);
    //se transforma en horas y minutos
    //var num = minute;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);

    if (rhours > 0) {
      if (rhours > 1) {
        return (rhours + " Hrs - " + rminutes + " min");
      }
      else {
        return (rhours + " Hr - " + rminutes + " min");
      }
    }
    else {
      if (rhours === 0) {
        return (rminutes + " min");
      }
    }

    return (null);
  }

  const tiempopromedio = (email) => {
    var data = grouped.get(email);
    var cont = 0, sum = 0;

    data.forEach((item, i) => {
      if (item.date_signed) {
        let dateOneObj = "";
        if (item.date_notified) {
          dateOneObj = new Date(item.date_notified);
        } else {
          dateOneObj = new Date(item.created_at);
        }

        const dateTwoObj = new Date(item.date_signed);
        const milliseconds = Math.abs(dateTwoObj - dateOneObj);
        const hours = milliseconds / 36e5;
        const minutes = Math.floor(milliseconds / 1000 / 60);
        //const hours = (dateTwoObj - dateOneObj)/36e5;
        sum += minutes;
        cont++;
      }
    });
    var prom = Math.floor(sum / cont);

    //se transforma en horas y minutos
    var num = prom;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);

    if (rhours > 0) {
      if (rhours > 1) {
        return (rhours + " Hrs - " + rminutes + " min");
      }
      else {
        return (rhours + " Hr - " + rminutes + " min");
      }
    }
    else {
      if (rhours === 0) {
        return (rminutes + " min");
      }
    }

    return (null);
  }


  return (
    <React.Fragment>
      <Grid container style={{ marginTop: 0 }}>
        <Grid item xs={12}>
          {/*
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography color="primary" gutterBottom>
              <font size="5">
                <b>FIRMANTES</b>
              </font>
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography color="dark" gutterBottom>
              <font size="1">
                <b>LISTADO DE FIRMANTES REGISTRADOS EN EL SISTEMA</b>
              </font>
            </Typography>
          </Box>
          */}
        </Grid>

        <Grid item xs={6} sm={3}>
          <Card variant="outlined">
            <CardContent>
              <Typography
                className={classes.title}
                color="textPrimary"
                gutterBottom
              >
                <b>Total</b>
              </Typography>
              <hr />
              <h3 style={{ color: "#6cc24a", margin: 0 }}>
                <b>{documentos.totalgeneral}</b>
              </h3>
            </CardContent>
            <Button variant="outlined" color="primary" onClick={handleTotal} style={{ margin: 0 }}>
              VER DETALLE
            </Button>
          </Card>
        </Grid>

        <Grid item xs={6} sm={3}>
          <Card variant="outlined">
            <CardContent>
              <Typography
                className={classes.title}
                color="textPrimary"
                gutterBottom
              >
                <b>Año en Curso</b>
              </Typography>
              <hr />
              <h3 style={{ color: "#6cc24a", margin: 0 }}>
                <b>{documentos.totales}</b>
              </h3>
            </CardContent>
            <Button variant="outlined" color="primary" onClick={handleYear} style={{ margin: 0 }}>
              VER DETALLE
            </Button>
          </Card>
        </Grid>

        <Grid item xs={6} sm={3}>
          <Card variant="outlined">
            <CardContent>
              <Typography
                className={classes.title}
                color="textPrimary"
                gutterBottom
              >
                <b>Últimos 30 días</b>
              </Typography>
              <hr />
              <h3 style={{ color: "#6cc24a", margin: 0 }}>
                <b>{documentos.last30}</b>
              </h3>
            </CardContent>
            <Button variant="outlined" color="primary" onClick={handleMonth} style={{ margin: 0 }}>
              VER DETALLE
            </Button>
          </Card>
        </Grid>

        <Grid item xs={6} sm={3}>
          <Card variant="outlined">
            <CardContent>
              <Typography
                className={classes.title}
                color="textPrimary"
                gutterBottom
              >
                <b>Últimos 7 días</b>
              </Typography>
              <hr />
              <h3 style={{ color: "#6cc24a", margin: 0 }}>
                <b>{documentos.last7}</b>
              </h3>
            </CardContent>
            <Button variant="outlined" color="primary" onClick={handleWeek} style={{ margin: 0 }}>
              VER DETALLE
            </Button>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Card variant="outlined">
            <CardContent>
              <Typography
                className={classes.title}
                color="textPrimary"
                gutterBottom
              >
                <b>Intervalo personalizado</b>
              </Typography>
              <hr />
              <TextField
                id="dateDesde"
                label="Desde"
                type="date"
                onChange={onChangeDesde}
                value={desde}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                id="dateHasta"
                label="Hasta"
                type="date"
                onChange={onChangeHasta}
                value={hasta}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Button variant="outlined" color="primary" onClick={handleFecha}>
                VER DETALLE
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid container style={{ marginTop: 10 }}>
        <div style={{ maxWidth: "100%" }}>
          {
            loading ? (
              <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                <CircularProgress />
              </div>
            ) : null
          }
          <MaterialTable
            localization={localization}
            options={{
              draggable: false,
              headerStyle: {
                backgroundColor: colorStatistics,
                color: "#FFF",
              },
              pageSize: 20, // make initial page size
              pageSizeOptions: [20, 50, 100, 500, 1000],
              actionsColumnIndex: -1,
              exportButton: { csv: true, pdf: false },
              exportAllData: true,
              actionsColumnIndex: 0,
            }}
            columns={[
              { title: "Compañía", field: "documentos.users.company" },
              { title: "Nombre", field: "name" },
              { title: "Apellido", field: "last_name" },
              { title: "Empresa", field: "company" },
              { title: "Correo", field: "email" },
              { title: "Celular", field: "phone" },
              {
                title: "Tiempo Prom.",
                render: (row) => (
                  <span>
                    {tiempopromedio(row.email)}
                  </span>
                ),
              },
            ]}
            data={tablaActual}
            title={titulo}
            actions={[
              {
                icon: () =>
                  <InfoIcon
                    className={
                      classes2.tableActionButtonIcon +
                      " " +
                      classes2.edit
                    }
                  />,
                tooltip: "Ver más detalles",
                onClick: (event, rowData) => {
                  //alert("You saved " + rowData.email);
                  var data = grouped.get(rowData.email);
                  console.log(data);
                  setDetalleFirmante(data);
                  setModal(true);
                },
              },
            ]}
          />
        </div>

      </Grid>

      <Dialog
        open={modal}
        transition={Transition}
        keepMounted
        fullScreen={false}
        fullWidth={true}
        maxWidth={"lg"}
        onClose={() => setModal(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h4 className={classes.modalTitle}>
            Firmas de{" "}
            <b>
              {detalleFirmante[0].name + " " + detalleFirmante[0].last_name}
            </b>
          </h4>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          <div>
            <MaterialTable
              localization={localization}
              options={{
                draggable: false,
                headerStyle: {
                  backgroundColor: colorStatistics,
                  color: "#FFF",
                },
                pageSize: 5, // make initial page size
                pageSizeOptions: [5, 20, 50, 100],
                actionsColumnIndex: -1,
                exportButton: { csv: true, pdf: false },
                exportAllData: true,
                actionsColumnIndex: 0,
              }}
              columns={[
                { title: "Rol", field: "role" },
                { title: "Notificación", field: "notification_types.name" },
                { title: "Documento", field: "documentos.filename" },
                /*
              {
                field: 'DOCUMENTO',
                title: 'DOCUMENTO',
                render: rowData => documento(rowData._id)
              },
              */
                {
                  title: "Creado",
                  field: "created_at",
                  render: (row) => (
                    <span>
                      {(row["created_at"]) ? moment(row["created_at"]).format("DD/MM/YYYY HH:mm:ss") : null}
                    </span>
                  ),
                },
                {
                  title: "Notificado",
                  field: "date_notified",
                  render: (row) => (
                    <span>
                      {(row["date_notified"]) ? moment(row["date_notified"]).format("DD/MM/YYYY HH:mm:ss") : null}
                    </span>
                  ),
                },
                {
                  title: "Firma",
                  field: "date_signed",
                  render: (row) => (
                    <span>
                      {(row["date_signed"]) ? moment(row["date_signed"]).format("DD/MM/YYYY HH:mm:ss") : null}
                    </span>
                  ),
                },
                {
                  title: "Tiempo",
                  render: (row) => (
                    <span>
                      {tiempo(row.date_notified, row.date_signed)}
                    </span>
                  ),
                },
                { title: "ID Firmante", field: "_id" },
              ]}
              data={detalleFirmante}
              title={"Detalle"}
            />
          </div>
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
          <Button onClick={() => setModal(false)} color="success">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
