import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "components/CustomButtons/Button.js";
import { DialogComp } from "components/Dialog/DialogComp";
import PersonIcon from '@material-ui/icons/Person';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
import Button2 from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";


// core components
import EditIcon from "@material-ui/icons/Edit";
import MaterialTable from "material-table";
import axios from "axios";
import styles2 from "assets/jss/material-dashboard-react/components/tasksStyle.js";
import { data } from "@tensorflow/tfjs";

const localization = {
    body: {
        emptyDataSourceMessage: "No hay datos por mostrar",
        addTooltip: "Añadir",
        deleteTooltip: "Eliminar",
        editTooltip: "Editar",
        filterRow: {
            filterTooltip: "Filtrar",
        },
    },
    header: {
        actions: "Herramientas",
    },
    pagination: {
        firstAriaLabel: "Primera página",
        firstTooltip: "Primera página",
        labelDisplayedRows: "{from}-{to} de {count}",
        labelRowsPerPage: "Filas por página:",
        labelRowsSelect: "filas",
        lastAriaLabel: "Ultima página",
        lastTooltip: "Ultima página",
        nextAriaLabel: "Pagina siguiente",
        nextTooltip: "Pagina siguiente",
        previousAriaLabel: "Pagina anterior",
        previousTooltip: "Pagina anterior",
    },
    toolbar: {
        exportAriaLabel: "Exportar",
        exportTitle: "Exportar",
        exportCSVName: "Exportar a CVS",
        exportPDFName: "Exportar a PDF",
        searchPlaceholder: "Buscar",
        searchTooltip: "Buscar",
    },
};
const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0",
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
    },
};
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStylestable = makeStyles(styles2);
const useStyles = makeStyles(styles);

export default function Corporaciones() {
    const classes2 = useStylestable();
    const [corporaciones, setCorporaciones] = useState([]);
    const [OpenEditCorporativo, setOpenEditCorporativo] = useState(false)
    const [showTexfield, setShowTexfield] = useState(false)
    const [nameRepre, setNameRepre] = useState("")
    const [editNameRepre, setEditNameRepre] = useState("")
    const [OpenEditName, setOpenEditName] = useState(false)
    const [indexRepre, setIndexRepre] = useState(null)
    const [openSnack, setOpenSnack] = useState(false);
    const [messageError, setMessageError] = useState("");

    const [datosModificar, setDatosModificar] = useState({
        idCorporativo: "",
        RFC_Corporacion: "",
        respresentantes: []
    });

    useEffect(() => {
        handleCorporaciones();
    }, []);

    const handleInsertarRepre = () => {
        setShowTexfield(false)
        let lista = datosModificar.respresentantes

        lista.push(nameRepre)
        setDatosModificar({
            ...datosModificar,
            respresentantes: lista,
        })
    }

    // alert functions

    const handleCloseSnack = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpenSnack(false);
    };


    const openAlert = (error) => {
        setOpenSnack(true);
        setMessageError(error);
    };


    const handleInputModificar = (event) => {
        setDatosModificar({
            ...datosModificar,
            [event.target.name]: event.target.value,
        });

    };


    const handleCorporaciones = () => {
        let token = sessionStorage.getItem("Token");
        axios
            .get(`${process.env.REACT_APP_URL}api/corporaciones`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                console.log("RESPCORPO", res.data);
                setCorporaciones(res.data);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleEditCorporativo = (data) => {
        setOpenEditCorporativo(true)
        setDatosModificar({
            idCorporativo: data._id,
            RFC_Corporacion: data.rfc == undefined ? "" : data.rfc,
            respresentantes: data.representante == undefined ? [] : data.representante,
        });
        console.log("DATACORPORATIVO", data)
    }
    const inputName = (event) => {
        setNameRepre(event.target.value)

    }
    const editInputName = (event) => {
        setEditNameRepre(event.target.value)

    }

    const deleteRepresentante = (index) => {
        console.log("INDEXREPRE", index)
        let lista = datosModificar.respresentantes
        lista.splice(index, 1)
        setDatosModificar({
            ...datosModificar,
            respresentantes: lista,
        })

    }

    const handleEditName = () => {
        setOpenEditName(false)
        let lista = datosModificar.respresentantes
        lista[indexRepre] = editNameRepre

    }
    const handlesendData = () => {
        let token = sessionStorage.getItem("Token");
        const data = new FormData();

        data.append("rfc", datosModificar.RFC_Corporacion);
        datosModificar.respresentantes.forEach((item, i) => data.append(`representante[${i}]`, item));

        var url = `${process.env.REACT_APP_URL}api/corporaciones/${datosModificar.idCorporativo}`;
        var config = {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: "Bearer  " + token,
            },
        };
        axios.post(url, data, config, {}).then(
            (response) => {
                var result = response.data;

                handleCorporaciones()
                setOpenEditCorporativo(false)

            },
            (error) => {
                let textoerror = JSON.stringify(error.response.data);
                openAlert(
                    textoerror.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "")
                );

            }
        );

    }



    function ItemRepresentantes() {
        if (datosModificar.respresentantes.length >= 1) {
            return datosModificar.respresentantes.map((ListItemData, index) => {
                return (
                    <ListItem key={index}>
                        <ListItemAvatar>
                            <Avatar>
                                <PersonIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={ListItemData}

                        />
                        <IconButton onClick={() => {
                            setOpenEditName(true)
                            setIndexRepre(index)
                            setEditNameRepre(ListItemData)
                        }} >

                            <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => {
                            deleteRepresentante(index)
                        }} >

                            <DeleteIcon />
                        </IconButton>

                    </ListItem>


                );

            })
        }
        return <Typography variant="h6" className={classes.title}>
            Sin representantes
        </Typography>

    }

    const classes = useStyles();
    return (
        <React.Fragment>
            <MaterialTable
                localization={localization}
                options={{
                    draggable: false,
                    pageSize: 20, // make initial page size
                    pageSizeOptions: [20, 50, 100,],
                    actionsColumnIndex: -1,
                    exportButton: false,
                    search: false,
                }}
                columns={[
                    { title: "Nombre", field: "name" },
                    {
                        title: "Representante", field: "representante",
                        render: rowData => rowData.representante ? rowData.representante.join(", ") : "Sin representantes"
                    },
                    { title: "Id", field: "_id" },
                    { title: "RFC", field: "rfc" },

                ]}
                data={corporaciones}
                title={"compañias"}
                actions={[
                    {
                        icon: () => (
                            <EditIcon
                                className={
                                    classes2.tableActionButtonIcon +
                                    " " +
                                    classes2.edit
                                }
                            />
                        ),
                        tooltip: "Editar",
                        onClick: (event, rowData) => {
                            handleEditCorporativo(rowData)
                        },
                    },
                ]}
            />
            {/* DIALOGO PARA EDITAR CORPORATIVO */}
            <DialogComp
                open={OpenEditCorporativo}
                maxWidth={"sm"}
                fullWidth={false}
                title="MODIFICAR COMPAÑIA"
                content={
                    <Grid container spacing={2}>
                        <Grid item xs={3} sm={6}>
                            <TextField
                                required
                                id="RFC_Corporacion"
                                name="RFC_Corporacion"
                                label="RFC Corporacion"
                                value={datosModificar.RFC_Corporacion}
                                fullWidth
                                autoComplete="RFC"
                                onChange={handleInputModificar}
                                inputProps={{ maxLength: 12, style: { textTransform: "uppercase" } }}
                                error={datosModificar.RFC_Corporacion.length > 11 ? false : true}
                            />
                        </Grid>
                        <Grid item xs={6} sm={12}>
                            <Typography variant="h6" className={classes.title}>
                                Representantes
                            </Typography>
                            <ItemRepresentantes />

                        </Grid>
                        {showTexfield == true ? (

                            <Grid item xs={5} sm={10}>
                                <TextField
                                    required
                                    id="nameRepre"
                                    name="nameRepre"
                                    label="Nombre del representante"
                                    value={nameRepre}
                                    fullWidth
                                    autoComplete="name"
                                    onChange={inputName}
                                    inputProps={{ maxLength: 30 }}
                                // error={datosModificar.RFC_Corporacion.length > 11 ? false : true}
                                />

                            </Grid>
                        ) : ""}
                        {showTexfield == true ? (
                            <Grid item xs={1} sm={2}>
                                <IconButton aria-label="desdete" onClick={() => {
                                    handleInsertarRepre()
                                }}>
                                    <CheckIcon />
                                </IconButton>
                            </Grid>
                        ) : ""}
                        <Grid item xs={6} sm={12}>
                            <Button
                                color="info"
                                size="md"
                                type="button"
                                className={classes.button}
                                onClick={() => {
                                    setShowTexfield(true);
                                }}
                                startIcon={<AddIcon />}
                            >
                                Agregar un representante
                            </Button>
                        </Grid>

                        <Grid item xs={3} sm={6}>
                            <Button
                                color="success"
                                size="md"
                                type="button"
                                onClick={handlesendData}
                                className={classes.button}
                            // disabled={disabledModificar}
                            >
                                Modificar compañia
                            </Button>
                        </Grid>
                    </Grid>
                }
                buttons={[
                    {
                        buttonEvent: () => setOpenEditCorporativo(false),
                        buttonName: "Cancelar",
                        buttonColor: "secondary",
                    },
                ]}
            />
            {/* FIN  DIALOGO PARA EDITAR CORPORATIVO */}
            <DialogComp
                open={OpenEditName}
                maxWidth={"sm"}
                fullWidth={false}
                title="MODIFICAR REPRESENTANTE"
                content={
                    <Grid container spacing={2}>
                        <Grid item xs={3} sm={12}>
                            <TextField
                                required
                                id="nameRepre"
                                name="nameRepre"
                                label="Nombre "
                                value={editNameRepre}
                                fullWidth
                                autoComplete="RFC"
                                onChange={editInputName}
                                inputProps={{ maxLength: 30 }}
                            />
                        </Grid>


                    </Grid>
                }
                buttons={[
                    {
                        buttonEvent: () => handleEditName(),
                        buttonName: "Aceptar",
                        buttonColor: "success",
                    },
                ]}
            />
            {/* FIN  DIALOGO PARA EDITAR CORPORATIVO */}
            <div className={classes.root}>
                <Snackbar
                    open={openSnack}
                    autoHideDuration={9000}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    onClose={handleCloseSnack}
                >
                    <Alert onClose={handleCloseSnack} severity="error">
                        {messageError}
                    </Alert>
                </Snackbar>
            </div>
        </React.Fragment>
    );
}
